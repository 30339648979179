import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { functions, firestore } from '../../firebase.config';
import { Colors } from '../../constants';
import { Spinner } from 'react-bootstrap';

const getClubChannels = functions.httpsCallable('gymAdminRequest-getClubChannels');

const ClubChannels = ({
  clubId,
  gymId,
} : {
  clubId: string;
  gymId: string;
}) => {
  const [channels, setChannels] = useState<any>(undefined);

  useEffect(() => {
    getClubChannels({
      clubId: clubId,
      gymId: gymId,
    })
    .then(channelsData => channelsData?.data && setChannels(channelsData.data));
  }, [clubId, gymId]);

  // const handleDeleteChannel = (channel: any) => {   
  //   Alert.alert('Are you sure you want to delete this update?', undefined, [
  //     {
  //       text: "Cancel",
  //       style: "cancel",
  //     },
  //     {
  //       text: "Delete",
  //       onPress: () => {
  //         clubsRef
  //           .doc(clubId)
  //           .collection('channels')
  //           .doc(channel.id)
  //           .update({
  //             deletedAt: new Date(),
  //           });
  //       },
  //       style: 'destructive',
  //     }
  //   ]);
  // }

  if (channels === undefined) {
    return (
      <div style={{padding: 20}}>
        <Spinner animation='border' color={Colors.MUSTARD} />
      </div>
    )
  }

  return (
    <div style={{flex: 1}}>
      {channels.length === 0 ? (
        <p style={{fontSize: 16, padding: '10px'}}>
          This club has no channels
        </p>
      ) : (
        <div>
          {channels.map((channel: any) => {
            // const handleDeleteChannelClick = () => handleDeleteChannel(channel);
            const lastMessageDate = new firestore.Timestamp(channel.lastMessageDate?._seconds, channel.lastMessageDate?._nanoseconds);

            return (
              <div key={channel.id} style={{ paddingTop: 10, paddingLeft: '10px', borderBottom: '1px solid lightgrey'}}>
                <div style={{display: 'flex'}}>
                  <p style={{flex: 1, fontWeight: 'bold', fontSize: 17, margin: 0}}>
                    {channel.name}
                  </p>
                  <p style={{color: Colors.GREY, padding: 3, fontSize: 12, margin: 0}}>
                    {moment(lastMessageDate?.toDate()).fromNow()}
                  </p>
                </div>
                <div>
                  <p style={{fontFamily: 'Montserrat-Medium', fontSize: 15, flex: 1}}>
                    {channel.lastMessage}
                  </p>
                  {/* <IconButton
                    onPress={handleDeleteChannelClick}
                    icon='delete'
                    color={Colors.DKRED}
                    style={{margin: 0}}
                  /> */}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ClubChannels;
