import React from 'react';
import moment from 'moment';
import { Colors } from '../../../constants';
import { ClimbTypes, IArea } from '../../../interfaces';

const windowWidth = window.innerWidth;

const AreaCard = ({
    area,
    areaAnalytics,
    climbType,
    handleSelectArea,
} : {
    area: IArea;
    areaAnalytics: any;
    climbType: ClimbTypes;
    handleSelectArea: (area: any) => void;
}) => {
    const handleSelectAreaClick = () => handleSelectArea(area);

    let routesCount = 0;
    const areaAnalyticsData = areaAnalytics?.data();
    const gradeDistribution = climbType === ClimbTypes.Boulder ? areaAnalyticsData.boulderGradeDistribution : areaAnalyticsData.routeGradeDistribution;
    if (!gradeDistribution) {
        return null;
    }

    for (const gradeCount of Object.values(gradeDistribution)) {
        routesCount += gradeCount as number;
    }

    let mapHeight = 170;
    if (windowWidth * 0.25 < 170) {
        mapHeight = windowWidth * 0.3;
    }

    return (
        <button
            style={{ display: 'flex', borderWidth: 1, borderColor: Colors.LTGREY, backgroundColor: 'transparent', margin: 10, maxWidth: 500, width: '75vw' }}
            onClick={handleSelectAreaClick}
        >
            <div style={{paddingTop: 10, display: 'flex', flex: 1}}>
                <div style={{
                    backgroundColor: area.color,
                    height: 15,
                    width: 15,
                    borderRadius: 50,
                    border: area.color === Colors.WHITE ? '1px solid grey' : 'none',
                    marginRight: 10,
                    marginLeft: 10,
                    marginTop: 6,
                }} />
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingRight: 5}}>
                    <p style={{fontSize: 24, fontWeight: 'bolder', marginBottom: 5, textAlign: 'left', lineHeight: 1}}>
                        {area.name}
                    </p>

                    <p style={{fontSize: 14, margin: 0, color: Colors.GREY, fontWeight: 'bold', textAlign: 'start'}}>
                        {area.walls.length} walls
                    </p>
                    <p style={{fontSize: 14, marginBottom: 5, color: Colors.GREY, fontWeight: 'bold', lineHeight: 1, textAlign: 'start'}}>
                        {routesCount} {climbType === ClimbTypes.Boulder ? 'boulders' : 'routes'}
                    </p>
                    <p style={{fontSize: 14, margin: 0, color: Colors.GREY, fontWeight: 'bold', lineHeight: 1, textAlign: 'start'}}>
                        Last Reset:
                    </p>
                    <p style={{fontSize: 14, marginBottom: 5, color: Colors.GREY, fontWeight: 'bold', lineHeight: 1, textAlign: 'start'}}>
                        {moment(areaAnalyticsData.lastReset.toDate()).format('MMMM Do')}
                    </p>
                </div>
            </div>
            <div>
                <svg height={mapHeight} width={mapHeight * 576/427} viewBox="0 0 576 427" style={{alignSelf: 'center'}}>
                    {area.walls.map(wall =>
                        <g
                            key={wall.id}
                            fill={area.color}
                            stroke={Colors.BLACK}
                            strokeWidth={1.5}
                        >
                            {wall.paths.map((path, i) =>
                                <path id={`${i}`} key={i} d={path.path} strokeDasharray={path.strokeDash} fill={path.color || area.color} />
                            )}
                            {wall.label.map((label: any, i: number) => 
                                <text key={i} fill='black' transform={label.transform} fontSize={12} x={label.x} y={label.y}>
                                    {label.text}
                                </text>
                            )}
                        </g>
                    )}
                    {area.markers.map(marker => 
                        <g
                            key={marker.id}
                            fill={marker.color}
                            stroke={Colors.BLACK}
                            strokeWidth={1.5}
                        >
                            <path id={marker.id} d={marker.path} />
                            {marker.label.map((label: any, i: number) => 
                                <text key={i} transform={label.transform} fontFamily='Montserrat-ExtraBold' fontSize={14} x={label.x} y={label.y}>
                                    {label.text}
                                </text>
                            )}
                        </g>
                    )}
                </svg>
            </div>
        </button>
    );
};

export default AreaCard;
