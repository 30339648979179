import React from 'react';
import moment from 'moment';

import BarChart from '../../organisms/charts/BarChart';
import ColorChart from '../../organisms/charts/ColorChart';
import { Colors } from '../../../constants';
import { ClimbTypes, IArea, IClimbSettingData } from '../../../interfaces';
import AreaMap from '../gymDisplay/AreaMap';

const AreaPage = ({
    climbType,
    areaAnalytics,
    selectedArea,
    climbSettingData,
    setSelectedWall,
    handleBack,
} : {
    climbType: ClimbTypes;
    areaAnalytics: any;
    selectedArea: IArea;
    climbSettingData: IClimbSettingData;
    setSelectedWall: (wall: any) => void;
    handleBack: () => void;
}) => {
    const areaAnalyticsData = areaAnalytics.data();
    const colorDistribution = climbType === ClimbTypes.Route ? areaAnalyticsData?.routeColorDistribution : areaAnalyticsData?.boulderColorDistribution;

    const labels: string[] = [];
    const gradeScale = climbType === ClimbTypes.Boulder ? climbSettingData.boulderGradeScale : climbSettingData.routeGradeScale;
    for (const gradeOption of gradeScale) {
        if (labels.findIndex(label => label === gradeOption.group) === -1) {
            labels.push(gradeOption.group);
        }
    }

    let totalClimbs = 0;
    const areaGradeDistribution: {[key: string]: number} = {};
    const gradeDistribution = climbType === ClimbTypes.Boulder ?
        areaAnalyticsData.boulderGradeDistribution || {}:
        areaAnalyticsData.routeGradeDistribution || {};
    for (const gradeKey of Object.keys(gradeDistribution)) {
        const gradeGroup = gradeScale.find(option => option.key === gradeKey)?.group;
        if (gradeGroup) {
            if (areaGradeDistribution[gradeGroup]) {
                areaGradeDistribution[gradeGroup] += gradeDistribution[gradeKey];
            } else {
                areaGradeDistribution[gradeGroup] = gradeDistribution[gradeKey];
            }
        }
        totalClimbs += gradeDistribution[gradeKey];
    }

    const climbTypeLabel = climbType === ClimbTypes.Route ? 'Route' : 'Boulder';
    const displayDataMap = [
        {
            label: `Total ${climbTypeLabel}s Set`,
            value: totalClimbs,
        },
        {
            label: 'Total Walls Set',
            value: selectedArea.walls.length,
        },
        {
            label: `Avg ${climbTypeLabel}s per Wall`,
            value: (totalClimbs/selectedArea.walls.length).toFixed(1),
        },
        // {
        //     label: 'Contributing Setters',
        //     value: totalClimbs,
        // },
        // {
        //     label: 'Avg Climbs per Setter',
        //     value: totalClimbs,
        // },
    ];

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', width: '80%'}}>
            <button
                style={{backgroundColor: 'transparent', border: 'none', color: Colors.MUSTARD, fontSize: 18, fontWeight: 'bolder', position: 'absolute', top: 15, left: 15}}
                onClick={handleBack}
            >
                &larr; All Areas
            </button>

            <p style={{letterSpacing: 1, marginTop: 40, fontWeight: 'bolder', fontSize: 28, marginBottom: 0}}>
                Currently Set - {selectedArea.name} {selectedArea.climbType === ClimbTypes.Mixed}
            </p>
            <p style={{letterSpacing: 1, fontSize: 18, marginBottom: 0, color: Colors.DRKGREY}}>
                Last Update - {moment(areaAnalyticsData?.lastReset.toDate()).format('L')}
            </p>
            {selectedArea.climbType === ClimbTypes.Mixed && (
                <p style={{letterSpacing: 1, fontSize: 18, marginBottom: 0, color: Colors.DRKGREY}}>
                    {climbType === ClimbTypes.Boulder ? '(Boulders)' : '(Routes)'}
                </p>
            )}

            <AreaMap
                selectedArea={selectedArea}
                handleSelectWall={setSelectedWall}
            />

            <div style={{display: 'flex', width: '100%', marginBottom: 30}}>
                {displayDataMap.map((data, i) => 
                    <div key={data.label} style={{flex: 1, borderRight: i === displayDataMap.length - 1 ? 'none' : '1px solid black'}}>
                        <p style={{margin: 0, textAlign: 'center', backgroundColor: Colors.MDGREY}}>{data.label}</p>
                        <p style={{padding: 7, margin: 0, textAlign: 'center', backgroundColor: Colors.MUSTARDSHADE}}>{data.value}</p>
                    </div> 
                )}
            </div>

            <div className='gymChartContainer'>
                <BarChart
                    data={areaGradeDistribution || {}}
                    labels={labels}
                />
                <ColorChart
                    data={colorDistribution || {}}
                />
            </div>
        </div>
    );
}

export default AreaPage;
