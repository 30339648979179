import { IUser, IGym, IGymOption } from '../interfaces';

const LOGIN_USER = "LOGIN_USER";
const LOGOUT_USER = "LOGOUT_USER";
const SET_GYM = "SET_GYM";
const SET_ADMIN_GYMS = "SET_ADMIN_GYMS";

export interface ILoginUserPayload {
    user: IUser;
};

export interface IGymPayload {
    gym: IGym;
};

export interface IAdminGymsPayload {
    adminGyms: IGymOption[];
};

export const storeUser = (payload: ILoginUserPayload) => {
    return { type: LOGIN_USER, payload };
};

export const logoutUser = () => {
    return { type: LOGOUT_USER };
};

export const setGym = (payload: IGymPayload) => {
    return { type: SET_GYM, payload };
};

export const setAdminGyms = (payload: IAdminGymsPayload) => {
    return { type: SET_ADMIN_GYMS, payload };
};
