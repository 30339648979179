import React from 'react';
import { Colors } from '../../../constants';
import { IArea } from '../../../interfaces';

const windowWidth = window.innerWidth;

const AreaMap = ({
    selectedArea,
    handleSelectWall,
} : {
    selectedArea: IArea,
    handleSelectWall: (wall: any) => void,
}) => {
    let wallHeight = 300;
    if (windowWidth * 0.8 < 170) {
        wallHeight = windowWidth * 0.8;
    }

    return (
        <div style={{ marginTop: 20 }}>
            <svg height={wallHeight} width={wallHeight * 576/427} viewBox="0 0 576 427" style={{alignSelf: 'center'}}>
                {selectedArea.walls.map(wall => 
                    <g key={wall.id}>
                        {wall.paths.map((path, i) =>
                            <path
                                key={i}
                                stroke={Colors.BLACK}
                                d={path.path}
                                strokeDasharray={path.strokeDash}
                                fill={path.color || selectedArea.color}
                                onClick={() => handleSelectWall(wall)}
                                style={{cursor: 'pointer'}}
                            />
                        )}
                        {wall.label.map((label, i) => 
                            <text
                                key={i}
                                stroke={Colors.BLACK}
                                fill='black'
                                transform={label.transform}
                                fontSize={12}
                                x={label.x}
                                y={label.y}
                                onClick={() => handleSelectWall(wall)}
                            >
                                {label.text}
                            </text>
                        )}
                    </g>
                )}
                {selectedArea.markers.map(marker => 
                    <g
                        key={marker.id}
                        fill={marker.color}
                        stroke={Colors.BLACK}
                        strokeWidth={1.5}
                    >
                        <path id={marker.id} d={marker.path} />
                        {marker.label.map((label: any, i: number) => 
                            <text key={i} transform={label.transform} fontFamily='Montserrat-ExtraBold' fontSize={14} x={label.x} y={label.y}>
                                {label.text}
                            </text>
                        )}
                    </g>
                )}
            </svg>
        </div>
    );
};

export default AreaMap;
