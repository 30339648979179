import React, { useState, useEffect } from 'react';
import { BiChevronDown, BiChevronUp, BiPencil } from 'react-icons/bi';
import { Spinner } from 'react-bootstrap';

import db from '../../firebase.config';
import { Colors } from '../../constants';

const leaguesRef = db.collection('leagues');
const gymsRef = db.collection('gyms');

const LeagueParticipants = ({
  league,
} : {
  league: any;
}) => {
  const [participants, setParticipants] = useState<any>(undefined);
  const [edittingParticipant, setEdittingParticipant] = useState<any | null>(null);
  const [climbSettingData, setClimbSettingData] = useState<any>(undefined);

  const handleClearEdittingParticipant = () => setEdittingParticipant(null);
  const handleEditParticipantBoulderMaxChange = (e: any) => {
    if (edittingParticipant) {
      const edittingParticipantCopy = {...edittingParticipant};
      edittingParticipantCopy.boulderMax = e.target.value;
      setEdittingParticipant(edittingParticipantCopy);
    }
  }

  const handleEditParticipantRouteMaxChange = (e: any) => {
    if (edittingParticipant) {
      const edittingParticipantCopy = {...edittingParticipant};
      edittingParticipantCopy.routeMax = e.target.value;
      setEdittingParticipant(edittingParticipantCopy);
    }
  }

  useEffect(() => {
    gymsRef
      .doc(league.gymId)
      .collection('climbSettingData')
      .doc(league.gymId)
      .get()
      .then(climbSettingDoc => (climbSettingDoc && climbSettingDoc.exists) && setClimbSettingData(climbSettingDoc.data()));
  }, [league.gymId]); 

  useEffect(() => {
    leaguesRef
      .doc(league.id)
      .collection('leagueParticipants')
      .where('teamStatus', '==', 'accepted')
      .orderBy('cumulativeScore')
      .limit(300)
      .get()
      .then(participantDocs => participantDocs && setParticipants(participantDocs.docs.map(participant => ({id: participant.id, ...participant.data()}))));
  }, [league.id]);

  if (participants === undefined) {
    return (
      <div>
        <Spinner animation='border' color={Colors.MUSTARD} />
      </div>
    )
  } else if (participants.length === 0) {
    return (
      <div>
        <p style={{fontSize: 24}}>
          No participants yet
        </p>
      </div>
    )
  }

  // const handleRemoveMember = async (member: any, team: any) => {
  //   setRemovingMemberId(member.id);

  //   await leaguesRef
  //     .doc(league.id)
  //     .collection('leagueParticipants')
  //     .doc(member.id)
  //     .update({
  //       teamId: null,
  //       teamStatus: null,
  //       teamAcceptedAt: null,
  //     });

  //   const previousMembers: any[] = team.previousMembers || [];
  //   previousMembers.push({
  //     memberId: member.id,
  //     points: member.cumulativeScore,
  //     date: new Date(),
  //   });

  //   await leaguesRef
  //     .doc(league.id)
  //     .collection('leagueTeams')
  //     .doc(team.id)
  //     .update({
  //       previousMembers,
  //     });
    
  //   const newTeamMembers = teamMembersMap[team.id].filter(currentMember => currentMember.id !== member.id);
  //   if (newTeamMembers.length) {
  //     const teamMembersMapCopy = {...teamMembersMap};
  //     teamMembersMapCopy[team.id] = newTeamMembers;
  //     setTeamMembersMap(teamMembersMapCopy);
  //   } else {
  //     const teamMembersMapCopy = {...teamMembersMap};
  //     delete teamMembersMapCopy[team.id];
  //     setTeamMembersMap(teamMembersMapCopy);
  //     setTeams(teams.filter((curTeam: any) => curTeam.id !== team.id));
  //   }

  //   setRemovingMemberId(null);
  // }

  const handleSaveEdittingParticipant = async () => {
    if (edittingParticipant) {
      await leaguesRef
        .doc(league.id)
        .collection('leagueParticipants')
        .doc(edittingParticipant.id)
        .update({
          routeMax: edittingParticipant.routeMax,
          boulderMax: edittingParticipant.boulderMax,
        });

      setParticipants(participants.map((participant: any) => participant.id === edittingParticipant.id ? edittingParticipant : participant));
      setEdittingParticipant(null);
    }
  }

  const condensedBoulderGrades: string[] = [];
  const condensedRouteGrades: string[] = [];
  if (climbSettingData) {
    climbSettingData.boulderGradeScale.forEach((gradeOption: any) => {
      if (!condensedBoulderGrades.includes(gradeOption.group)) {
        condensedBoulderGrades.push(gradeOption.group);
      }
    });
    climbSettingData.routeGradeScale.forEach((gradeOption: any) => {
      if (!condensedRouteGrades.includes(gradeOption.group)) {
        condensedRouteGrades.push(gradeOption.group);
      }
    });
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
      {edittingParticipant && (
        <div style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, display: 'flex', alignItems: 'center', backgroundColor: Colors.MODALBACKGROUND}}>
          <div style={{height: 'calc(250px + 14vh)', width: 'calc(250px + 20vw)', borderRadius: 30, backgroundColor: Colors.WHITE, margin: 'auto', position: 'relative'}}>
            <button onClick={handleClearEdittingParticipant} style={{border: 'none', backgroundColor: 'white', position: 'absolute', top: 15, right: 15}}>
              &#10006;
            </button>
            <p style={{textAlign: 'center', marginTop: 15, fontSize: 20}}>
              Edit Participant: {edittingParticipant.userName}
            </p>

            {(condensedBoulderGrades.length || condensedRouteGrades.length) ? (
              <div style={{display: 'flex', flexDirection: 'column', margin: 25}}>
                <p style={{margin: 0}}>
                  Boulder Max
                </p>
                <select value={edittingParticipant.boulderMax} onChange={handleEditParticipantBoulderMaxChange}>
                  {condensedBoulderGrades.map(grade => <option value={grade}>{grade}</option>)}
                </select>

                <p style={{margin: '20px 0 0 0'}}>
                  Route Max
                </p>
                <select value={edittingParticipant.routeMax} onChange={handleEditParticipantRouteMaxChange}>
                  {condensedRouteGrades.map(grade => <option value={grade}>{grade}</option>)}
                </select>

                <button onClick={handleSaveEdittingParticipant} style={{border: 'none', padding: 3, marginTop: 40, borderRadius: 5, backgroundColor: Colors.MUSTARD, }}>
                  Save
                </button>
              </div>
            ) : (
              <Spinner animation='border' />
            )}
          </div>
        </div>
      )}
      
      {participants.map((participant: any) => {
        const handleEditParticipantClick = () => setEdittingParticipant(participant);

        return (
          <div key={participant.id} style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            <div
              style={{marginBottom: 10, padding: 5, borderRadius: 10, border: '1px solid grey', backgroundColor: Colors.WHITE}}
            >
              <div style={{display: 'flex', flexGrow: 1, alignItems: 'center'}}>
                <img src={participant.userImageUri} alt='admin profile' style={{height: 60, width: 60, borderRadius: 60}} />

                <p style={{marginBottom: 0, marginLeft: 10, fontSize: 18, fontWeight: 'bold', width: '25%', paddingRight: 5, textAlign: 'left'}}>
                  {participant.userName}
                </p>

                <p style={{marginBottom: 0, marginLeft: 10, fontSize: 18, width: '15%', paddingRight: 5, textAlign: 'left'}}>
                  {participant.cumulativeScore} points
                </p>

                <p style={{marginBottom: 0, fontSize: 16, width: '25%', textAlign: 'center'}}>
                  {participant.boulderMax}
                </p>

                <p style={{marginBottom: 0, fontSize: 16, flexGrow: 1, textAlign: 'left'}}>
                  {participant.routeMax}
                </p>

                <div style={{flexGrow: 1, justifyContent: 'center', display: 'flex'}}>
                  <button onClick={handleEditParticipantClick} style={{border: 'none', backgroundColor: 'white'}}>
                    <BiPencil size={30} color={Colors.DRKGREY} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default LeagueParticipants;
