import React, { useState, useEffect } from 'react';

import { functions } from '../../firebase.config';
import { Colors } from '../../constants';
import { Spinner } from 'react-bootstrap';

const getClubUpdates = functions.httpsCallable('gymAdminRequest-getClubUpdates');

const ClubUpdates = ({
    clubId,
    gymId,
} : {
    clubId: string;
    gymId: string;
}) => {
    const [updates, setUpdates] = useState<any>(undefined);

    useEffect(() => {
        getClubUpdates({
            clubId: clubId,
            gymId: gymId,
        })
        .then(updatesData => updatesData?.data && setUpdates(updatesData.data));
    }, [clubId, gymId]);

    // const handleDeleteUpdate = (update: any) => {   
    //     Alert.alert('Are you sure you want to delete this update?', undefined, [
    //         {
    //           text: "Cancel",
    //           style: "cancel",
    //         },
    //         {
    //           text: "Delete",
    //           onPress: () => {
    //             clubsRef
    //                 .doc(clubId)
    //                 .collection('updates')
    //                 .doc(update.id)
    //                 .update({
    //                     deletedAt: new Date(),
    //                 });
                
    //             setRefresh(refresh + 1);
    //           },
    //           style: 'destructive',
    //         }
    //     ]);
    // }
  
    if (updates === undefined) {
      return (
        <div style={{padding: 20}}>
          <Spinner animation='border' color={Colors.MUSTARD} />
        </div>
      )
    }

    return (
        <div style={{flex: 1}}>
            {updates.length === 0 ? (
                <p style={{padding: 10, fontSize: 16}}>
                    This club hasn't posted{'\n'}any updates yet
                </p>
            ) : (
                <div>
                    {updates.map((update: any, i: number) => {
                        // const handleDeleteUpdateClick = () => handleDeleteUpdate(update);

                        return (
                            <div key={i} style={{ display: 'flex', paddingTop: 15, paddingBottom: 15, paddingLeft: '10px', borderBottom: '1px solid #C4C4C4'}}>
                                <img src={update.userImageUri} alt='user profile' style={{height: 60, width: 60, borderRadius: 60}} />

                                <div style={{marginLeft: 10, flex: 1}}>
                                    <p style={{fontSize: 16, fontWeight: 'bold', marginBottom: 5}}>
                                        {update.userName}
                                    </p>
                                    <p style={{fontSize: 14, marginBottom: 5}}>
                                        {update.message}
                                    </p>

                                    <div style={{flexDirection: 'row', alignSelf: 'flex-end'}}>
                                        {/* <IconButton
                                            onPress={handleDeleteUpdateClick}
                                            icon='delete'
                                            color={Colors.DKRED}
                                            style={{margin: 0}}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ClubUpdates;
