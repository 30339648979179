import React, { useState, useEffect } from 'react';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { Spinner } from 'react-bootstrap';

import './ClubMembers.css';

const clubsRef = db.collection('clubs');
const usersRef = db.collection('users');

const ClubMembers = ({
  clubId,
  ownerId,
} : {
  clubId: string;
  ownerId: string;
}) => {
  const [owner, setOwner] = useState<any>(undefined);
  const [members, setMembers] = useState<any>(undefined);
  const [requests, setRequests] = useState<any>(undefined);

  useEffect(() => {
    usersRef
      .doc(ownerId)
      .get()
      .then(userDoc => {
        const userData = userDoc?.data();
        if (userData) {
          setOwner({
            id: ownerId,
            userName: userData.name,
            userImageUri: userData.profilePictureUri
          });
        }
      })
  }, [ownerId])

  useEffect(() => {
    clubsRef
      .doc(clubId)
      .collection('members')
      .where('status', '==', 'accepted')
      .orderBy('role', 'asc')
      .limit(10)
      .get()
      .then(async memberDocs => {
        if (memberDocs && !memberDocs.empty) {
          const allMemberDocs: any[] = [];
          for (const memberDoc of memberDocs.docs) {
            const memberData = {...memberDoc.data()};
            if (memberData.role !== 'owner') {
              const userDoc = await usersRef
                .doc(memberDoc.id)
                .get();

              memberData.id = memberDoc.id;
              if (userDoc && userDoc.exists) {
                memberData.userName = userDoc.data()?.name;
                memberData.userImageUri = userDoc.data()?.profilePictureUri;
              }
              allMemberDocs.push(memberData as any);
            }
          };
          setMembers(allMemberDocs);
        } else {
          setMembers([]);
        };
      });

    clubsRef
      .doc(clubId)
      .collection('members')
      .where('status', '==', 'requested')
      .limit(10)
      .get()
      .then(async requestDocs => {
        if (requestDocs && !requestDocs.empty) {
          const allRequestDocs: any[] = [];
          for (const requestDoc of requestDocs.docs) {
            const userDoc = await usersRef
              .doc(requestDoc.id)
              .get();

            const requestData = {...requestDoc.data()};
            requestData.id = requestDoc.id;
            if (userDoc && userDoc.exists) {
              requestData.userName = userDoc.data()?.name;
              requestData.userImageUri = userDoc.data()?.profilePictureUri;
            }
            allRequestDocs.push(requestData as any);
          };
          setRequests(allRequestDocs);
        } else {
          setRequests([]);
        };
      });
  }, [clubId]);

  if (members === undefined || requests === undefined || owner === undefined) {
    return (
      <div className="loadingContainer">
        <Spinner animation='border' color={Colors.MUSTARD} />
      </div>
    )
  } else if (members.length === 0 && requests.length === 0) {
    return (
      <div className="noContentContainer">
        <p className="noContentText">
          No non-admin members yet
        </p>
      </div>
    )
  }

  return (
    <div>
      <div key={owner.id} className='membersContainer'>
        <img src={owner.userImageUri} alt='owner profile' style={{height: 60, width: 60, borderRadius: 60 }} />
        <p style={{flex: 1, marginLeft: 10, marginBottom: 0, fontSize: 16, fontWeight: 'bold'}}>
          {owner.userName}
        </p>
        <p style={{color: Colors.GREY, marginBottom: 0}}>
          (owner)
        </p>
      </div>
      {members.map((member: any) => (
        <div key={member.id} className="membersContainer">
          <img src={member.userImageUri} alt='admin profile' style={{height: 60, width: 60, borderRadius: 60}} />
          <p style={{flex: 1, marginBottom: 0, marginLeft: 10, fontSize: 16, fontWeight: 'bold',}}>
            {member.userName}
          </p>
          <p style={{color: Colors.GREY, marginBottom: 0}}>
            ({member.role})
          </p>
        </div>
        )
      )}
    </div>
  );
};

export default ClubMembers;
