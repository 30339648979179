export enum Colors {
    MUSTARD = '#F5C669',
    LTMUSTARD = '#f2d394',
    LTRED = '#ff7070',
    RED = '#FF5151',
    DRKRED = '#bf1717',
    GREEN = '#5AB523',
    BLUE = '#4F8BFF',
    LTGREY = '#C4C4C4',
    GREY = '#8a8a8a',
    MDGREY = '#A1A1A1',
    WHITE = '#ffffff',
    OFFWHITE = '#ededed',
    YELLOW = '#F4E14C',
    LTORANGE = '#F6BC57',
    ORANGE = '#FF7B00',
    DRKGREY = '#6b6b6b',
    LTGREEN = '#4dff7c',
    TEAL = '#4dfff3',
    LTBLUE = '#4dbbff',
    LTPURPLE = '#b54dff',
    PURPLE = '#7120ab',
    PINK = '#ff33ee',
    BROWN = '#80482a',
    BLACK = '#000000',
    LTLTGREY = '#d9d9d9',
    TEXTLTGREY = '#BDBDBD',
    MUSTARDSHADE = '#F5C66999',
    MODALBACKGROUND = 'rgba(0, 0, 0, 0.5)',
};
