import React, { useEffect, useRef, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ReactToPrint from 'react-to-print';
import { connect } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import PrintTagComponent from './PrintTagComponent';
import { IGym, ITagTemplate } from '../../../interfaces';
import { Colors } from '../../../constants';
import db from '../../../firebase.config';

const gymsRef = db.collection('gyms');

enum PrintOrientation {
    LANDSCAPE = 'landscape',
    PORTRAIT = 'portrait',
}

const defaultTagTemplate: ITagTemplate = {
    height: 1350,
    width: 1050,
    zoom: 1,
    gymImageUri: '',
    gymImageTop: 60,
    gymImageLeft: 350,
    gradeTop: 450,
    gradeFontSize: 174,
    nameFontSize: 102,
    aboutFontSize: 55,
    aboutAlign: 'left',
    aboutSide: 50,
    aboutFontFamily: '',
    aboutFontWeight: 'normal',
    aboutTop: 800,
    climbTimeLogoTop: 1080,
    climbTimeLogoSide: 150,
    isGymLogoTop: true,
    primaryColor: Colors.MUSTARD,
    hasBorder: false,
    areaNameShown: true,
}

const PrintTagsModal = ({
    selectedRoutes,
    areaName,
    wallName,
    gym,
    handleCloseModal,
} : {
    selectedRoutes: any[];
    areaName: string;
    wallName: string;
    gym: IGym;
    handleCloseModal: () => void;
}) => {
    const [zoomOffset, setZoomOffset] = useState<number>(0);
    const [tagTemplate, setTagTemplate] = useState<ITagTemplate | undefined>(undefined);
    // const [orientation, setOrientation] = useState<PrintOrientation>(PrintOrientation.PORTRAIT);

    useEffect(() => {
        gymsRef
            .doc(gym.id)
            .collection('tagTemplate')
            .doc(gym.id)
            .get()
            .then(tagTemplateDoc => (tagTemplateDoc && tagTemplateDoc.exists) ?
                setTagTemplate(tagTemplateDoc.data() as ITagTemplate) :
                setTagTemplate(defaultTagTemplate)
            );
    }, [gym.id]);

    const printComponentRef = useRef<any>(null);
    const getPrintComponent = () => printComponentRef.current;

    const getPrintButton = () => (
        <Button variant='primary'>
            Print
        </Button>
    )

    const handleIncreaseZoomOffset = () => setZoomOffset(zoomOffset + 0.05);
    const handleDecreaseZoomOffset = () => setZoomOffset(zoomOffset - 0.05);
    // const handleSetPortrait = () => setOrientation(PrintOrientation.PORTRAIT);
    // const handleSetLandscape = () => setOrientation(PrintOrientation.LANDSCAPE);

    return (
        <Modal animation={false} size='xl' show={true} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>Print Tags</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{display: 'flex', justifyContent: 'space-around' }}
                >
                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center'}}>
                        <div>
                            <p style={{fontSize: 20, textAlign: 'center'}}>Tag Size:</p>
                            <button
                                style={{backgroundColor: 'transparent', border: '1px solid grey', fontSize: 30, borderRadius: 6, marginRight: 10}}
                                onClick={handleDecreaseZoomOffset}
                            >
                                &#9660;
                            </button>
                            <button
                                style={{backgroundColor: 'transparent', border: '1px solid grey', fontSize: 30, borderRadius: 6}}
                                onClick={handleIncreaseZoomOffset}
                            >
                                &#9650;
                            </button>
                        </div>

                        {/* <div>
                            <p style={{textAlign: 'center', fontSize: 20}}>Orientation:</p>
                            <button
                                style={{
                                    backgroundColor: orientation === PrintOrientation.PORTRAIT ? Colors.MUSTARD : Colors.MUSTARDSHADE,
                                    border: '1px solid grey',
                                    fontSize: 18,
                                    borderRadius: 6,
                                    marginRight: 10,
                                }}
                                onClick={handleSetPortrait}
                            >
                                Portrait
                            </button>
                            <button
                                style={{
                                    backgroundColor: orientation === PrintOrientation.LANDSCAPE ? Colors.MUSTARD : Colors.MUSTARDSHADE,
                                    border: '1px solid grey',
                                    fontSize: 18,
                                    borderRadius: 6,
                                }}
                                onClick={handleSetLandscape}
                            >
                                Landscape
                            </button>
                        </div> */}
                    </div>
 
                    <div>
                        <p>Preview:</p>
                        {!tagTemplate ? (
                            <Spinner animation="border" />
                        ) : (
                            <div style={{overflowY: 'scroll', border: '1px solid grey'}}>
                                <div style={{zoom: 0.18}}>
                                    <PrintTagComponent
                                        ref={printComponentRef}
                                        routes={selectedRoutes}
                                        template={tagTemplate}
                                        areaName={areaName}
                                        wallName={wallName}
                                        zoomOffset={zoomOffset}
                                        orientation={PrintOrientation.PORTRAIT}
                                        gymAvatarUri={gym.avatarUri}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='light' onClick={handleCloseModal}>
                    Close
                </Button>
                <ReactToPrint
                    trigger={getPrintButton}
                    content={getPrintComponent}
                />
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
    }
}

export default connect(mapStateToProps)(PrintTagsModal);
