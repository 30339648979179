import React from 'react';
import { Colors } from '../../../constants';

const ColorChart = ({
    data,
} : {
    data: {[key: string]: number};
}) => {
    let orderedColorData: any[] = Object.keys(data)
        .map(label => ({
            color: label,
            value: data[label],
        }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 6);

    if (orderedColorData.length < 6) {
        const fillArray = Array(6 - orderedColorData.length).fill({
            color: 'transparent',
            value: '',
        });
        orderedColorData = [...fillArray, ...orderedColorData];
    }

    return (
        <div style={{minWidth: 130, minHeight: 250, width: '15vw', height: '35vh', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <div style={{display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between'}}>
                {orderedColorData.map((data: any, i: number) => 
                    <div key={i} style={{display: 'flex', flex: 1, alignItems: 'flex-end'}}>
                        <div style={{backgroundColor: data.color, width: 20, height: 20, borderRadius: 50, marginRight: 10, marginBottom: 3, borderWidth: (data.color === Colors.WHITE) ? 1 : 0, borderStyle: 'solid', borderColor: Colors.LTGREY}}/>
                        <p style={{margin: 0, fontWeight: 'bolder', fontSize: 20}}>{data.value}</p>
                    </div>
                )}
            </div>
            <p style={{borderTop: '1px solid black', textAlign: 'center', marginTop: 10}}>Used Colors</p>
        </div>
    );
}

export default ColorChart;
