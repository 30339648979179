import React, { useEffect, useState, useRef } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { BiLeftArrowAlt, BiPencil, BiImage, BiTrash } from 'react-icons/bi';
import { connect } from 'react-redux';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { uploadImage } from '../../utils/uploadImage';
import { IGym } from '../../interfaces';

const gymRef = db.collection('gyms');

interface INewsUpdate {
    message: string;
    imageUri: string | null;
}

const NewsDataPage = ({
    allNews,
    gym,
} : {
    allNews: any;
    gym: IGym;
}) => {
    let { id: newsId } = useParams<{id: string}>();
    const [saveLoading, setSaveLoading] = useState<boolean>(false);
    const [news, setNews] = useState<INewsUpdate | undefined | null>(undefined)
    const [imageToUpload, setImageToUpload] = useState<any>(undefined);

    const inputFile = useRef<any>(null) ;

    useEffect(() => {
        if (newsId === 'create') {
            setNews({
                message: '',
                imageUri: null,
            });
            return;
        }

        if (gym?.id) {
            gymRef
                .doc(gym.id)
                .collection('news')
                .doc(newsId)
                .get()
                .then(newsDoc => {
                    if (newsDoc && newsDoc.exists) {
                        setNews({
                            message: newsDoc.data()?.message,
                            imageUri: newsDoc.data()?.imageUri,
                        });
                    } else {
                        setNews(null);
                    }
                })
        }
    }, [gym?.id, newsId]);

    const handleOpenFileSystem = () => inputFile.current.click();

    const handleSaveNews = async () => {
        setSaveLoading(true);
        if (gym?.id && news) {
            let uploadImageUri: string | undefined | null = news.imageUri;
            if (imageToUpload && news.imageUri) {
                uploadImageUri = await uploadImage(imageToUpload, news.imageUri);
                if (!uploadImageUri) {
                    window.alert('Image upload failed');
                    return;
                }
            }

            if (newsId === 'create') {
                const newNews = {
                    message: news.message,
                    imageUri: uploadImageUri,
                    likes: [],
                    deletedAt: null,
                    postDate: new Date(),
                };
                const newNewsDoc = await gymRef
                    .doc(gym?.id)
                    .collection('news')
                    .add(newNews);

                allNews?.push({
                    id: newNewsDoc.id,
                    ...newNews,
                });
            } else {
                await gymRef
                    .doc(gym?.id)
                    .collection('news')
                    .doc(newsId)
                    .update({
                        message: news.message,
                        imageUri: uploadImageUri,
                    });

                allNews?.forEach((newsDoc: any) => {
                    if (newsId === newsDoc.id) {
                        newsDoc.message = news.message;
                        newsDoc.imageUri = uploadImageUri;
                    }
                });
            }            
        }
        window.history.back();
    }

    const handleAttachedImageChange = (event: any) => {
        if (event.target?.files?.length > 0 && news) {
            setNews({
                message: news.message,
                imageUri: URL.createObjectURL(event.target.files[0]),
            });
            setImageToUpload(event.target.files[0]);
        }
    }

    const handleRemoveImage = () => {
        if (news) {
            setNews({
                message: news.message,
                imageUri: null,
            });
            setImageToUpload(undefined);
        };
    }

    const handleMessageChanged = (event: any) => news && setNews({
        message: event.target.value,
        imageUri: news.imageUri,
    });

    if (news === undefined) {
        return (
            <div style={{margin: '20px'}}>
                <Spinner animation="border" />
            </div>
        );
    } else if (news === null) {
        return <p style={{padding: 20}}>Error! Please go back and try again!</p>
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, margin: 20}}>
            <button
                onClick={() => window.history.back()}
                style={{display: 'flex', borderRadius: 50, alignSelf: 'flex-start', padding: 10, marginTop: 10, marginBottom: 15, backgroundColor: Colors.OFFWHITE, border: 'none'}}
            >
                <BiLeftArrowAlt size={24} color={Colors.DRKGREY} />
            </button>

            <div>
                <p style={{fontSize: 18, fontWeight: 'bold', color: Colors.DRKGREY, marginTop: 3, marginBottom: 3}}>
                    Message:
                </p>
                <textarea style={{width: 500, height: 150, borderRadius: 5}} value={news.message} onChange={handleMessageChanged} />
            </div>

            <p style={{fontSize: 18, fontWeight: 'bold', color: Colors.DRKGREY, marginTop: 3, marginBottom: 3}}>
                Attached Photo:
            </p>
            <div style={{marginBottom: 20, display: 'flex'}}>
                <input type='file' id='file' ref={inputFile} onChange={handleAttachedImageChange} style={{display: 'none'}}/>
                {news.imageUri ? (
                    <div style={{position: 'relative'}}>
                        <img width={400} src={news.imageUri} alt='news-attachment' />
                        <button
                            onClick={handleOpenFileSystem}
                            style={{position: 'absolute', bottom: -5, right: -5, backgroundColor: 'white', borderRadius: 20, padding: 3, border: '1px solid grey'}}
                        >
                            <BiPencil size={24} />
                        </button>
                        <button
                            onClick={handleRemoveImage}
                            style={{position: 'absolute', bottom: -5, left: -5, backgroundColor: 'white', borderRadius: 20, padding: 3, border: '1px solid grey'}}
                        >
                            <BiTrash size={24} />
                        </button>
                    </div>
                ) : (
                    <div>
                        <button style={{backgroundColor: 'transparent', border: 'none'}}>
                            <BiImage onClick={handleOpenFileSystem} size={56} />
                        </button>
                    </div>
                )}
            </div>

            {saveLoading ? (
                <div style={{}}>
                    <Spinner animation="border" />
                </div>
            ) : (
                <button
                    type="submit"
                    style={{alignSelf: 'flex-start', backgroundColor: Colors.MUSTARD, padding: '5px 70px', border: '1px solid grey', borderRadius: 8, marginTop: 10}}
                    onClick={handleSaveNews}
                >
                    Save
                </button>
            )}  
        </div>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
    }
}

export default connect(mapStateToProps)(NewsDataPage);
