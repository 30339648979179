import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';

import db from '../../../firebase.config';
import { Colors, ReverseColorLookup } from '../../../constants';
import { IArea, IWall } from '../../../interfaces';
import PrintTagsModal from '../tagPrinting/PrintTagsModal';

const wallWidth = 351;
const wallHeight = 260.2031;
const areasRef = db.collection('areas');

const WallPage = ({
    selectedArea,
    selectedWall,
    handleBack,
} : {
    selectedArea: IArea;
    selectedWall: IWall;
    handleBack: () => void;
}) => {
    const [activeCampaign, setActiveCampaign] = useState<any>(undefined);
    const [routes, setRoutes] = useState<any>(undefined);

    const [isPrintTagsOpen, setIsPrintTagsOpen] = useState<boolean>(false);
    const [selectedRoutes, setSelectedRoutes] = useState<any[]>([]);

    useEffect(() => {
        areasRef
            .doc(selectedArea.id)
            .collection('campaigns')
            .where('status', '==', 1) // Active campaign
            .where('wallId', '==', selectedWall.id)
            .limit(1)
            .get()
            .then(campaignDocs => (campaignDocs && campaignDocs.size === 1) && setActiveCampaign(campaignDocs.docs[0]));
    }, [selectedArea.id, selectedWall.id]);

    useEffect(() => {
        if (activeCampaign) {
            areasRef
                .doc(selectedArea.id)
                .collection('campaigns')
                .doc(activeCampaign.id)
                .collection('routes')
                .where('deletedAt', '==', null)
                .get()
                .then(routeDocs => routeDocs && setRoutes(routeDocs.docs));
        }
    }, [selectedArea.id, selectedWall.id, activeCampaign]);

    const handleToggleIsPrintTagsOpen = () => setIsPrintTagsOpen(!isPrintTagsOpen);

    const handleSelectAllClick = () => {
        if (selectedRoutes.length < routes.length) {
            setSelectedRoutes(routes);
        } else {
            setSelectedRoutes([]);
        }
    }

    const splitViewBox = selectedWall.viewBoxAdjusted.split(' ');
    const viewBoxStartX = parseInt(splitViewBox[0]);
    const viewBoxStartY = parseInt(splitViewBox[1]);
    const viewBoxWidth = parseInt(splitViewBox[2]);
    const viewBoxHeight = parseInt(splitViewBox[3]);
    const viewBoxCenterX = viewBoxStartX + viewBoxWidth/2;
    const viewBoxCenterY = viewBoxStartY + viewBoxHeight/2;
    const wallTransform = `
        translate(${selectedWall.transformAdjusted.translateX}, ${selectedWall.transformAdjusted.translateY})
        rotate(${selectedWall.transformAdjusted.rotation} ${viewBoxCenterX} ${viewBoxCenterY})
        scale(${selectedWall.transformAdjusted.scale})
    `;

    return (
        <div style={{ padding: 20, flex: 1, width: '80%' }}>
            {isPrintTagsOpen && 
                <PrintTagsModal
                    handleCloseModal={handleToggleIsPrintTagsOpen}
                    selectedRoutes={selectedRoutes}
                    areaName={selectedArea.name}
                    wallName={selectedWall.name}
                />
            }

            <button
                style={{backgroundColor: 'transparent', border: 'none', color: Colors.MUSTARD, fontSize: 18, fontWeight: 'bolder', position: 'absolute', top: 15, left: 15}}
                onClick={handleBack}
            >
                &larr; All Walls
            </button>

            {!routes ? (
                <div style={{marginTop: '10%', flex: 1, display: 'flex', justifyContent: 'center'}}>
                    <Spinner animation='border' color={Colors.MUSTARD} />
                </div>
            ) : (
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <h4>{selectedWall.name}</h4>

                    <svg
                        width={wallWidth}
                        height={wallHeight}
                        viewBox={selectedWall.viewBoxAdjusted}
                    >
                        <g transform={wallTransform}>
                            {selectedWall.paths.map((path, i) => 
                                <path
                                    key={i}
                                    d={path.path}
                                    stroke={Colors.BLACK}
                                    strokeWidth={1.5}
                                    strokeDasharray={path.strokeDash}
                                    fill={path.color || selectedArea.color}
                                />
                            )}
                            {routes.map((route: any) => {
                                return (
                                    <circle
                                        key={route.id}
                                        cx={route.data().coords ? route.data().coords[0] : 0}
                                        cy={route.data().coords ? route.data().coords[1] : 0}
                                        fill={route.data().color}
                                        r={selectedWall.routeRadius}
                                        stroke="black"
                                    />
                                )
                            })}
                        </g>
                    </svg>

                    <div style={{width: '100%', marginTop: 20}}>
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <h5 style={{ padding: 5 }}>
                                Routes
                            </h5>
                            <div style={{display: 'flex', paddingRight: 5, alignItems: 'center', justifyContent: 'center'}}>
                                <button
                                    onClick={handleToggleIsPrintTagsOpen}
                                    disabled={selectedRoutes.length === 0}
                                    style={{
                                        marginRight: 50,
                                        backgroundColor: selectedRoutes.length === 0 ? Colors.MUSTARDSHADE : Colors.MUSTARD,
                                        borderRadius: 3,
                                        border: '1px solid grey',
                                        padding: '3px 30px 3px 30px'
                                    }}
                                >
                                    Print Tags
                                </button>
                                <input
                                    style={{width: 18, height: 18}}
                                    type="checkbox"
                                    checked={routes.length === selectedRoutes.length}
                                    onChange={handleSelectAllClick}
                                />
                            </div>
                        </div>

                        {routes
                            .sort((a: any, b: any) => a.data().order - b.data().order)
                            .map((route: any, i: number) => {
                                const isChecked = selectedRoutes.findIndex(selectedRoute => selectedRoute.id === route.id) !== -1;
                                const handleCheckBoxClicked = () => {
                                    if (isChecked) {
                                        setSelectedRoutes(selectedRoutes.filter(selectedRoute => route.id !== selectedRoute.id));
                                    } else {
                                        setSelectedRoutes([route, ...selectedRoutes]);
                                    }
                                }

                                return (
                                    <div
                                        key={route.id}
                                        style={{ borderTop: '1px solid #d9d9d9', display: 'flex', alignItems: 'center', padding: 5, justifyContent: 'space-between' }}
                                    >
                                        <div style={{display: 'flex'}}>
                                            <div style={{
                                                backgroundColor: route.data().color,
                                                border: route.data().color === Colors.WHITE ? '1px solid grey' : 'none',
                                                height: 24,
                                                width: 24,
                                                borderRadius: 50,
                                                marginRight: 15
                                            }} />
                                            <p style={{ margin: 0, fontSize: 18}}>
                                                {(route.data().name || ReverseColorLookup[route.data().color]) + ' ' + (route.data().gradeLabel || route.data().grade)}
                                            </p>
                                        </div>
                                        <input
                                            style={{width: 18, height: 18}}
                                            type="checkbox"
                                            id={route.id}
                                            value={route.id}
                                            checked={isChecked}
                                            onChange={handleCheckBoxClicked}
                                        />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default WallPage;
