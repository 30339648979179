import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';

import db from '../../firebase.config';
import { IGym } from '../../interfaces';
import { Colors } from '../../constants';

const clubsRef = db.collection('clubs');

const ManageClubsPage = ({
  gym,
} : {
  gym: IGym;
}) => {
  const [clubs, setClubs] = useState<any>(undefined);

  useEffect(() => {
    if (gym?.id) {
      clubsRef
        .where(`gyms.${gym.id}`, '==', gym.name)
        .where('deletedAt', '==', null)
        .get()
        .then(clubDocs => clubDocs && setClubs(clubDocs.docs))
    }
  }, [gym?.id, gym?.name]);

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100%', padding: 20}}>
      <div>
        <h4>Clubs</h4>
        <p style={{fontSize: 16, color: Colors.DRKGREY}}>Clubs can be viewed, suspended, and deleted from this admin site, but creating and managing a club needs to be completed in the mobile app!</p>
      </div>

      {!clubs ? (
        <Spinner animation="border" />
      ) :
      clubs.length === 0 ? (
        <p>No clubs at this time</p>
      ) : (
        <ul className="list-group list-group-flush">
          {clubs.map((club: any) => {
            const clubData = club.data();
            const clubLink = '/clubs/' + club.id + '/updates';

            return (
              <li key={club.id} style={{ padding: 0, border: '1px solid grey', borderRadius: '10px', marginBottom: '20px' }} className="list-group-item">
                <Link to={clubLink} style={{textDecorationLine: 'none', color: 'black'}}>
                  <div style={{display: 'flex', flexDirection: 'row', padding: 15}}>
                    <img alt='club-profile' src={clubData.imageUri} height={150} width={150} />
                    <div style={{paddingLeft: 15}}>
                      <p style={{marginBottom: 5, fontWeight: 'bold'}}>{clubData.name}</p>
                      <h6 style={{marginBottom: 0}}>About:</h6>
                      <p>{clubData.description}</p>
                      <h6 style={{marginBottom: 0}}>Owner:</h6>
                      <p style={{marginBottom: 0}}>{clubData.owner.name}</p>
                    </div>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  );
}

const mapStateToProps = function(state: any) {
  return {
    gym: state.gym,
  }
}

export default connect(mapStateToProps)(ManageClubsPage);
