import React from 'react';

import RouteTag from './RouteTag';
import { ITagTemplate } from '../../../interfaces';

import './PrintTagComponent.css';

const printerPaperWidth = 2550;
const printerPaperHeight = 3300;

enum PrintOrientation {
    LANDSCAPE = 'landscape',
    PORTRAIT = 'portrait',
}

const PrintTagPreview = React.forwardRef(({
    routes,
    areaName,
    wallName,
    template,
    zoomOffset,
    orientation,
    gymAvatarUri,
} : {
    routes: any[];
    areaName: string;
    wallName: string;
    template: ITagTemplate;
    zoomOffset: number;
    orientation: PrintOrientation;
    gymAvatarUri: string;
}, ref: any) => {
    if (!gymAvatarUri) return null;

    const previewPadding = 10;
    let previewWidth = printerPaperWidth;
    let previewHeight = printerPaperHeight;
    if (orientation === PrintOrientation.LANDSCAPE) {
        previewWidth = printerPaperHeight;
        previewHeight = printerPaperWidth;
    }

    const usablePreviewWidth = previewWidth - previewPadding * 2;
    const usablePreviewHeight = previewHeight - previewPadding * 2;
    const tagsPerRow = Math.trunc(usablePreviewWidth / (template.width * (template.zoom + zoomOffset)));
    const tagsPerColumn = Math.trunc(usablePreviewHeight / (template.height * (template.zoom + zoomOffset)));

    let tagsDisplay: any[] = [];
    let rowOfTags: any[] = [];
    let numRows = 1;
    for (let i=0; i<routes.length; i++) {
        rowOfTags.push(
            <RouteTag
                key={routes[i].id}
                route={{id: routes[i].id, ...routes[i].data()}}
                gymImageUri={gymAvatarUri}
                areaName={areaName}
                wallName={wallName}
                template={template}
                zoom={template.zoom + zoomOffset}
            />
        );
        if (rowOfTags.length >= tagsPerRow) {
            tagsDisplay.push(
                <div key={numRows} style={{display: 'flex', pageBreakAfter: numRows % tagsPerColumn === 0 ? 'always' : 'auto'}}>
                    {rowOfTags}
                </div>
            );
            numRows++;
            rowOfTags = [];
        }
    }
    if (rowOfTags.length !== 0) {
        tagsDisplay.push(
            <div key={numRows} style={{display: 'flex', pageBreakAfter: numRows % tagsPerColumn === 0 ? 'always' : 'auto'}}>
                {rowOfTags}
            </div>
        );
    }

    return (
        <div ref={ref} style={{
            width: previewWidth,
            height: previewHeight,
            padding: previewPadding,
        }}>
            {tagsDisplay}
        </div>
    );
});

export default PrintTagPreview;
