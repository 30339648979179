import React from 'react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';

import db from '../../../firebase.config';
import { useAuth } from '../../../contexts/AuthContext';
import { Colors } from '../../../constants';
import { IGym, IGymOption, IUser } from '../../../interfaces';
import { setGym } from '../../../redux/actions';

import './Header.css';

const gymsRef = db.collection('gyms');

const Header = ({
  gym,
  user,
  adminGyms,
  setGymRedux,
} : {
  gym: IGym;
  user: IUser;
  adminGyms: IGymOption[];
  setGymRedux: (gym: IGym) => void;
}) => {
  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
    } catch(err) {
      window.alert('Error signing out!')
    }
  }

  const handleAdminGymChange = async (e: any) => {
    if (e.nativeEvent.target.value) {
      const gymDoc = await gymsRef
        .doc(e.nativeEvent.target.value)
        .get();

      const gymData = gymDoc?.data();
      if (gymData) {
        await setGymRedux({
          id: gymDoc.id,
          ...gymData,
        } as IGym);

        window.location.href = '/';
      }
    }
  }

  return (
    <div className='headerContainer'>
      <div style={{display: 'flex', alignItems: 'center'}}>
        {(adminGyms?.length > 1) ? (
          <select
            className='headerGymSelect'
            value={gym.id}
            onChange={handleAdminGymChange}
            id='admin-gyms'
          >
            {adminGyms.map(gymOption => (
              <option
                key={gymOption.id}
                value={gymOption.id}
              >
                {gymOption.name}
              </option>
            ))}
          </select>
        ) : (
          <p style={{margin: 0, fontSize: 20, color: Colors.BLACK}}>
            {gym.name}
          </p>
        )}     
      </div>

      <div style={{display: 'flex', alignItems: 'center', marginLeft: 10}}>
        <p style={{margin: 0}}>
          Signed in as: {user.name}
        </p>
        <Button onClick={handleLogout} variant="link">
          Log Out
        </Button>
      </div>
    </div>
  );
}

const mapStateToProps = function(state: any) {
  return {
    gym: state.gym,
    user: state.user,
    adminGyms: state.adminGyms,
  }
}

const mapDispatchToProps = function(dispatch: any) {
  return {
    setGymRedux: (gym: IGym) => {
      dispatch(setGym({
        gym: gym,
      }))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
