import React, { useEffect, useState, useRef } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useParams, useHistory } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { connect } from 'react-redux';
import moment from 'moment';

import db from '../../firebase.config';
import ImageCropSelector from '../organisms/imageEditor/ImageCropSelector';
import { leagueSchema } from '../../validationSchema';
import { IArea, IGymOption } from '../../interfaces';
import { Colors } from '../../constants';
import { uploadImage } from '../../utils/uploadImage';
import './EditLeaguePage.css';

const areasRef = db.collection('areas');
const leaguesRef = db.collection('leagues');

const EditLeaguePage = ({
    gym,
} : {
    gym: IGymOption;
}) => {
    let { id: leagueId } = useParams<{id: string}>();
    const [league, setLeague] = useState<any>(undefined);
    const [areas, setAreas] = useState<IArea[] | undefined>(undefined);
    const [vMaxDescriptionIndex, setVMaxDescriptionIndex] = useState<number>(0);
    const [imageToUpload, setImageToUpload] = useState<any>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const areaMapClassName = useRef('leagueAreaMaps hidden');
    const history = useHistory();

    useEffect(() => {
        areasRef
            .where('gymId', '==', gym.id)
            .get()
            .then(areaDocs => areaDocs && setAreas(
                areaDocs.docs.map(areaDoc => ({id: areaDoc.id, ...areaDoc.data()} as IArea))
            ));
    }, [gym.id])

    useEffect(() => {
        if (leagueId === 'new') {
            const currentDate = new Date();
            const currentDateString = currentDate.toISOString();
            setLeague({
                deletedAt: null,
                gymId: gym.id,
                imageUri: 'https://firebasestorage.googleapis.com/v0/b/climb-time-2ed56.appspot.com/o/default-league.png?alt=media&token=bc67d96b-7bcc-4379-8581-863b1120a096',
                name: '',
                description: '',
                startDate: currentDateString,
                endDate: currentDateString,
                registrationStartDate: currentDateString,
                registrationEndDate: currentDateString,
                maxTeams: 0,
                maxMembersPerTeam: 4,
                numTeams: 0,
                numParticipants: 0,
                climbingTypes: {
                    bouldering: true,
                    ropes: true,
                },
                allowedStyles: {
                    flash: true,
                    redpoint: true,
                    repeat: false,
                },
                climbingDays: {
                    mon: true,
                    tue: true,
                    wed: true,
                    thu: true,
                    fri: true,
                    sat: true,
                    sun: true,
                },
                vMaxDescription: 'Hardest grade comfortably done in 5 attempts',
                scoring: {
                    threeBelow: 40,
                    twoBelow: 60,
                    oneBelow: 80,
                    atMax: 100,
                    oneAbove: 120,
                    twoAbove: 140,
                    threeAbove: 160,
                },
                applicableWalls: true,
            });
        } else {
            leaguesRef
                .doc(leagueId)
                .get()
                .then(leagueDoc => {
                    const leagueData = leagueDoc?.data();
                    if (leagueData) {
                        leagueData.startDate = leagueData.startDate?.toDate().toISOString();
                        leagueData.endDate = leagueData.endDate?.toDate().toISOString();
                        leagueData.registrationStartDate = leagueData.registrationStartDate?.toDate().toISOString();
                        leagueData.registrationEndDate = leagueData.registrationEndDate?.toDate().toISOString();
                        if (leagueData.applicableWalls !== true) {
                            areaMapClassName.current = 'leagueAreaMaps show'
                        }
                        setLeague(leagueData);
                    } else {
                        setLeague(null);
                    }
                });
        }
    }, [leagueId, gym.id]);

    const handleSaveLeague = async (values: any) => {
        setIsLoading(true);
        if (league.imageUri !== values.imageUri) {
            if (!imageToUpload) {
                window.alert('Image upload failed. Please reselect the image and try again.');
                setIsLoading(false);
                return;
            }
            const uploadImageUri = await uploadImage(imageToUpload, values.imageUri);
            values.imageUri = uploadImageUri;
        }

        values.startDate = new Date(values.startDate);
        values.endDate = new Date(values.endDate);
        values.registrationStartDate = new Date(values.registrationStartDate);
        values.registrationEndDate = new Date(values.registrationEndDate);

        try {
            if (leagueId === 'new') {
                const newLeagueRef = leaguesRef.doc();
                await newLeagueRef
                    .set({
                        id: newLeagueRef.id,
                        ...values
                    });
                history.replace('/leagues/' + newLeagueRef.id + '/leaderboard');
            } else {
                await leaguesRef
                    .doc(leagueId)
                    .update(values);
                history.goBack();
            }
        } catch (err: any) {
            window.alert('Error saving league. Please try again.');
            setIsLoading(false);
        }
    }

    const getAllWalls = () => {
        if (areas) {
            const allApplicableWalls: {[key: string]: boolean} = {};
            for (const area of areas) {
                for (const wall of area.walls) {
                    allApplicableWalls[wall.id] = true;
                }
            }
            return allApplicableWalls;
        }
        return undefined;
    }

    if (league === undefined) {
        return <Spinner animation="border" style={{margin: 20}} />
    } else if (league === null) {
        return <p>Error! Please go back and try again!</p>
    }

    const scoringKeys = [
        {key: 'threeBelow', label: '-3 Grades'},
        {key: 'twoBelow', label: '-2 Grades'},
        {key: 'oneBelow', label: '-1 Grade'},
        {key: 'atMax', label: 'V-Max Grade'},
        {key: 'oneAbove', label: '+1 Grade'},
        {key: 'twoAbove', label: '+2 Grades'},
        {key: 'threeAbove', label: '+3 Grades'},
    ];
    const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    const title = leagueId === 'new' ? 'Create League' : 'Edit League';

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100vh', margin: 20}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <button
                    onClick={history.goBack}
                    style={{ backgroundColor: 'transparent', alignSelf: 'flex-start', border: '1px solid grey', borderRadius: 10, marginRight: 10, marginTop: 2}}
                >
                    <p style={{margin: 0, padding: '0px 15px 0px 15px', color: 'black'}}>
                        &#8592;
                    </p>
                </button>
                <h4>{title}</h4>
            </div>

            <Formik
                initialValues={league}
                validationSchema={leagueSchema}
                onSubmit={handleSaveLeague}
            >
                {({ values, errors, touched, submitForm, setFieldValue }) => {
                    const handleImageChange = (imageBlob: any, blobUrl: string) => {
                        setFieldValue('imageUri', blobUrl);
                        setImageToUpload(imageBlob);
                    }

                    const handleVMaxDescriptionIndexChange = (e: any) => {
                        if (e.target.id) {
                            const index = parseInt(e.target.id);
                            if (index === 0) {
                                setFieldValue('vMaxDescription', 'Hardest grade comfortably done in 5 attempts');
                                setVMaxDescriptionIndex(index);
                            } else if (index === 1) {
                                setFieldValue('vMaxDescription', 'Hardest grade done in the last month');
                                setVMaxDescriptionIndex(index);
                            } else if (index === 2) {
                                setFieldValue('vMaxDescription', '');
                                setVMaxDescriptionIndex(index);
                            }
                        }
                    }

                    const handleDateChange = (e: any) => {
                        const parsedDate = e.target.value?.split('-');
                        const fieldName = e.target.name;
                        if (parsedDate.length === 3 && fieldName) {
                            const date = new Date(values[fieldName]);
                            date.setFullYear(parsedDate[0]);
                            date.setMonth(parsedDate[1] - 1);
                            date.setDate(parsedDate[2]);
                            setFieldValue(fieldName, date);
                        }
                    }

                    const handleTimeChange = (e: any) => {
                        const parsedTime = e.target.value?.split(':');
                        const fieldName = e.target.name;
                        if (parsedTime.length === 2 && fieldName) {
                            const date = new Date(values[fieldName]);
                            date.setHours(parsedTime[0]);
                            date.setMinutes(parsedTime[1]);
                            setFieldValue(fieldName, date);
                        }
                    }

                    const handleApplicableWallsIndexChange = (e: any) => {
                        if (e.target.id) {
                            const index = parseInt(e.target.id);
                            if (index === 0) {
                                setFieldValue('applicableWalls', true);
                                areaMapClassName.current = 'leagueAreaMaps hide'
                            } else if (index === 1) {
                                setFieldValue('applicableWalls', getAllWalls());
                                areaMapClassName.current = 'leagueAreaMaps show'
                            }
                        }
                    }

                    const handleToggleApplicableWall = (wallId: string) => {
                        const applicableWallsCopy = {...values.applicableWalls};
                        if (values.applicableWalls[wallId]) {
                            delete applicableWallsCopy[wallId];
                        } else {
                            applicableWallsCopy[wallId] = true;
                        }
                        setFieldValue('applicableWalls', applicableWallsCopy);
                    }

                    return (
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 15}}>
                            <div style={{display: 'flex'}}>
                                <div style={{margin: 'auto'}}>
                                    <ImageCropSelector
                                        initialImageSrc={values.imageUri}
                                        aspect={1}
                                        displayHeight={175}
                                        handleSaveImage={handleImageChange}
                                    />
                                </div>
                                <div style={{paddingLeft: 15, flex: 1}}>
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <p style={{marginBottom: 0, fontSize: 20}}>League Name:</p>
                                        {(errors.name && touched.name) &&
                                            <p style={{color: 'red', marginLeft: 10, marginBottom: 0}}>*Required</p>
                                        }
                                    </div>
                                    <Field
                                        name='name'
                                        placeholder='Name'
                                        type='text'
                                        style={{margin: '5px 0px 15px 0px', width: 400}}
                                    />
                                    <p style={{marginBottom: 0, fontSize: 20}}>Description:</p>
                                    <Field
                                        name='description'
                                        placeholder='Description'
                                        type='text'
                                        as='textarea'
                                        style={{margin: '5px 0px 15px 0px', width: 400, minHeight: 100}}
                                    />
                                </div>
                            </div>

                            <p style={{fontSize: 20, fontWeight: 'bold', marginBottom: 0}}>
                                Rules and Format
                            </p>
                            <div style={{display: 'flex', marginTop: 20}}>
                                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                    <p style={{marginBottom: 0, fontSize: 20}}>
                                        League Dates
                                    </p>
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                        <p style={{marginBottom: 0, marginRight: 15}}>Start date:</p>
                                        <input id='startDate' name='startDate' type="date" value={moment(values.startDate).format('YYYY-MM-DD')} onChange={handleDateChange} />
                                        <p style={{margin: '0px 10px 0px 10px'}}>at</p>
                                        <input id='startDate' name='startDate' type="time" value={moment(values.startDate).format('HH:mm')} onChange={handleTimeChange} />
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                        <p style={{marginBottom: 0, marginRight: 23}}>End date:</p>
                                        <input id='endDate' name='endDate' type="date" value={moment(values.endDate).format('YYYY-MM-DD')} onChange={handleDateChange} />
                                        <p style={{margin: '0px 10px 0px 10px'}}>at</p>
                                        <input id='endDate' name='endDate' type="time" value={moment(values.endDate).format('HH:mm')} onChange={handleTimeChange} />
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                    <p style={{marginBottom: 0, fontSize: 20}}>
                                        Registration Dates
                                    </p>
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                        <p style={{marginBottom: 0, marginRight: 15}}>Opens:</p>
                                        <input id='registrationStartDate' name='registrationStartDate' type="date" value={moment(values.registrationStartDate).format('YYYY-MM-DD')} onChange={handleDateChange} />
                                        <p style={{margin: '0px 10px 0px 10px'}}>at</p>
                                        <input id='registrationStartDate' name='registrationStartDate' type="time" value={moment(values.registrationStartDate).format('HH:mm')} onChange={handleTimeChange} />
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                        <p style={{marginBottom: 0, marginRight: 13}}>Closes:</p>
                                        <input id='registrationEndDate' name='registrationEndDate' type="date" value={moment(values.registrationEndDate).format('YYYY-MM-DD')} onChange={handleDateChange} />
                                        <p style={{margin: '0px 10px 0px 10px'}}>at</p>
                                        <input id='registrationEndDate' name='registrationEndDate' type="time" value={moment(values.registrationEndDate).format('HH:mm')} onChange={handleTimeChange} />
                                    </div>
                                </div>
                            </div>

                            <div style={{display: 'flex', marginTop: 25}}>
                                <p style={{margin: 0, marginRight: 10, fontSize: 20}}>Max # of Teams</p>
                                <Field
                                    name='maxTeams'
                                    type='number'
                                    style={{width: 70}}
                                />
                            </div>
                            <div style={{display: 'flex', marginTop: 7}}>
                                <p style={{margin: 0, marginRight: 10, fontSize: 20}}>Max # of members per team</p>
                                <Field
                                    name='maxMembersPerTeam'
                                    type='number'
                                    style={{width: 70}}
                                />
                            </div>

                            <div style={{marginTop: 20}}>
                                <p style={{marginBottom: 0, fontSize: 20}}>
                                    Climbing Types:
                                </p>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Field
                                        type='checkbox'
                                        name='climbingTypes.bouldering'
                                        style={{height: 20, width: 20}}
                                    />
                                    <p style={{marginBottom: 0, marginLeft: 10}}>Bouldering</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Field
                                        type='checkbox'
                                        name='climbingTypes.ropes'
                                        style={{height: 20, width: 20}}
                                    />
                                    <p style={{marginBottom: 0, marginLeft: 10}}>Ropes</p>
                                </div>
                            </div>

                            <div style={{marginTop: 20}}>
                                <p style={{marginBottom: 0, fontSize: 20}}>
                                    Styles Allowed:
                                </p>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Field
                                        type='checkbox'
                                        name='allowedStyles.flash'
                                        style={{height: 20, width: 20}}
                                    />
                                    <p style={{marginBottom: 0, marginLeft: 10}}>Flash</p>
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <Field
                                        type='checkbox'
                                        name='allowedStyles.redpoint'
                                        style={{height: 20, width: 20}}
                                    />
                                    <p style={{marginBottom: 0, marginLeft: 10}}>Redpoint</p>
                                </div>
                            </div>

                            <div style={{marginTop: 20}}>
                                <p style={{marginBottom: 0, fontSize: 20}}>
                                    Climbing Days:
                                </p>
                                {daysOfWeek.map(day => 
                                    <div key={day} style={{display: 'flex', alignItems: 'center'}}>
                                        <Field
                                            type='checkbox'
                                            name={`climbingDays.${day.toLowerCase()}`}
                                            style={{height: 20, width: 20}}
                                        />
                                        <p style={{marginBottom: 0, marginLeft: 10}}>{day}</p>
                                    </div>
                                )}
                            </div>

                            <p style={{fontSize: 20, fontWeight: 'bold', marginBottom: 5, marginTop: 25}}>
                                Scoring and Handicapping
                            </p>
                            <p style={{padding: '0 15px 0 15px'}}>
                                Scoring is based upon each climbers average number of points scored. A handicap is used to level the playing field and account for base strength of a climber. The base score is 100 points for completing a climb at your handicap level. Points are added or subtracted for grades harder or easier than your handicap. V-Max is your base grade that the handicap is based off. If any of you scoring needs aren’t met, please contact your ClimbTime rep.
                            </p>
                            <p style={{marginBottom: 0, fontSize: 20}}>
                                Base V-Max Grade on...
                            </p>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                <input
                                    type='radio'
                                    id='0'
                                    name='vMaxDescription-0'
                                    checked={vMaxDescriptionIndex === 0}
                                    onChange={handleVMaxDescriptionIndexChange}
                                    style={{width: 20, height: 20}}
                                />
                                <p style={{margin: 0, marginLeft: 10}}>Hardest grade comfortably done in 5 attempts</p>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                <input
                                    type='radio'
                                    id='1'
                                    name='vMaxDescription-1'
                                    checked={vMaxDescriptionIndex === 1}
                                    onChange={handleVMaxDescriptionIndexChange}
                                    style={{width: 20, height: 20}}
                                />
                                <p style={{margin: 0, marginLeft: 10}}>Hardest grade done in the last month</p>
                            </div>
                            <div style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                                <input
                                    type='radio'
                                    id='2'
                                    name='vMaxDescription-2'
                                    checked={vMaxDescriptionIndex === 2}
                                    onChange={handleVMaxDescriptionIndexChange}
                                    style={{width: 20, height: 20}}
                                />
                                <Field
                                    disabled={vMaxDescriptionIndex !== 2}
                                    name='vMaxDescription'
                                    placeholder='Custom handicap description'
                                    value={vMaxDescriptionIndex !== 2 ? '' : values.vMaxDescription}
                                    type='text'
                                    style={{width: 500, marginLeft: 10}}
                                />
                                {(errors.vMaxDescription && touched.vMaxDescription) &&
                                    <p style={{color: 'red', marginLeft: 10, marginBottom: 0}}>*Required</p>
                                }
                            </div>

                            <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: 20}}>
                                {scoringKeys.map(scoringKey => 
                                    <div key={scoringKey.key} style={{border: '1px solid grey', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '10vw'}}>
                                        <p style={{marginTop: 10, marginBottom: 0}}>{scoringKey.label}</p>
                                        <Field
                                            name={`scoring.${scoringKey.key}`}
                                            type='number'
                                            style={{textAlign: 'center', paddingLeft: 15, border: 'none', borderBottom: '1px solid grey', margin: 10, width: '80%'}}
                                        />
                                        <p style={{marginBottom: 10}}>Points</p>
                                    </div>
                                )}
                            </div>

                            <p style={{fontSize: 20, fontWeight: 'bold', marginBottom: 10, marginTop: 25}}>
                                Applicable Gym Areas
                            </p>
                            {!areas ?
                                <Spinner animation='border' />
                            :
                                <>
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                        <input
                                            type='radio'
                                            id='0'
                                            name='applicableWalls-0'
                                            checked={values.applicableWalls === true}
                                            onChange={handleApplicableWallsIndexChange}
                                            style={{width: 20, height: 20}}
                                        />
                                        <p style={{marginBottom: 0, marginLeft: 10}}>Entire gym is counted in league scoring</p>
                                    </div>
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: 10}}>
                                        <input
                                            type='radio'
                                            id='1'
                                            name='applicableWalls-1'
                                            checked={values.applicableWalls !== true}
                                            onChange={handleApplicableWallsIndexChange}
                                            style={{width: 20, height: 20}}
                                        />
                                        <p style={{marginBottom: 0, marginLeft: 10}}>Select the walls to be counted for league scoring</p>
                                    </div>
                                    <div className={areaMapClassName.current}>
                                        {areas.map(area =>
                                            <svg key={area.id} height={300} width={300 * 576/427} viewBox="0 0 576 427" style={{border: '1px solid grey', borderRadius: 10, margin: 5}}>
                                                {area.walls.map(wall => 
                                                    <g
                                                        key={wall.id}
                                                        fill={area.color}
                                                        stroke={Colors.BLACK}
                                                    >
                                                        {wall.paths.map((path, i) =>
                                                            <path
                                                                key={i}
                                                                d={path.path}
                                                                strokeDasharray={path.strokeDash}
                                                                fill={path.color || area.color}
                                                                opacity={(values.applicableWalls === true || values.applicableWalls[wall.id]) ? 1 : 0.5}
                                                                onClick={() => handleToggleApplicableWall(wall.id)}
                                                                style={{cursor: 'pointer'}}
                                                            />
                                                        )}
                                                        {wall.label.map((label: any, i: number) => 
                                                            <text key={i} fill='black' transform={label.transform} fontSize={12} x={label.x} y={label.y}>
                                                                {label.text}
                                                            </text>
                                                        )}
                                                    </g>
                                                )}
                                                {area.markers.map(marker => 
                                                    <g
                                                        key={marker.id}
                                                        fill={marker.color}
                                                        stroke={Colors.BLACK}
                                                        strokeWidth={1.5}
                                                    >
                                                        <path id={marker.id} d={marker.path} />
                                                        {marker.label.map((label: any, i: number) => 
                                                            <text key={i} transform={label.transform} fontFamily='Montserrat-ExtraBold' fontSize={14} x={label.x} y={label.y}>
                                                                {label.text}
                                                            </text>
                                                        )}
                                                    </g>
                                                )}
                                            </svg>
                                        )}
                                    </div>
                                </>
                            }

                            <button
                                type='button'
                                disabled={isLoading}
                                onClick={submitForm}
                                style={{display: 'flex', alignSelf: 'center', marginTop: 40, marginBottom: 50, borderRadius: 5, borderWidth: 1, backgroundColor: isLoading ? Colors.MUSTARDSHADE : Colors.MUSTARD}}
                            >
                                {isLoading ? <Spinner animation='border' /> : leagueId === 'new' ? 'Create League' : 'Save League'}
                            </button>
                        </div>
                    );
                }}
            </Formik>
        </div>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
    }
}

export default connect(mapStateToProps)(EditLeaguePage);
