import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyBj-fFdBSRzY8Lgw27DNKjG3MYWe2f5m-0",
    authDomain: "climb-time-2ed56.firebaseapp.com",
    projectId: "climb-time-2ed56",
    storageBucket: "climb-time-2ed56.appspot.com",
    messagingSenderId: "814914977847",
    appId: "1:814914977847:web:f98652e9cac754b1ac7fb2",
    measurementId: "G-J85VBD17QX"
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const storage = firebase.storage();
export const functions = firebase.functions();
export const firestore = firebase.firestore;
export default firebase.firestore();
