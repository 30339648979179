import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { BiTrash, BiPencil, BiCalendarPlus } from 'react-icons/bi';
import { connect } from 'react-redux';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { IGym } from '../../interfaces';

const gymRef = db.collection('gyms');

const ManageEventsPage = ({
  gym,
} : {
  gym: IGym;
}) => {
  const [events, setEvents] = useState<any>(undefined);
  const [flaggedEvent, setFlaggedEvent] = useState<any>(undefined);

  useEffect(() => {
    if (gym?.id) {
      gymRef
        .doc(gym.id)
        .collection('events')
        .where('deletedAt', '==', null)
        .orderBy('startDate', 'asc')
        .startAfter(moment().startOf('day').toDate())
        .get()
        .then(eventDocs => eventDocs && setEvents(
          eventDocs.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
        ))
    }
  }, [gym?.id]);

  const handleDeleteEvent = () => {
    if (flaggedEvent && gym?.id) {
      gymRef
        .doc(gym.id)
        .collection('events')
        .doc(flaggedEvent.id)
        .update({
          deletedAt: new Date(),
        });
      
      setEvents(
        events.filter((doc: any) => doc.id !== flaggedEvent.id)
      );
      setFlaggedEvent(undefined)
    }
  }

  const handleClearFlaggedNews = () => setFlaggedEvent(undefined);

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1, padding: 20}}>
      <h4>Events</h4>

      {flaggedEvent && 
        <Modal animation={false} show={true} onHide={handleClearFlaggedNews}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm Deletion</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this event?</Modal.Body>
          <Modal.Footer>
            <Button variant='light' onClick={handleClearFlaggedNews}>
              Close
            </Button>
            <Button variant='danger' onClick={handleDeleteEvent}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      }

      <Link to='events/create'>
        <button
          style={{position: 'fixed', bottom: 20, right: 20, borderRadius: 100, padding: 15, backgroundColor: Colors.MUSTARD, border: 'none', zIndex: 1}}
        >
          <BiCalendarPlus size={40} color={Colors.WHITE} />
        </button>
      </Link>

      {!events ? (
        <Spinner animation="border" />
      ) :
      events.length === 0 ? (
        <p>Create an event and get members together!</p>
      ) : (
        <ul className="list-group list-group-flush" style={{marginBottom: 80}}>
          {events
            .sort((a: any, b: any) => {
              const aStart = a.startDate.seconds === undefined ? a.startDate : a.startDate.toDate();
              const bStart = b.startDate.seconds === undefined ? b.startDate : b.startDate.toDate();
              return aStart.getTime() - bStart.getTime();
            })
            .map((event: any) => {
              const eventData = event;
              if (event.startDate.seconds !== undefined) {
                eventData.startDate = eventData.startDate.toDate();
                eventData.endDate = eventData.endDate.toDate();
              }
              const eventLink = '/events/' + event.id;
              const flagEvent = () => setFlaggedEvent(event);

              return (
                <li key={event.id} className="list-group-item">
                  <div style={{display: 'flex'}}>
                    <div style={{display: 'flex', flex: 1}}>
                      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                        <p style={{fontSize: 30, color: Colors.MUSTARD, fontWeight: 'bold', marginBottom: 0}}>
                          {moment(eventData.startDate).format('MMM')}
                        </p>
                        <p style={{fontSize: 30, lineHeight: 1, color: Colors.MUSTARD, marginBottom: 0}}>
                          {moment(eventData.startDate).format('DD')}
                        </p>
                        {eventData.multiDay && (
                          <>
                            <p style={{color: Colors.MUSTARD, marginBottom: 0, fontWeight: 'bold', lineHeight: 1}}>-</p>
                            {eventData.startDate.getMonth() !== eventData.endDate.getMonth() && (
                              <p style={{fontSize: 30, lineHeight: 1.4, color: Colors.MUSTARD, fontWeight: 'bold', marginBottom: 0}}>
                                {moment(eventData.endDate).format('MMM')}
                              </p>
                            )}
                            <p style={{fontSize: 30, lineHeight: 1, color: Colors.MUSTARD, marginBottom: 0}}>
                              {moment(eventData.endDate).format('DD')}
                            </p>
                          </>
                        )}
                      </div>

                      <div style={{flex: 1, marginLeft: '3%'}}>
                        <p style={{fontFamily: 'Montserrat', fontSize: 20, margin: 0, fontWeight: 'bold'}}>
                          {eventData.name}
                        </p>
                        <p style={{fontFamily: 'Montserrat', fontSize: 16, margin: 0}}>
                          {eventData.description}
                        </p>

                        <div style={{flexDirection: 'row'}}>
                          <div style={{flex: 1, marginRight: '5%' }}>
                            <p style={{fontSize: 14, color: Colors.GREY, marginTop: '1%'}}>
                              {moment(eventData.startDate).format('dddd, LT')} - {moment(eventData.endDate).format('LT')}
                            </p>

                            {eventData.availableSpots === 0 ? (
                              <div>
                                <p style={{fontSize: 14, color: Colors.GREY, marginTop: '1%'}}>
                                  Unlimited spots
                                </p>
                              </div>
                            ) : (
                              <div>
                                <div style={{borderColor: Colors.GREY, borderWidth: 1, display: 'flex', flexDirection: 'row', maxWidth: 500}}>
                                  <div style={{ height: 15, flex: eventData.attendeeIds.length, backgroundColor: Colors.MUSTARD}} />
                                  <div style={{ height: 15, flex: eventData.availableSpots - eventData.attendeeIds.length, backgroundColor: Colors.LTGREY}} />
                                </div>

                                <p style={{alignSelf: 'flex-end', color: Colors.GREY, fontSize: 12, margin: 0}}>
                                  {eventData.attendeeIds.length}/{eventData.availableSpots} spots filled
                                </p>
                              </div>
                            )}
                            
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                      <Link to={eventLink} style={{textDecorationLine: 'none', color: 'black', marginBottom: 10}}>
                        <BiPencil size={24} />
                      </Link>
                      <button onClick={flagEvent} style={{backgroundColor: 'transparent', border: 'none'}}>
                        <BiTrash size={24} />
                      </button>
                    </div>
                  </div>
                </li>
              )
            })
          }
        </ul>
      )}
    </div>
  );
}
const mapStateToProps = function(state: any) {
  return {
    gym: state.gym,
  }
}

export default connect(mapStateToProps)(ManageEventsPage);
