import { Colors } from './Colors';
import { RouteColorOptions } from './RouteColorOptions';

interface IReverseColorLookup {
    [key: string]: string;
} 

export const ReverseColorLookup: IReverseColorLookup = {};
ReverseColorLookup[Colors.MUSTARD] = 'Mustard';
ReverseColorLookup[Colors.RED] = 'Red';
ReverseColorLookup[Colors.GREEN] = 'Green';
ReverseColorLookup[Colors.BLUE] = 'Blue';
ReverseColorLookup[Colors.LTGREY] = 'Light Grey';
ReverseColorLookup[Colors.GREY] = 'Grey';
ReverseColorLookup[Colors.WHITE] = 'White';
ReverseColorLookup[Colors.OFFWHITE] = 'Off White';
ReverseColorLookup[Colors.YELLOW] = 'Yellow';
ReverseColorLookup[Colors.LTORANGE] = 'Light Orange';
ReverseColorLookup[Colors.ORANGE] = 'Orange';
ReverseColorLookup[Colors.DRKGREY] = 'Dark Grey';
ReverseColorLookup[Colors.LTGREEN] = 'Light Green';
ReverseColorLookup[Colors.TEAL] = 'Teal';
ReverseColorLookup[Colors.LTBLUE] = 'Light Blue';
ReverseColorLookup[Colors.LTPURPLE] = 'Light Purple';
ReverseColorLookup[Colors.PINK] = 'Pink';
ReverseColorLookup[Colors.BROWN] = 'Brown';
ReverseColorLookup[Colors.BLACK] = 'Black';
ReverseColorLookup[Colors.PURPLE] = 'Purple';

RouteColorOptions.forEach(colorOption => {
    ReverseColorLookup[colorOption.hex] = colorOption.name;
});
