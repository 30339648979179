import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { FaMedal } from 'react-icons/fa';
import { connect } from 'react-redux';
import moment from 'moment';

import db from '../../firebase.config';
import { IGym } from '../../interfaces';
import { Colors } from '../../constants';

const leaguesRef = db.collection('leagues');

const ManageLeaguesPage = ({
  gym,
} : {
  gym: IGym;
}) => {
  const [leagues, setLeagues] = useState<any>(undefined);

  useEffect(() => {
    if (gym?.id) {
      leaguesRef
        .where(`gymId`, '==', gym.id)
        .where('deletedAt', '==', null)
        .orderBy('endDate', 'desc')
        .limit(10)
        .get()
        .then(leagueDocs => leagueDocs && setLeagues(leagueDocs.docs))
    }
  }, [gym?.id]);

  const currentDate = new Date();
  const expiredLeagues: any[] = [];
  const openLeagues: any[] = [];
  for (const league of (leagues || [])) {
    if (league.data().endDate.toDate() < currentDate) {
      expiredLeagues.push(league);
    } else {
      openLeagues.push(league);
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100%', padding: 20}}>
      <h4>Open Leagues</h4>

      <Link to='leagues/edit/new'>
        <button
          style={{display: 'flex', position: 'fixed', bottom: 20, right: 20, borderRadius: 100, padding: '20px 12px 20px 12px', backgroundColor: Colors.MUSTARD, border: 'none', zIndex: 1}}
        >
          <p style={{margin: 0, fontSize: 24, fontWeight: 'bold', color: Colors.WHITE}}>+</p>
          <FaMedal size={40} color={Colors.WHITE} />
        </button>
      </Link>

      {!leagues ? (
        <Spinner animation="border" />
      ) :
      leagues.length === 0 ? (
        <p>No leagues at this time</p>
      ) : (
        <>
          <ul className="list-group list-group-flush">
            {openLeagues.map((league: any) => {
              const leagueData = league.data();
              const leagueLink = '/leagues/' + league.id + '/leaderboard';
              const registrationStatus = leagueData.registrationStartDate.toDate() > currentDate ?
                `Opening ${moment(leagueData.registrationStartDate.toDate()).format('LL')}` :
                leagueData.registrationEndDate.toDate() > currentDate ?
                'Open' :
                'Closed';

              return (
                <li key={league.id} style={{ padding: 0, border: '1px solid grey', borderRadius: '10px', marginBottom: '20px' }} className="list-group-item">
                  <Link to={leagueLink} style={{textDecorationLine: 'none', color: 'black'}}>
                    <div style={{display: 'flex', padding: 15}}>
                      <img alt='league-profile' src={leagueData.imageUri} height={150} width={150} style={{marginRight: 15, borderRadius: 5, border: '1px solid #c9c9c9'}} />
                      <div>
                        <p style={{marginBottom: 0, marginRight: 20, fontWeight: 'bold', fontSize: 24}}>
                          {leagueData.name}
                        </p>
                        <p style={{marginBottom: 5, fontSize: 18}}>
                          {moment(leagueData.startDate.toDate()).format('LL')} - {moment(leagueData.endDate.toDate()).format('LL')}
                        </p>

                        <p style={{fontSize: 18}}>
                          Registration: {registrationStatus}
                        </p>
                        {leagueData.description && (
                          <>
                            <h6 style={{marginBottom: 0, fontWeight: 'bold'}}>Description:</h6>
                            <p style={{marginBottom: 0}}>{leagueData.description}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </Link>
                </li>
              )
            })}
          </ul>
          <p style={{fontSize: 20, marginBottom: 5, marginTop: 10}}>Past Leagues</p>
          <ul className="list-group list-group-flush">
            {expiredLeagues.map((league: any) => {
              const leagueData = league.data();
              const leagueLink = '/leagues/' + league.id + '/leaderboard';
              const registrationStatus = leagueData.registrationStartDate.toDate() > currentDate ?
                `Opening ${moment(leagueData.registrationStartDate.toDate()).format('LL')}` :
                leagueData.registrationEndDate.toDate() > currentDate ?
                'Open' :
                'Closed';

              return (
                <li key={league.id} style={{ padding: 0, border: '1px solid grey', borderRadius: '10px', marginBottom: '20px' }} className="list-group-item">
                  <Link to={leagueLink} style={{textDecorationLine: 'none', color: 'black'}}>
                    <div style={{display: 'flex', padding: 15}}>
                      <img alt='league-profile' src={leagueData.imageUri} height={150} width={150} style={{marginRight: 15, borderRadius: 5, border: '1px solid #c9c9c9'}} />
                      <div>
                        <p style={{marginBottom: 0, marginRight: 20, fontWeight: 'bold', fontSize: 24}}>
                          {leagueData.name}
                        </p>
                        <p style={{marginBottom: 5, fontSize: 18}}>
                          {moment(leagueData.startDate.toDate()).format('LL')} - {moment(leagueData.endDate.toDate()).format('LL')}
                        </p>

                        <p style={{fontSize: 18}}>
                          Registration: {registrationStatus}
                        </p>
                        {leagueData.description && (
                          <>
                            <h6 style={{marginBottom: 0, fontWeight: 'bold'}}>Description:</h6>
                            <p style={{marginBottom: 0}}>{leagueData.description}</p>
                          </>
                        )}
                      </div>
                    </div>
                  </Link>
                </li>
              )
            })}
          </ul>
        </>
      )}
    </div>
  );
}

const mapStateToProps = function(state: any) {
  return {
    gym: state.gym,
  }
}

export default connect(mapStateToProps)(ManageLeaguesPage);
