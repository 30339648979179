import * as Yup from 'yup';

export default Yup.object().shape({
    imageUri: Yup.string(),
    name: Yup.string()
        .required('Required'),
    description: Yup.string(),
    startDate: Yup.date()
        .required('Required'),
    endDate: Yup.date()
        .required('Required'),
    registrationStartDate: Yup.date()
        .required('Required'),
    registrationEndDate: Yup.date()
        .required('Required'),
    maxTeams: Yup.number()
        .required('Required'),
    maxMembersPerTeam: Yup.number()
        .required('Required'),
    climbingTypes: Yup.object().shape({
            bouldering: Yup.boolean().required(),
            ropes: Yup.boolean().required(),
        })
        .required('Required'),
    allowedStyles: Yup.object().shape({
            flash: Yup.boolean().required(),
            redpoint: Yup.boolean().required(),
            repeat: Yup.boolean().required(),
        })
        .required('Required'),
    climbingDays: Yup.object().shape({
            mon: Yup.boolean().required(),
            tue: Yup.boolean().required(),
            wed: Yup.boolean().required(),
            thu: Yup.boolean().required(),
            fri: Yup.boolean().required(),
            sat: Yup.boolean().required(),
            sun: Yup.boolean().required(),
        })
        .required('Required'),
    vMaxDescription: Yup.string()
        .required('Required'),
    applicableWalls: Yup.mixed()
        .required('Required'),
});
