import React, { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

import { FaApple, FaFacebook } from 'react-icons/fa';
import { FcGoogle } from 'react-icons/fc';

import { useAuth } from '../../contexts/AuthContext';
import ClimbTimeLogoClimber from '../../assets/climb-time-logo-climber.png';
import { Colors } from '../../constants';

import './LoginPage.css';

enum LoginType {
    Google = 'google',
    Apple = 'apple',
    Facebook = 'facebook',
    Email = 'email',
}

const LoginPage = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const emailRef = useRef<any>();
    const passwordRef = useRef<any>();
    const {
        handleEmailLogin,
        handleGoogleLogin,
        handleFacebookLogin,
        handleAppleLogin,
    } = useAuth();

    const handleLogin = async (e: any, loginType: LoginType) => {
        e.preventDefault();
        setLoading(true);
        setError(undefined);

        try {
            switch (loginType) {
                case LoginType.Email: {
                    await handleEmailLogin(emailRef.current.value, passwordRef.current.value);
                    break;
                }
                case LoginType.Facebook: {
                    await handleFacebookLogin();
                    break;
                }
                case LoginType.Apple: {
                    await handleAppleLogin();
                    break;
                }
                case LoginType.Google: {
                    await handleGoogleLogin();
                    break;
                }
            }
        } catch (err: any) {
            console.log(err.code);
            if (err.code !== 'auth/popup-closed-by-user' && err.code !== 'auth/cancelled-popup-request') {
                setError('Failed to log in!');
            }
            if (err.code === 'auth/account-exists-with-different-credential') {
                setError('Account exists with different provider.\nTry another sign in method!');
            }
            setLoading(false);
        }
    }

    const handleEmailLoginClick = (e: any) => handleLogin(e, LoginType.Email);
    const handleFacebookLoginClick = (e: any) => handleLogin(e, LoginType.Facebook);
    const handleAppleLoginClick = (e: any) => handleLogin(e, LoginType.Apple);
    const handleGoogleLoginClick = (e: any) => handleLogin(e, LoginType.Google);

    return (
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '1000px', alignItems: 'center'}}>
            {loading && (
                <div style={{position: 'absolute', zIndex: 999, width: '100%', height: '100%', backgroundColor: Colors.MODALBACKGROUND, display: 'flex', justifyContent: 'center'}}>
                    <Spinner animation='border' variant="light" style={{alignSelf: 'center'}} />
                </div>
            )}
            <div className='LoginContainer'>
                <img src={ClimbTimeLogoClimber} alt='climb time climber' className='loginClimberImage' />

                <div>
                    {error && <Alert variant='danger'>{error}</Alert>}
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <p style={{fontSize: 14, fontWeight: 'bold', margin: 0}}>
                            To continue, log in to the ClimbTime Gym Portal
                        </p>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', zIndex: 9999}}>
                        <button onClick={handleFacebookLoginClick} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: "#3b5998", margin: '10px 0px 0px 0px', padding: 10, borderRadius: 50}}>
                            <FaFacebook name="facebook" color='white' size={20} />
                            <p style={{color: Colors.WHITE, fontSize: 16, textAlign: 'center', margin: '0px 0px 0px 10px'}}>
                                Continue with Facebook
                            </p>
                        </button>
                        <button onClick={handleAppleLoginClick} style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', backgroundColor: 'black', margin: '10px 0px 0px 0px', padding: 10, borderRadius: 50}}>
                            <FaApple color='white' size={20} />
                            <p style={{color: Colors.WHITE, fontSize: 16, textAlign: 'center', margin: '0px 0px 0px 10px'}}>
                                Continue with Apple
                            </p>
                        </button>
                        <button onClick={handleGoogleLoginClick} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', backgroundColor: "white", margin: '10px 0px 0px 0px', padding: 10, borderRadius: 50}}>
                            <FcGoogle size={20} />
                            <p style={{fontSize: 16, textAlign: 'center', margin: '0px 0px 0px 10px'}}>
                                Continue with Google
                            </p>
                        </button>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'row', margin: '20px 0px 20px 0px'}}>
                        <div style={{width: '100%', borderTop: '1px solid grey', alignSelf: 'center'}} />
                        <p style={{margin: '0px 20px 0px 20px', fontSize: 12, fontWeight: 'bold'}}>
                            OR
                        </p>
                        <div style={{width: '100%', borderTop: '1px solid grey', alignSelf: 'center'}} />
                    </div>
                    <Card style={{maxWidth: '500px', width: '80vw'}}>
                        <Card.Body>
                            <Form onSubmit={handleEmailLoginClick}>
                                <Form.Group id='email'>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type='email' placeholder='Email' ref={emailRef} required />
                                </Form.Group>
                                <Form.Group id='password'>
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type='password' placeholder='Password' ref={passwordRef} required />
                                </Form.Group>
                                <Button
                                    disabled={loading}
                                    className='w-100'
                                    type='submit'
                                >
                                    Log In
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
                
            </div>
            

            <div style={{textAlign: 'center', marginTop: 25}}>
                <p style={{fontSize: '18px', fontWeight: 'bold', padding: 20}}>
                    Not yet on ClimbTime? Click <a href="https://climb-time.com/for-gyms/request-demo">here</a> to set up a demo and get started today!
                </p>
            </div>
        </div>
    );
}

export default LoginPage;
