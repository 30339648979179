import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { Switch, Route, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

import db, { functions } from '../../firebase.config';
import ClubChannels from './ClubChannels';
import ClubEvents from './ClubEvents';
import ClubMembers from './ClubMembers';
import ClubUpdates from './ClubUpdates';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { Colors } from '../../constants';
import { IGymOption } from '../../interfaces';
import './ClubPage.css';

const clubsRef = db.collection('clubs');
const updateClubStatus = functions.httpsCallable('gymAdminRequest-updateClubStatus');

enum FlagType {
    Deletion = 'Deletion',
    Suspend = 'Suspension',
    Unsuspend = 'Unsuspension'
}

const ClubPage = ({
    gym,
} : {
    gym: IGymOption,
}) => {
    let { id: clubId } = useParams<{id: string}>();
    const [club, setClub] = useState<any>(undefined);
    const [flag, setFlag] = useState<FlagType | undefined>(undefined);
    const [isLoadingAction, setIsLoadingAction] = useState<boolean>(false);

    useEffect(() => {
        clubsRef
            .doc(clubId)
            .get()
            .then(clubDoc => {
                if (clubDoc && clubDoc.exists) {
                    setClub(clubDoc.data())
                } else {
                    setClub(null);
                }
            });
    }, [clubId]);

    const handleConfirmFlag = async () => {
        if (flag !== undefined) {
            setIsLoadingAction(true);
            const action = flag === FlagType.Deletion ?
                'delete' : flag === FlagType.Unsuspend ?
                'unsuspend' :
                'suspend';
            try {
                await updateClubStatus({
                    clubId: clubId,
                    gymId: gym.id,
                    action: action,
                });

                if (flag === FlagType.Suspend) {
                    const clubCopy = {...club};
                    clubCopy.suspendedAt = new Date();
                    setClub(clubCopy);
                    setIsLoadingAction(false);
                    handleClearFlag();
                } else if (flag === FlagType.Unsuspend) {
                    const clubCopy = {...club};
                    clubCopy.suspendedAt = null;
                    setClub(clubCopy);
                    setIsLoadingAction(false);
                    handleClearFlag();
                } else if (flag === FlagType.Deletion) {
                    window.location.href = '/clubs';
                }
            } catch (err) {
                setIsLoadingAction(false);
                window.alert('Error! Please try again.');
            }
        }
    }

    const handleDeleteClubClick = () => setFlag(FlagType.Deletion);
    const handleSuspendClubClick = () => setFlag(FlagType.Suspend);
    const handleUnsuspendClubClick = () => setFlag(FlagType.Unsuspend);
    const handleClearFlag = () => setFlag(undefined);

    if (club === undefined) {
        return (
            <div style={{margin: 20}}>
                <Spinner animation="border" />
            </div>
        );
    } else if (club === null) {
        return <p style={{margin: 20}}>Error! Please go back and try again!</p>
    }

    const tabName = window.location.pathname.split('/').pop();

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100vh', margin: 20}}>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Link
                    to='/clubs'
                    style={{display: 'flex', borderRadius: 50, alignSelf: 'flex-start', padding: 10, marginTop: 10, marginBottom: 15, backgroundColor: Colors.OFFWHITE, border: 'none'}}
                >
                    <BiLeftArrowAlt size={24} color={Colors.DRKGREY} />
                </Link>

                <h4 style={{marginLeft: 20}}>
                    {club.name}
                </h4>
            </div>
            {(flag !== undefined) && (
                <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 9999, backgroundColor: Colors.MODALBACKGROUND}}>
                    <div style={{marginTop: 25, width: '50%', minWidth: '350px', backgroundColor: Colors.WHITE, marginLeft: 'auto', marginRight: 'auto', borderRadius: 5}}>
                        <div style={{padding: '10px 20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <p style={{fontSize: 24}}>Confirm {flag}</p>
                            <button onClick={handleClearFlag} style={{border: 'none', backgroundColor: 'white'}}>
                                <p style={{fontSize: 18, fontWeight: 'bold'}}>&#x2715;</p>
                            </button>
                        </div>
                        <div style={{padding: '20px 20px'}}>
                            <p>
                                {flag === FlagType.Deletion ?
                                    `Are you sure you want to delete the club: ${club.name}? This action can not be undone.` : flag === FlagType.Unsuspend ? 
                                    `Are you sure you want to unsuspend the club: ${club.name}? You can suspend this club again if necessary.` :
                                    `Are you sure you want to suspend the club: ${club.name}? This action can be undone by the gym admin only.`
                                }
                            </p>
                        </div>
                        <div style={{padding: '10px 10px', display: 'flex', flexDirection: 'row-reverse'}}>
                            {isLoadingAction ?
                                <div style={{margin: '5px 40px'}}>
                                    <Spinner animation='border' size='sm' />
                                </div>
                            :
                                <Button variant='danger' onClick={handleConfirmFlag}>
                                    {flag === FlagType.Deletion ? 'Delete' : flag === FlagType.Unsuspend ? 'Unsuspend' : 'Suspend'}
                                </Button>
                            }
                            <Button variant='light' style={{marginRight: 15}} onClick={handleClearFlag}>
                                Close
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <img alt='club-profile' src={club.imageUri} height={150} width={150} />
                <div style={{paddingLeft: 15, flex: 1}}>
                    <h6 style={{marginBottom: 0}}>About:</h6>
                    <p>{club.description}</p>
                    <h6 style={{marginBottom: 0}}>Owner:</h6>
                    <p>{club.owner.name}</p>
                </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 10, borderBottom: '3px solid #f2d394'}}>
                <div style={{alignItems: 'center', display: 'flex'}}>
                    <Link to={`/clubs/${clubId}/updates`} className={tabName === 'updates' ? 'club-links club-link-active' : 'club-links'}>
                        Updates
                    </Link>
                    <Link to={`/clubs/${clubId}/events`} className={tabName === 'events' ? 'club-links club-link-active' : 'club-links'}>
                        Events
                    </Link>
                    <Link to={`/clubs/${clubId}/channels`} className={tabName === 'channels' ? 'club-links club-link-active' : 'club-links'}>
                        Channels
                    </Link>
                    <Link to={`/clubs/${clubId}/members`} className={tabName === 'members' ? 'club-links club-link-active' : 'club-links'}>
                        Members
                    </Link>
                </div>
                
                <div style={{display: 'flex'}}>
                    {club.suspendedAt === null ? (
                        <button
                            onClick={handleSuspendClubClick}
                            style={{backgroundColor: 'transparent', color: Colors.LTRED, margin: '5px 10px', border: '1px solid lightgrey', borderRadius: 5}}
                        >
                            Suspend Club
                        </button>
                    ) : (
                        <button
                            onClick={handleUnsuspendClubClick}
                            style={{backgroundColor: 'transparent', color: Colors.LTRED, margin: '5px 10px', border: '1px solid lightgrey', borderRadius: 5}}
                        >
                            Unsuspend Club
                        </button>
                    )}

                    <button
                        onClick={handleDeleteClubClick}
                        style={{backgroundColor: 'transparent', margin: '5px 0px', color: Colors.DRKRED, border: '1px solid lightgrey', borderRadius: 5}}
                    >
                        Delete Club
                    </button>
                </div>
            </div>
            
            {club.suspendedAt !== null ? (
                <p style={{fontSize: 20, fontWeight: 'bold', padding: 20}}>
                    This club is suspended
                </p>
            ) : (
                <Switch>
                    <Route
                        exact
                        path='/clubs/:id/updates'
                        children={<ClubUpdates clubId={clubId} gymId={gym.id} />}
                    />
                    <Route
                        exact
                        path='/clubs/:id/events'
                        children={<ClubEvents clubId={clubId} gymId={gym.id} />}
                    />
                    <Route
                        exact
                        path='/clubs/:id/members'
                        children={<ClubMembers clubId={clubId} ownerId={club.owner.id} />}
                    />
                    <Route
                        exact
                        path='/clubs/:id/channels'
                        children={<ClubChannels clubId={clubId} gymId={gym.id} />}
                    />
                </Switch>
            )}
            
        </div>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
    }
}

export default connect(mapStateToProps)(ClubPage);
