import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { connect } from 'react-redux';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { IGym } from '../../interfaces';

const gymRef = db.collection('gyms');
const usersRef = db.collection('users');

const FeedbackData = ({
    allFeedback,
    gym,
} : {
    allFeedback: any[];
    gym: IGym;
}) => {
    let { id: feedbackId } = useParams<{id: string}>();
    const [feedback, setFeedback] = useState<any>(undefined);
    const [userData, setUserData] = useState<any>(undefined);

    useEffect(() => {
        if (gym?.id) {
            gymRef
                .doc(gym.id)
                .collection('feedback')
                .doc(feedbackId)
                .get()
                .then(feedbackDoc => {
                    if (feedbackDoc && feedbackDoc.exists) {
                        setFeedback(feedbackDoc);
                        if (feedbackDoc.data()?.userId) {
                            usersRef
                                .doc(feedbackDoc.data()?.userId)
                                .get()
                                .then(userDoc => userDoc && setUserData(userDoc));
                        }
                        if (!feedbackDoc.data()?.readAt) {
                            gymRef
                                .doc(gym.id)
                                .collection('feedback')
                                .doc(feedbackId)
                                .update({
                                    readAt: new Date(),
                                });

                            allFeedback?.forEach((feedbackDoc: any) => {
                                if (feedbackId === feedbackDoc.id) {
                                    feedbackDoc.readAt = new Date();
                                }
                            })
                        }
                    } else {
                        setFeedback(null);
                    }
                });

            
        }
    }, [gym?.id, feedbackId, allFeedback]);

    if (feedback === undefined) {
        return <Spinner animation="border" />
    } else if (feedback === null) {
        return <p>Error! Please go back and try again!</p>
    }

    const feedbackData = feedback.data();

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, margin: 20}}>
            <h4>Feedback:</h4>

            <button
                onClick={() => window.history.back()}
                style={{display: 'flex', alignItems: 'center', alignSelf: 'flex-start', marginTop: 10, marginBottom: 15, backgroundColor: 'transparent', border: '1px solid grey'}}
            >
                <BiLeftArrowAlt size={24} />
                <p style={{margin: 0}}>Back</p>
            </button>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <div style={{width: '30%', minWidth: 400}}>
                    <p style={{fontSize: 18, fontWeight: 'bold', color: Colors.DRKGREY, marginTop: 3, marginBottom: 3}}>
                        Message:
                    </p>
                    <p>{feedbackData.message}</p>
                </div>

                <div>
                    <p style={{fontSize: 18, fontWeight: 'bold', color: Colors.DRKGREY, marginTop: 3, marginBottom: 3}}>
                        User:
                    </p>
                    {feedbackData.anonymous ? (
                        <p>Anonymous</p>
                    ) : (
                        <div>
                            <p>{feedbackData.email}</p>
                            {userData && 
                                <div>
                                    <p>{userData.data()?.name}</p>
                                    <img alt='user-profile' src={userData.data()?.profilePictureUri} />
                                </div>
                            }
                        </div>
                    )}
                </div>
            </div>

            <p style={{fontSize: 18, fontWeight: 'bold', color: Colors.DRKGREY, marginTop: 3, marginBottom: 3}}>
                Attached Photo:
            </p>
            <div>
                {feedbackData.photoUri ? <img height={400} alt='feedback-attachment' src={feedbackData.photoUri} /> : <p>None</p>}
            </div>
        </div>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
    }
}

export default connect(mapStateToProps)(FeedbackData);
