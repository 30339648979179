import React, { createContext, useContext, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase/app';

import db, { auth } from '../firebase.config';
import { IGym, IGymOption, IUser } from '../interfaces';
import { logoutUser, setAdminGyms, setGym, storeUser } from '../redux/actions';

type ContextProps = {
    currentUser: any,
    logout: any;
    handleEmailLogin: any;
    handleGoogleLogin: any;
    handleFacebookLogin: any;
    handleAppleLogin: any;
};

const usersRef = db.collection('users');
const gymsRef = db.collection('gyms');
const googleProvider = new firebase.auth.GoogleAuthProvider();
const facebookProvider = new firebase.auth.FacebookAuthProvider();
const appleProvider = new firebase.auth.OAuthProvider('apple.com');

const AuthContext = createContext<Partial<ContextProps>>({});

export function useAuth() {
    return useContext(AuthContext);
}

const AuthProvider = ({
    children,
    user: reduxUser,
    setGymRedux,
    setAdminGymsRedux,
    storeUserRedux,
    logoutUserRedux,
} : {
    children: any;
    user: IUser;
    setGymRedux: (gym: IGym) => void;
    setAdminGymsRedux: (adminGyms: IGymOption[]) => void;
    storeUserRedux: (user: IUser) => void;
    logoutUserRedux: () => void;
}) => {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user: any) => {
            try {
                if (user !== null) {
                    if (user.uid !== reduxUser.id) {
                        const userDoc = await usersRef
                            .doc(user.uid)
                            .get();

                        const userGyms = userDoc?.data()?.gyms;
                        const adminGyms: IGymOption[] = [];
                        for (const userGym of userGyms) {
                            const gymRoleDoc = await gymsRef
                                .doc(userGym.id)
                                .collection('admin')
                                .doc(user.uid)
                                .get()

                            if (
                                gymRoleDoc &&
                                gymRoleDoc.exists &&
                                ['admin', 'owner'].includes(gymRoleDoc.data()?.role)
                            ) {
                                adminGyms.push(userGym);
                            }
                        }

                        if (adminGyms.length === 0) { //They are not a gym admin and log them out
                            auth.signOut();
                            window.alert('You are not a gym admin!')
                        } else {
                            await storeUserRedux({
                                id: user.uid,
                                email: user.email,
                                ...userDoc.data(),
                            } as IUser);

                            await setAdminGymsRedux(adminGyms);

                            const gymDoc = await gymsRef
                                .doc(adminGyms[0].id)
                                .get();

                            const gymData = gymDoc?.data();
                            if (gymData) {
                                await setGymRedux({
                                    id: adminGyms[0].id,
                                    ...gymData,
                                } as IGym);
                            }
                            window.location.href = '/';
                        }
                    }
                } else {
                    logoutUserRedux();
                }

                setLoading(false);
            } catch (err) {
                auth.signOut();
            }
        });

        return unsubscribe;
    }, [logoutUserRedux, setAdminGymsRedux, setGymRedux, storeUserRedux, reduxUser.id]);

    const handleEmailLogin = async (email: string, password: string) => {
        await auth.signInWithEmailAndPassword(email, password);
    }

    const handleGoogleLogin = async () => await auth.signInWithPopup(googleProvider);
    const handleFacebookLogin = async () => await auth.signInWithPopup(facebookProvider);
    const handleAppleLogin = async () => await auth.signInWithPopup(appleProvider);

    const logout = () => {
        return auth.signOut();
    }

    const value = {
        handleEmailLogin,
        handleGoogleLogin,
        handleFacebookLogin,
        handleAppleLogin,
        logout,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}

const mapStateToProps = function(state: any) {
    return {
        user: state.user,
    }
}

const mapDispatchToProps = function(dispatch: any) {
    return {
        setGymRedux: (gym: IGym) => {
            dispatch( setGym({
                gym: gym,
            }))
        },
        setAdminGymsRedux: (adminGyms: IGymOption[]) => {
            dispatch( setAdminGyms({
                adminGyms: adminGyms,
            }))
        },
        storeUserRedux: (user: IUser) => {
            dispatch( storeUser({
                user: user,
            }))
        },
        logoutUserRedux: () => {
            dispatch(logoutUser())
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
