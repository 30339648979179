export const RouteColorOptions = [
    // Black
    {
        hex: "#ffffff", 
        name: "White", 
    },
    {
        hex: "#d9d9d9", 
        name: "Light Grey", 
    },
    {
        hex: "#bfbfbf", 
        name: "Grey", 
    },
    {
        hex: "#919191", 
        name: "Grey", 
    },
    {
        hex: "#616161", 
        name: "Dark Grey", 
    },
    {
        hex: "#000000", 
        name: "Black", 
    },
    // Purple
    {
        hex: '#e2b0ff',
        name: 'light Purple'
    },
    {
        hex: '#cc73ff',
        name: 'light Purple'
    },
    {
        hex: "#ab00fa", 
        name: "Purple", 
    },
    {
        hex: "#7500ab", 
        name: "Purple", 
    },
    {
        hex: "#661e87", 
        name: "Dark Purple", 
    },
    {
        hex: "#440063", 
        name: "Dark Purple", 
    },
    // Blue
    {
        hex: "#b0c0ff", 
        name: "Light Blue", 
    },
    {
        hex: "#8099ff", 
        name: "Light Blue", 
    },
    {
        hex: "#3863ff", 
        name: "Blue", 
    },
    {
        hex: "#1949ff", 
        name: "Blue", 
    },
    {
        hex: "#4800f2", 
        name: "Blue", 
    },
    {
        hex: "#2b008f", 
        name: "Dark Blue", 
    },
    // Teal
    {
        hex: "#6be3ff", 
        name: "Teal", 
    },
    {
        hex: "#0db6de", 
        name: "Teal", 
    },
    {
        hex: "#199ebd", 
        name: "Teal", 
    },
    {
        hex: "#278ca3", 
        name: "Dark Teal", 
    },
    {
        hex: "#167d94", 
        name: "Dark Teal", 
    },
    {
        hex: "#006a82", 
        name: "Dark Teal", 
    },
    // Aqua
    {
        hex: "#91ffe2",
        name: "Light Aqua", 
    },
    {
        hex: "#69ffd7", 
        name: "Light Aqua", 
    },
    {
        hex: "#0af5b6", 
        name: "Aqua", 
    },
    {
        hex: "#06bf8e", 
        name: "Aqua", 
    },
    {
        hex: "#0ca67d", 
        name: "Dark Aqua", 
    },
    {
        hex: "#027d5c", 
        name: "Dark Aqua", 
    },
    // Green
    {
        hex: "#a8fc97", 
        name: "Light Green", 
    },
    {
        hex: "#7cf065", 
        name: "Light Green", 
    },
    {
        hex: "#3cf716", 
        name: "Green", 
    },
    {
        hex: "#26cc04", 
        name: "Green", 
    },
    {
        hex: "#1d9e03", 
        name: "Dark Green", 
    },
    {
        hex: "#107303", 
        name: "Dark Green", 
    },
    //Yellow
    {
        hex: "#fbff91", 
        name: "Light Yellow", 
    },
    {
        hex: "#f1f757", 
        name: "Light Yellow", 
    },
    {
        hex: "#eaf20f", 
        name: "Yellow", 
    },
    {
        hex: "#c2c900", 
        name: "Yellow", 
    },
    {
        hex: "#939902", 
        name: "Dark Yellow", 
    },
    {
        hex: "#6a6e01", 
        name: "Dark Yellow", 
    },
    // Brown
    {
        hex: "#fcc99d", 
        name: "Beige", 
    },
    {
        hex: "#ffc387", 
        name: "Beige", 
    },
    {
        hex: "#ffab57", 
        name: "Light Orange", 
    },
    {
        hex: "#f2891f", 
        name: "Orange", 
    },
    {
        hex: "#de7104", 
        name: "Orange", 
    },
    {
        hex: "#ba5f04", 
        name: "Brown", 
    },
    // Red
    {
        hex: "#ff9f94", 
        name: "Light Red", 
    },
    {
        hex: "#ff6a59", 
        name: "Light Red", 
    },
    {
        hex: "#fc4935", 
        name: "Red", 
    },
    {
        hex: "#fa1b02", 
        name: "Red", 
    },
    {
        hex: "#b51504", 
        name: "Dark Red", 
    },
    {
        hex: "#850d00", 
        name: "Dark Red", 
    },
    // Pink
    {
        hex: "#f7adea", 
        name: "Light Pink", 
    },
    {
        hex: "#f772de", 
        name: "Pink", 
    },
    {
        hex: "#f525ce", 
        name: "Pink", 
    },
    {
        hex: "#ed05c2", 
        name: "Pink", 
    },
    {
        hex: "#ab038c", 
        name: "Dark Pink", 
    },
    {
        hex: "#780262", 
        name: "Purple", 
    },
];
