import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { BiPlus, BiX } from 'react-icons/bi';
import { connect } from 'react-redux';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { IGym } from '../../interfaces';
import { capitalizeWords } from '../../utils/capitalizeWords';

const gymRef = db.collection('gyms');
const usersRef = db.collection('users');

const SetterSelectionModal = ({
    gym,
    handleCloseModal,
    handleAddSettersToList,
} : {
    gym: IGym;
    handleCloseModal: () => void;
    handleAddSettersToList: (setters: any[]) => void;
}) => {
    const [gymAdmins, setGymAdmins] = useState<any>(undefined);
    const [potentialSetters, setPotentialSetters] = useState<any>(undefined);
    const [selectedUsers, setSelectedUsers] = useState<any>(new Map());
    const [search, setSearch] = useState<string>('');
    const [climbSettingData, setClimbSettingData] = useState<any>(undefined);
    const [isLoadingAddSetters, setIsLoadingAddSetters] = useState<boolean>(false);
    const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);

    useEffect(() => {
        if (gym?.id) {
            //Note: the admins collection contains both admins and employees. They are differentiated with the role field
            gymRef
                .doc(gym.id)
                .collection('admin')
                .where('role', '==', 'admin')
                .get()
                .then(async potentialSetterDocs => {
                    if (potentialSetterDocs && !potentialSetterDocs.empty) {
                        const allPotentialSetterData: any[] = [];
                        for (const potentialSetterDoc of potentialSetterDocs.docs) {
                            const userDoc = await usersRef
                                .doc(potentialSetterDoc.id)
                                .get()
                
                            if (userDoc && userDoc.exists) {
                                const potentialSetterData = {...potentialSetterDoc.data()};
                                potentialSetterData.id = potentialSetterDoc.id;
                                potentialSetterData.profilePictureUri = userDoc.data()?.profilePictureUri;
                                potentialSetterData.name = userDoc.data()?.name;
                                allPotentialSetterData.push(potentialSetterData);
                            }
                        };
                        //sort alphabetically by name
                        allPotentialSetterData.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                        setGymAdmins(allPotentialSetterData);
                        setPotentialSetters(allPotentialSetterData);
                    } else {
                        setGymAdmins([]);
                    }
                })

            gymRef
                .doc(gym.id)
                .collection('climbSettingData')
                .doc(gym.id)
                .get()
                .then(climbSettingDataDoc => {
                    const climbSettingData: any = climbSettingDataDoc.data();
                    setClimbSettingData(climbSettingData);
                })
        }
    }, [gym?.id]);

    const handleAddSelectedUsers = async () => {
        if (selectedUsers) {
            setIsLoadingAddSetters(true);
            const selectedUsersData: any[] = []
            const settersUpdateObj: any = {}
            try {
                for(const [selectedUserId, selectedUserValues] of selectedUsers){
                    const userAdminDoc = await gymRef
                        .doc(gym.id)
                        .collection('admin')
                        .doc(selectedUserValues.id)
                        .get();

                    if (!userAdminDoc.exists) {
                        await gymRef
                            .doc(gym.id)
                            .collection('admin')
                            .doc(selectedUserValues.id)
                            .set({
                                role: 'employee',
                            });
                    }
                    selectedUsersData.push(
                        selectedUserValues
                    );
                    settersUpdateObj["setters." + selectedUserValues.id] = selectedUserValues.name;
                }
                await gymRef
                    .doc(gym.id)
                    .collection('climbSettingData')
                    .doc(gym.id)
                    .update(settersUpdateObj);
            } catch (err: any) {
                window.alert('Failed to add settes. Please try again.');
                setIsLoadingAddSetters(false);
                return;
            }

            setIsLoadingAddSetters(false);
            handleAddSettersToList(selectedUsersData);
            handleCloseModal();
        }
    }

    const handleSelectUser = (selectedUser: any) => {
        setSelectedUsers(new Map(selectedUsers.set(selectedUser.id, selectedUser)));
    };
    const handleRemoveSelectedUser = (selectedUser: any) => {
        selectedUsers.delete(selectedUser.id);
        setSelectedUsers(new Map(selectedUsers));
    };

    const handleChangeSearch = (e: any) => setSearch(e.target.value);
    const handleSubmitSearch = async () => {
        if (search.trim() === '') {
            setPotentialSetters(gymAdmins);
        } else {
            setIsLoadingUsers(true);
            const searchPhrase = capitalizeWords(search.trim());
            const potentialSetterDocs = await usersRef
                .where('name', '>=', searchPhrase)
                .where('name', '<=', searchPhrase + '\uf8ff')
                .limit(8)
                .get()

            if (potentialSetterDocs && !potentialSetterDocs.empty) {
                setPotentialSetters(
                    potentialSetterDocs.docs
                    .map(potentialSetterDoc => 
                        ({id: potentialSetterDoc.id, ...potentialSetterDoc.data()})
                    )
                    .sort((a: any, b: any) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
                );
            } else {
                setPotentialSetters([]);
            }

            setIsLoadingUsers(false);
        }
    };

    const generatePotentialSetterComponents = () => {
        if (potentialSetters && !isLoadingUsers) {
            const unaddedPotentialSetters: any[] = potentialSetters.filter((potentialSetter: any) =>
                !climbSettingData?.setters[potentialSetter.id] && // Not already a setter and
                !selectedUsers.get(potentialSetter.id) // Not selected to be added as a setter
            );
            let matchedPotentialSetterComponents = unaddedPotentialSetters.map((potentialSetter: any) => 
                <ListGroup.Item style={{cursor: 'pointer'}} key={potentialSetter?.id} onClick={() => handleSelectUser(potentialSetter)}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <BiPlus size={18}/>
                        <div style={{fontSize: 18, marginLeft: 5}}>
                            {potentialSetter.name}
                        </div>
                    </div>
                </ListGroup.Item>
            );

            return matchedPotentialSetterComponents.length > 0 ? (
                <ListGroup style={{marginTop: 10, overflowY: 'auto'}}>
                    {matchedPotentialSetterComponents}
                </ListGroup>
            ) : potentialSetters.length > 0 ? (
                <div>
                    <p style={{fontSize: 18, margin: 15}}>No available users</p>
                </div>
            ) : (
                <div>
                    <p style={{fontSize: 18, margin: 15}}>No users found for this search</p>
                </div>
            );
        } else {
            return <Spinner animation="border" style={{margin: 15}} />
        }
    };

    const generateSelectedUserComponents = () => {
        const selectedUserComponents = Array.from(selectedUsers).map((selectedUserInfo: any) => {
            const selectedUser = selectedUserInfo[1]
            return(
                <ListGroup.Item style={{cursor: 'pointer'}} key={selectedUser.id} onClick={() => handleRemoveSelectedUser(selectedUser)}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start'}}>
                        <BiX size={18}/>
                        <div style={{fontSize: 18, marginLeft: 5}}>
                            {selectedUser.name}
                        </div>
                    </div>
                </ListGroup.Item>
            )
        });

        return(
            <ListGroup style={{overflowY: 'auto', borderRadius: 5, border: '1px solid grey', padding: 10, flex: 1}}>
                {selectedUserComponents}    
            </ListGroup>
        )
    };

    return (
        <Modal style={{zIndex: 9999}} animation={true} size='xl' show={true} onHide={handleCloseModal} >
            <Modal.Header closeButton>
                <Modal.Title>Add setters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{display: 'flex', flexDirection: 'row', height: '60vh'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flex: 1, marginRight: '1%'}}>
                        <label htmlFor="search" style={{fontWeight: 'bold', fontSize: '16'}}>Search for a user</label>
                        <div style={{display: 'flex', flexDirection:'row', justifyContent: 'space-between', backgroundColor: Colors.OFFWHITE, alignSelf: 'flex-start', padding: '0 10px 0 10px', borderRadius: 30, border: '1px solid grey', width: '100%'}}>
                            <input
                                id='search'
                                name='search'
                                onChange={handleChangeSearch}
                                value={search}
                                placeholder='John Doe'
                                style={{outline: 'none', border: 'none', backgroundColor: 'transparent', flexGrow: 1, padding: 10}}
                            />
                            <button
                                style={{border: 'none', backgroundColor: 'transparent', color: Colors.BLUE}}
                                onClick={handleSubmitSearch}
                            >
                                Search
                            </button>
                        </div>
                        {generatePotentialSetterComponents()}
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flex: 1, marginLeft: '1%', justifyContent: 'space-between'}}>
                        <h2 style={{fontWeight: 'bold', fontSize: 16}}>Selected users</h2>
                        {selectedUsers.size > 0 ? (
                            generateSelectedUserComponents()
                        ) : (
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1, borderRadius: 30, border: '1px solid grey', padding:10}}>
                                Please select some new setters from the list to the left
                            </div>
                        )}
                        <button
                            disabled={isLoadingAddSetters}
                            style={{backgroundColor: Colors.OFFWHITE, color: Colors.BLUE, borderRadius: 30, padding: '10px 25px 10px 25px', border: '1px solid grey', marginTop: 10, alignSelf: 'flex-start'}}
                            onClick={handleAddSelectedUsers}
                        >
                            Confirm new setter selection
                        </button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='light' onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
    }
}

export default connect(mapStateToProps)(SetterSelectionModal);
