import React, { useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { BiUserPlus } from 'react-icons/bi';
import { connect } from 'react-redux';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { capitalizeWords } from '../../utils/capitalizeWords';
import { IGym } from '../../interfaces';

const gymRef = db.collection('gyms');
const usersRef = db.collection('users');

const PeopleSearchModal = ({
    role,
    gym,
    handleCloseModal,
    handleAddAdminToList,
} : {
    role: string;
    gym: IGym;
    handleCloseModal: () => void;
    handleAddAdminToList: (admin: any) => void;
}) => {
    const [users, setUsers] = useState<any>(undefined);
    const [selectedUser, setSelectedUser] = useState<any>(undefined);
    const [search, setSearch] = useState<string>('');
    const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);

    const handleClearSelectedUser = () => setSelectedUser(undefined);
    const handleAddSelectedUser = async () => {
        if (selectedUser) {
            await gymRef
                .doc(gym.id)
                .collection('admin')
                .doc(selectedUser.id)
                .set({
                    role: role,
                });
            
            handleAddAdminToList({
                id: selectedUser.id,
                role: role,
                userName: selectedUser.data().name,
                userImageUri: selectedUser.data().profilePictureUri,
            })
            handleCloseModal();
        }
    }

    const handleChangeSearch = (e: any) => setSearch(e.target.value);
    const handleSubmitSearch = async () => {
        setIsLoadingUsers(true);
        if (search.trim().length < 1) {
            return;
        }

        const searchPhrase = capitalizeWords(search.trim());
        const userDocs = await usersRef
            .where('name', '>=', searchPhrase)
            .where('name', '<=', searchPhrase + '\uf8ff')
            .limit(8)
            .get()

        setUsers(userDocs.docs)
        setIsLoadingUsers(false);
    }

    return (
        <Modal style={{zIndex: 9999}} animation={true} size='xl' show={true} onHide={handleCloseModal} >
            <Modal.Header closeButton>
                <Modal.Title>Add an {role}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{display: 'flex', flexDirection: 'column' }}
                >
                    <label htmlFor="search">Search for a user</label>
                    <div style={{backgroundColor: Colors.OFFWHITE, alignSelf: 'flex-start', borderRadius: 30, padding: 10, border: '1px solid grey'}}>
                        <input
                            id='search'
                            name='search'
                            onChange={handleChangeSearch}
                            value={search}
                            placeholder='John Doe'
                            style={{border: 'none', width: '30vw', backgroundColor: 'transparent'}}
                        />
                        <button
                            style={{border: 'none', backgroundColor: 'transparent', color: Colors.BLUE}}
                            onClick={handleSubmitSearch}
                        >
                            Search
                        </button>
                    </div>
                    {isLoadingUsers ? (
                        <Spinner animation="border" />
                    ) : !users ? (
                        <div />
                    ) : users.length === 0 ? (
                        <p>No users match this search!</p>
                    ) : (
                        <ul className="list-group list-group-flush">
                            {users.map((user: any) => {
                                const handleSelectUser = () => setSelectedUser(user);

                                return (
                                    <li key={user.id} style={{ padding: 10 }} className="list-group-item">
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                                <div style={{borderRadius: 100, overflow: 'hidden'}}>
                                                    <img alt='user-profile' height={75} src={user.data().profilePictureUri} />
                                                </div>
                                                <p style={{ margin: 0, marginLeft: 15}}>{user.data().name}</p>
                                            </div>
                                            <button onClick={handleSelectUser} style={{backgroundColor: 'transparent', border: 'none'}}>
                                                <BiUserPlus size={24} />
                                            </button>
                                        </div>
                                        {selectedUser?.id === user.id && (
                                            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
                                                <p style={{margin: 0}}>
                                                    Are you sure you want to add <strong>{selectedUser.data().name}</strong> as an {role}
                                                </p>
                                                <Button style={{marginLeft: 20, marginRight: 10}} variant='danger' onClick={handleClearSelectedUser}>
                                                    Cancel
                                                </Button>
                                                <Button variant='light' onClick={handleAddSelectedUser}>
                                                    Make {role}
                                                </Button>
                                            </div>
                                        )}
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant='light' onClick={handleCloseModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
    }
}

export default connect(mapStateToProps)(PeopleSearchModal);
