import * as Yup from 'yup';

export default Yup.object().shape({
    startDate: Yup.date()
        .required('Required'),
    endDate: Yup.date()
        .required('Required'),
    name: Yup.string()
        .required('Required'),
    availableSpots: Yup.number()
        .required('Required'),
    registrationLink: Yup.string()
        .matches(
            /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            'Enter correct url! (must start with https:// or http://)'
        ),
    description: Yup.string(),
});
