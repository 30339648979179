import React from 'react';

import BarChart from '../../organisms/charts/BarChart';
import ColorChart from '../../organisms/charts/ColorChart';
import { Colors } from '../../../constants';
import { ClimbTypes, IClimbSettingData } from '../../../interfaces';
import AreaSelector from '../gymDisplay/AreasSelector';

import './GymPage.css';

const GymPage = ({
    climbType,
    allAreaAnalytics,
    areas,
    climbSettingData,
    setClimbType,
    setSelectedArea,
} : {
    climbType: ClimbTypes;
    allAreaAnalytics: any;
    areas: any;
    climbSettingData: IClimbSettingData;
    setClimbType: (climbType: ClimbTypes) => void;
    setSelectedArea: (area: any) => void;
}) => {
    const handleSetBoulders = () => setClimbType(ClimbTypes.Boulder);
    const handleSetRoutes = () => setClimbType(ClimbTypes.Route);

    const evaluatedAreas = areas.filter((area: any) => [ClimbTypes.Mixed, climbType].includes(area.data().climbType));

    const labels: string[] = [];
    const gradeScale = climbType === ClimbTypes.Boulder ? climbSettingData.boulderGradeScale : climbSettingData.routeGradeScale;
    for (const gradeOption of gradeScale) {
        if (labels.findIndex(label => label === gradeOption.group) === -1) {
            labels.push(gradeOption.group);
        }
    }

    let totalClimbs = 0;
    const gymGradeDistribution: {[key: string]: number} = {};
    const gymColorDistribution: {[key: string]: number} = {};
    if (allAreaAnalytics) {
        for (const areaAnalyticsDoc of allAreaAnalytics) {
            const areaAnalyticsData = areaAnalyticsDoc.data();
            const colorDistribution = climbType === ClimbTypes.Boulder ?
                areaAnalyticsData.boulderColorDistribution || {} :
                areaAnalyticsData.routeColorDistribution || {};
            const gradeDistribution = climbType === ClimbTypes.Boulder ?
                areaAnalyticsData.boulderGradeDistribution || {}:
                areaAnalyticsData.routeGradeDistribution || {};

            for (const color of Object.keys(colorDistribution)) {
                if (colorDistribution[color] !== 0) {
                    if (gymColorDistribution[color]) {
                        gymColorDistribution[color] += colorDistribution[color];
                    } else {
                        gymColorDistribution[color] = colorDistribution[color];
                    }
                }
            }
            for (const gradeKey of Object.keys(gradeDistribution)) {
                const gradeGroup = gradeScale.find(option => option.key === gradeKey)?.group;
                if (gradeGroup) {
                    if (gymGradeDistribution[gradeGroup]) {
                        gymGradeDistribution[gradeGroup] += gradeDistribution[gradeKey];
                    } else {
                        gymGradeDistribution[gradeGroup] = gradeDistribution[gradeKey];
                    }
                }
                totalClimbs += gradeDistribution[gradeKey];
            }
        }
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center', width: '100%'}}>
            <p style={{letterSpacing: 1, marginTop: 20, fontWeight: 'bolder', fontSize: 28, marginBottom: 0}}>
                Currently Set - All Gym
            </p>
            <div style={{margin: 20}}>
                <button
                    onClick={handleSetBoulders}
                    style={{border: 'none', backgroundColor: climbType === ClimbTypes.Boulder ? Colors.MUSTARD : Colors.LTLTGREY, padding: 3, borderRadius: 4, width: 175, marginRight: '1.5vw'}}
                >
                    <p style={{margin: 0, fontWeight: 'bold', fontSize: 18, letterSpacing: 1}}>
                        Boulders
                    </p>
                </button>
                <button
                    onClick={handleSetRoutes}
                    style={{border: 'none', backgroundColor: climbType === ClimbTypes.Route ? Colors.MUSTARD : Colors.LTLTGREY, padding: 3, borderRadius: 4, width: 175, marginLeft: '1.5vw'}}
                >
                    <p style={{margin: 0, fontWeight: 'bold', fontSize: 18, letterSpacing: 1}}>
                        Routes
                    </p>
                </button>
            </div>
            <p style={{fontSize: 24, letterSpacing: 1}}>
                Total {climbType === ClimbTypes.Boulder ? 'Boulders' : 'Routes'} Set = {totalClimbs}
            </p>
            <div className='gymChartContainer'>
                <BarChart
                    data={gymGradeDistribution}
                    labels={labels}
                />
                <ColorChart
                    data={gymColorDistribution}
                />
            </div>

            <AreaSelector
                areas={evaluatedAreas}
                allAreaAnalytics={allAreaAnalytics}
                climbType={climbType}
                handleSelectArea={setSelectedArea}
            />
        </div>
    );
}

export default GymPage;
