import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { BiTrash, BiPencil, BiMessageAdd } from 'react-icons/bi';
import { connect } from 'react-redux';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { IGym } from '../../interfaces';

const gymRef = db.collection('gyms');

const ManageNewsPage = ({
  gym,
} : {
  gym: IGym;
}) => {
  const [flaggedNews, setFlaggedNews] = useState<any>(undefined);
  const [news, setNews] = useState<any>(undefined);
  const [refresh, setRefresh] = useState<number>(0);

  useEffect(() => {
    if (gym?.id) {
      gymRef
        .doc(gym.id)
        .collection('news')
        .where('deletedAt', '==', null)
        .orderBy('postDate', 'desc')
        .get()
        .then(newsDocs => newsDocs && setNews(
          newsDocs.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
        ))
    }
  }, [gym?.id, refresh]);

  const handleDeleteNews = () => {
    if (flaggedNews && gym?.id) {
      gymRef
        .doc(gym.id)
        .collection('news')
        .doc(flaggedNews.id)
        .update({
          deletedAt: new Date(),
        });
      
      setRefresh(refresh + 1);
      setFlaggedNews(undefined)
    }
  }

  const handleClearFlaggedNews = () => setFlaggedNews(undefined);

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1, padding: 20}}>
      <h4>News:</h4>

      {flaggedNews && 
        <div style={{position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, zIndex: 9999, backgroundColor: Colors.MODALBACKGROUND}}>
          <div style={{marginTop: 25, width: '30%', minWidth: '350px', backgroundColor: Colors.WHITE, marginLeft: 'auto', marginRight: 'auto', borderRadius: 5}}>
            <div style={{padding: '10px 20px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
              <p style={{fontSize: 24}}>Confirm Deletion</p>
              <button onClick={handleClearFlaggedNews} style={{border: 'none', backgroundColor: 'white'}}>
                <p style={{fontSize: 18, fontWeight: 'bold'}}>&#x2715;</p>
              </button>
            </div>
            <div style={{padding: '0px 20px'}}>
              <p>Are you sure you want to delete this news?</p>
            </div>
            <div style={{padding: '10px 10px', display: 'flex', flexDirection: 'row-reverse'}}>
              <Button variant='danger' onClick={handleDeleteNews}>
                Delete
              </Button>
              <Button variant='light' style={{marginRight: 15}} onClick={handleClearFlaggedNews}>
                Close
              </Button>
            </div>
          </div>
        </div>
      }

      <Link to='news/create'>
        <button
          style={{position: 'fixed', bottom: 20, right: 20, borderRadius: 100, height: 70, width: 70, backgroundColor: Colors.MUSTARD, border: 'none', zIndex: 1}}
        >
          <BiMessageAdd size={40} style={{marginTop: 5}} color={Colors.WHITE} />
        </button>
      </Link>

      {!news ? (
        <Spinner animation="border" />
      ) :
      news.length === 0 ? (
        <p>Let your members know what's going on!</p>
      ) : (
        <ul className="list-group list-group-flush" style={{paddingBottom: '100px'}}>
          {news
          .sort((a: any, b: any) => {
            const aStart = a.postDate.seconds === undefined ? a.postDate : a.postDate.toDate();
            const bStart = b.postDate.seconds === undefined ? b.postDate : b.postDate.toDate();
            return bStart.getTime() - aStart.getTime();
          })
          .map((gymNews: any) => {
            const newsData = gymNews;
            if (gymNews.postDate.seconds !== undefined) {
              newsData.postDate = newsData.postDate.toDate();
            }
            const newsLink = '/news/' + gymNews.id;
            const flagNews = () => setFlaggedNews(gymNews);

            return (
              <li key={gymNews.id} style={{ padding: 0 }} className="list-group-item">
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                  <div style={{ padding: 10}}>
                    <p style={{margin: 0}}>{moment(newsData.postDate).format('MMM D, YYYY')}</p>
                    <p style={{margin: 0}}>{newsData.message}</p>
                  </div>
                  <div>
                    <Link to={newsLink} style={{textDecorationLine: 'none', color: 'black', marginRight: 10}}>
                      <BiPencil size={24} />
                    </Link>
                    <button onClick={flagNews} style={{backgroundColor: 'transparent', border: 'none'}}>
                      <BiTrash size={24} />
                    </button>
                  </div>
                </div>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  );
}

const mapStateToProps = function(state: any) {
  return {
    gym: state.gym,
  }
}

export default connect(mapStateToProps)(ManageNewsPage);
