import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { IArea } from '../../interfaces';

const areasRef = db.collection('areas');

const LeagueAbout = ({
  league,
} : {
  league: any | undefined;
}) => {
  const [areas, setAreas] = useState<IArea[] | undefined>(undefined);

  useEffect(() => {
    if (league.applicableWalls !== true) {
      areasRef
        .where('gymId', '==', league.gymId)
        .get()
        .then(areaDocs => areaDocs && setAreas(
          areaDocs.docs.map(areaDoc => ({id: areaDoc.id, ...areaDoc.data()} as IArea))
        ));
    }
  }, [league.gymId, league.applicableWalls])

  const scoringKeys = [
    {key: 'threeBelow', label: '-3 Grades'},
    {key: 'twoBelow', label: '-2 Grades'},
    {key: 'oneBelow', label: '-1 Grade'},
    {key: 'atMax', label: 'V-Max Grade'},
    {key: 'oneAbove', label: '+1 Grade'},
    {key: 'twoAbove', label: '+2 Grades'},
    {key: 'threeAbove', label: '+3 Grades'},
  ];
  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  return (
    <div style={{display: 'flex', flexDirection: 'column', paddingBottom: 100}}>
      <p style={{fontSize: 20, fontWeight: 'bold', marginBottom: 0}}>
        Rules and Format
      </p>

      <p style={{margin: 0, marginRight: 10, fontSize: 20}}>
        Max # of Teams: <b>{league.maxTeams}</b>
      </p>
      <p style={{margin: 0, marginRight: 10, fontSize: 20}}>
        Max # of members per team: <b>{league.maxMembersPerTeam}</b>
      </p>

      <div style={{marginTop: 20}}>
        <p style={{marginBottom: 0, fontSize: 20}}>
          Climbing Types:
        </p>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <input
            type='checkbox'
            readOnly
            checked={league.climbingTypes.bouldering}
            style={{height: 20, width: 20}}
          />
          <p style={{marginBottom: 0, marginLeft: 10}}>Bouldering</p>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <input
            type='checkbox'
            readOnly
            checked={league.climbingTypes.ropes}
            style={{height: 20, width: 20}}
          />
          <p style={{marginBottom: 0, marginLeft: 10}}>Ropes</p>
        </div>
      </div>

      <div style={{marginTop: 20}}>
        <p style={{marginBottom: 0, fontSize: 20}}>
          Styles Allowed:
        </p>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <input
            type='checkbox'
            readOnly
            checked={league.allowedStyles.flash}
            style={{height: 20, width: 20}}
          />
          <p style={{marginBottom: 0, marginLeft: 10}}>Flash</p>
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <input
            type='checkbox'
            readOnly
            checked={league.allowedStyles.redpoint}
            style={{height: 20, width: 20}}
          />
          <p style={{marginBottom: 0, marginLeft: 10}}>Redpoint</p>
        </div>
      </div>

      <div style={{marginTop: 20}}>
        <p style={{marginBottom: 0, fontSize: 20}}>
          Climbing Days:
        </p>
        {daysOfWeek.map(day => 
          <div key={day} style={{display: 'flex', alignItems: 'center'}}>
            <input
              type='checkbox'
              readOnly
              checked={league.climbingDays[day.toLowerCase()]}
              style={{height: 20, width: 20}}
            />
            <p style={{marginBottom: 0, marginLeft: 10}}>{day}</p>
          </div>
        )}
      </div>

      <p style={{fontSize: 20, fontWeight: 'bold', marginBottom: 5, marginTop: 25}}>
        Scoring and Handicapping
      </p>
      <p style={{marginBottom: 0, fontSize: 20}}>
        Base V-Max Grade on:
      </p>
      <p style={{margin: 0}}>{league.vMaxDescription}</p>

      <div style={{display: 'flex', justifyContent: 'space-evenly', marginTop: 20}}>
        {scoringKeys.map(scoringKey => 
          <div key={scoringKey.key} style={{border: '1px solid grey', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '10vw'}}>
            <p style={{marginTop: 10, marginBottom: 0}}>{scoringKey.label}</p>
            <p style={{textAlign: 'center', border: 'none', borderBottom: '1px solid grey', margin: 10, width: '80%'}}>
              {league.scoring[scoringKey.key]}
            </p>
            <p style={{marginBottom: 10}}>Points</p>
          </div>
        )}
      </div>

      <p style={{fontSize: 20, fontWeight: 'bold', marginBottom: 10, marginTop: 25}}>
        Applicable Gym Areas
      </p>
      {league.applicableWalls === true ? 
        <p style={{marginBottom: 0}}>Entire gym is counted in league scoring</p>
        :
        <div>
          <p style={{marginBottom: 0, marginLeft: 10}}>The selected walls are counted for league scoring</p>
          <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}}>
            {!areas ?
              <Spinner animation='border' />
              :
              areas.map(area =>
                  <svg key={area.id} height={300} width={300 * 576/427} viewBox="0 0 576 427" style={{border: '1px solid grey', borderRadius: 10, margin: 5}}>
                      {area.walls.map(wall => 
                        <g
                          key={wall.id}
                          fill={area.color}
                          stroke={Colors.BLACK}
                        >
                          {wall.paths.map((path, i) =>
                            <path
                              key={i}
                              d={path.path}
                              strokeDasharray={path.strokeDash}
                              fill={path.color || area.color}
                              opacity={league.applicableWalls[wall.id] ? 1 : 0.5}
                              style={{cursor: 'pointer'}}
                            />
                          )}
                          {wall.label.map((label: any, i: number) => 
                            <text key={i} fill='black' transform={label.transform} fontSize={12} x={label.x} y={label.y}>
                              {label.text}
                            </text>
                          )}
                        </g>
                      )}
                      {area.markers.map(marker => 
                        <g
                          key={marker.id}
                          fill={marker.color}
                          stroke={Colors.BLACK}
                          strokeWidth={1.5}
                        >
                          <path id={marker.id} d={marker.path} />
                          {marker.label.map((label: any, i: number) => 
                            <text key={i} transform={label.transform} fontFamily='Montserrat-ExtraBold' fontSize={14} x={label.x} y={label.y}>
                              {label.text}
                            </text>
                          )}
                        </g>
                      )}
                  </svg>
                )
              }
          </div>
        </div>
      }
    </div>
  );
};

export default LeagueAbout;
