import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { BiTrash, BiListPlus } from 'react-icons/bi';
import { connect } from 'react-redux';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import PeopleSearchModal from './PeopleSearchModal';
import { IGym, IUser } from '../../interfaces';

const gymRef = db.collection('gyms');
const usersRef = db.collection('users');

const ManageAdminsPage = ({
    gym,
    user,
  } : {
    gym: IGym;
    user: IUser;
  }) => {
    const [admins, setAdmins] = useState<any>(undefined);
    const [flaggedAdmin, setFlaggedAdmin] = useState<any>(undefined);
    const [isAddAdminOpen, setIsAddAdminOpen] = useState<any>(undefined);

    useEffect(() => {
        if (gym?.id) {
            gymRef
                .doc(gym.id)
                .collection('admin')
                .where('role', 'in', ['admin', 'owner'])
                .get()
                .then(async adminDocs => {
                    if (adminDocs && !adminDocs.empty) {
                        const allAdminData: any[] = [];
                        for (const adminDoc of adminDocs.docs) {
                            const userDoc = await usersRef
                                .doc(adminDoc.id)
                                .get()
                
                            if (userDoc && userDoc.exists) {
                                const adminData = {...adminDoc.data()};
                                adminData.id = adminDoc.id;
                                adminData.userImageUri = userDoc.data()?.profilePictureUri;
                                adminData.userName = userDoc.data()?.name;
                                allAdminData.push(adminData);
                            }
                        };
                        setAdmins(allAdminData);
                    } else {
                        setAdmins([]);
                    }
                })
        }
    }, [gym?.id]);

    const handleToggleAddAdminOpen = () => setIsAddAdminOpen(!isAddAdminOpen);

    const handleClearFlaggedAdmin = () => setFlaggedAdmin(undefined);
    const handleDeleteFlaggedAdmin = () => {
        if (flaggedAdmin) {
            gymRef
                .doc(gym.id)
                .collection('admin')
                .doc(flaggedAdmin.id)
                .delete();

            setAdmins(admins.filter((admin: any) => admin.id !== flaggedAdmin.id));
            setFlaggedAdmin(undefined);
        }
    }

    const handleAddAdminToList = (newAdmin: any) => setAdmins([...admins, newAdmin]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, padding: 20}}>
            <h4 style={{marginBottom: 5}}>Manage Admins:</h4>
            <p style={{marginBottom: 10}}>Admins have access to this gym admin website and the setting capability in the mobile app. Admins will not be listed as a setter unless explicitly added as a setter as well.</p>

            {flaggedAdmin && 
                <Modal animation={false} show={true} onHide={handleClearFlaggedAdmin}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to remove {flaggedAdmin.userName} from the list of admins</Modal.Body>
                    <Modal.Footer>
                        <Button variant='light' onClick={handleClearFlaggedAdmin}>
                            Close
                        </Button>
                        <Button variant='danger' onClick={handleDeleteFlaggedAdmin}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            <button
                onClick={handleToggleAddAdminOpen}
                style={{position: 'fixed', bottom: 20, right: 20, borderRadius: 100, padding: 13, backgroundColor: Colors.MUSTARD, border: 'none'}}
            >
                <BiListPlus size={40} color={Colors.WHITE} />
            </button>
            {isAddAdminOpen && 
                <PeopleSearchModal
                    role='admin'
                    handleCloseModal={handleToggleAddAdminOpen}
                    handleAddAdminToList={handleAddAdminToList}
                />
            }

            {!admins ? (
                <Spinner animation="border" />
            ) : admins.length === 0 ? (
                <p>No admins at this time</p>
            ) : (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {admins.map((admin: any) => {
                        const handleRemoveAdmin = () => setFlaggedAdmin(admin);

                        return (
                            <div
                                key={admin.id}
                                style={{
                                    position: 'relative',
                                    marginRight: 15,
                                    marginBottom: 15,
                                    padding: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: '1px solid grey',
                                    borderRadius: 5,
                                    minWidth: 250,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {(user?.id !== admin.id && admin.role !== 'owner') && (
                                    <button
                                        onClick={handleRemoveAdmin}
                                        style={{position: 'absolute', bottom: -5, right: -5, backgroundColor: 'white', borderRadius: 20, padding: 3, border: '1px solid grey'}}
                                    >
                                        <BiTrash size={24} />
                                    </button>
                                )}
                                <img alt='user-profile' src={admin.userImageUri} height={200} />
                                <p style={{margin: 10, fontWeight: 'bold'}}>{admin.userName}</p>
                                <p style={{margin: 0}}>Role: {admin.role}</p>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
        user: state.user,
    }
}

export default connect(mapStateToProps)(ManageAdminsPage);
