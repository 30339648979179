import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useParams, Link, Switch, Route } from 'react-router-dom';
import moment from 'moment';

import db from '../../firebase.config';
import LeagueLeaderboard from './LeagueLeaderboard';
import LeagueTeams from './LeagueTeams';
import LeagueParticipants from './LeagueParticipants';
import LeagueAbout from './LeagueAbout';
import { Colors } from '../../constants';
import { BiLeftArrowAlt } from 'react-icons/bi';

import './LeaguePage.css';

const leaguesRef = db.collection('leagues');

const LeaguePage = () => {
    let { id: leagueId } = useParams<{id: string}>();
    const [league, setLeague] = useState<any>(undefined);

    const currentPathArray = window.location.pathname.split('/');
    const currentPath = currentPathArray[currentPathArray.length - 1];

    useEffect(() => {
        if (leagueId === 'create') {
            setLeague({
                imageUri: null,
                name: '',
                description: '',
                startDate: new Date(),
                endDate: new Date(),
                registrationStartDate: new Date(),
                registartionEndDate: new Date(),
                maxTeams: 0,
                maxMembersPerTeam: 4,
                climbingTypes: [],
                allowedStyles: [],
                climbingDays: [],
                vMaxDescription: '',
            });
            return;
        } else {
            leaguesRef
                .doc(leagueId)
                .get()
                .then(leagueDoc => {
                    if (leagueDoc && leagueDoc.exists) {
                        setLeague(leagueDoc.data())
                    } else {
                        setLeague(null);
                    }
                });
        }
    }, [leagueId]);

    if (league === undefined) {
        return <Spinner animation="border" style={{margin: 20}} />
    } else if (league === null) {
        return <p>Error! Please go back and try again!</p>
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100vh', margin: 20}}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Link
                        to='/leagues'
                        style={{display: 'flex', borderRadius: 50, alignSelf: 'flex-start', padding: 10, marginTop: 10, marginBottom: 15, backgroundColor: Colors.OFFWHITE, border: 'none'}}
                    >
                        <BiLeftArrowAlt size={24} color={Colors.DRKGREY} />
                    </Link>

                    <h4 style={{marginLeft: 20}}>
                        {league.name}
                    </h4>
                </div>

                <Link
                    to={`/leagues/edit/${leagueId}`}
                    style={{ backgroundColor: Colors.MUSTARD, display: 'flex', alignItems: 'center', border: '1px solid grey', borderRadius: 50, padding: '0px 20px 0px 20px', marginRight: 10, marginTop: 2, textDecorationLine: 'none'}}
                >
                    <p style={{margin: 0, color: 'black', fontSize: 22, lineHeight: 1.1}}>
                        &#9998;
                    </p>
                    <p style={{margin: 0, color: 'black', fontSize: 14, marginLeft: 8}}>
                        Edit League
                    </p>
                </Link>
            </div>

            <div style={{display: 'flex', flexDirection: 'row'}}>
                <img alt='league-profile' src={league.imageUri} height={150} width={150} style={{marginRight: 20, border: '1px solid grey', borderRadius: 5}} />
                <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                    <div style={{display: 'flex'}}>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                            <p style={{margin: 0, fontWeight: 'bold'}}>League Dates: </p>
                            <div style={{alignSelf: 'flex-start'}}>
                                <p style={{margin: 0}}>{moment(league.startDate.toDate()).format('LLL')}</p>
                                <p style={{margin: 0, fontSize: 12, textAlign: 'center', lineHeight: '10px'}}>-</p>
                                <p>{moment(league.endDate.toDate()).format('LLL')}</p>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                            <p style={{margin: 0, fontWeight: 'bold'}}>Registration Dates:</p>
                            <div style={{alignSelf: 'flex-start'}}>
                                <p style={{margin: 0}}>{moment(league.registrationStartDate.toDate()).format('LLL')}</p>
                                <p style={{margin: 0, fontSize: 12, textAlign: 'center', lineHeight: '10px'}}>-</p>
                                <p>{moment(league.registrationEndDate.toDate()).format('LLL')}</p>
                            </div>
                        </div>
                    </div>
                    {league.description && (
                        <>
                            <h6 style={{marginBottom: 0, fontWeight: 'bold'}}>Description:</h6>
                            <p>{league.description}</p>
                        </>
                    )}
                </div>
            </div>

            <div style={{display: 'flex', marginTop: 10, borderBottom: '3px solid #f2d394'}}>
                <Link to={`/leagues/${leagueId}/leaderboard`} className={currentPath === 'leaderboard' ? 'leaguePageLink leaguePageLinkSelected' : 'leaguePageLink'}>
                    Leaderboard
                </Link>
                <Link to={`/leagues/${leagueId}/teams`} className={currentPath === 'teams' ? 'leaguePageLink leaguePageLinkSelected' : 'leaguePageLink'}>
                    Teams
                </Link>
                <Link to={`/leagues/${leagueId}/participants`} className={currentPath === 'participants' ? 'leaguePageLink leaguePageLinkSelected' : 'leaguePageLink'}>
                    Participants
                </Link>
                <Link to={`/leagues/${leagueId}/about`} className={currentPath === 'about' ? 'leaguePageLink leaguePageLinkSelected' : 'leaguePageLink'}>
                    About
                </Link>
            </div>

            <div style={{padding: 20}}>
                <Switch>
                    <Route
                        exact
                        path='/leagues/:id/leaderboard'
                        children={<LeagueLeaderboard leagueId={leagueId} />}
                    />
                    <Route
                        exact
                        path='/leagues/:id/teams'
                        children={<LeagueTeams league={league} />}
                    />
                    <Route
                        exact
                        path='/leagues/:id/participants'
                        children={<LeagueParticipants league={league} />}
                    />
                    <Route
                        exact
                        path='/leagues/:id/about'
                        children={<LeagueAbout league={league} />}
                    />
                </Switch>
            </div>
        </div>
    );
}

export default LeaguePage;
