import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Dashboard from './dashboard/Dashboard';
import LoginPage from './login/LoginPage';
import AuthProvider from '../contexts/AuthContext';
import PrivateRoute from './login/PrivateRoute';
import store, { persistor } from '../redux/store';

const App = () => {

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100vh'}}>
          <Router>
            <AuthProvider>
              <Switch>
                <Route
                  exact
                  path='/login'
                  component={LoginPage}
                />
                <PrivateRoute
                  path='/'
                  component={Dashboard}
                />
              </Switch>
            </AuthProvider>
          </Router>
        </div>
      </PersistGate>
    </ReduxProvider>
  );
}

export default App;
