import { createStore } from "redux";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage';
import { IReduxState } from '../interfaces/IReduxState';

const LOGIN_USER = "LOGIN_USER";
const LOGOUT_USER = "LOGOUT_USER";
const SET_GYM = "SET_GYM";
const SET_ADMIN_GYMS = "SET_ADMIN_GYMS";

const initialState: IReduxState = {
    user: {},
    gym: {},
    adminGyms: [],
};

function rootReducer(state = initialState, action: any) {
    switch(action.type) {
        case LOGIN_USER:
            return Object.assign({}, state, action.payload);

        case SET_GYM:
            return Object.assign({}, state, action.payload);

        case SET_ADMIN_GYMS:
            return Object.assign({}, state, action.payload);

        case LOGOUT_USER:
            return Object.assign({}, state, {user: {}, gym: {}, adminGyms: []});

        default:
            return state;
    };
};

const persistConfig = {
    key: 'root',
    storage: storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

let store = createStore(persistedReducer)
export const persistor = persistStore(store)

export default store;
