import React, { useState, useEffect } from 'react';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { Spinner } from 'react-bootstrap';

const leaguesRef = db.collection('leagues');

const LeagueLeaderboard = ({
  leagueId,
} : {
  leagueId: string;
}) => {
  const [teams, setTeams] = useState<any>(undefined);

  useEffect(() => {
    leaguesRef
      .doc(leagueId)
      .collection('leagueTeams')
      .orderBy('cumulativeScore', 'desc')
      .limit(50)
      .get()
      .then(teamDocs => teamDocs && setTeams(teamDocs.docs));
  }, [leagueId]);

  if (teams === undefined) {
    return (
      <div>
        <Spinner animation='border' color={Colors.MUSTARD} />
      </div>
    )
  } else if (teams.length === 0) {
    return (
      <div>
        <p style={{fontSize: 24}}>
          No teams yet
        </p>
      </div>
    )
  }

  const positionSuffix = ['st', 'nd', 'rd', 'th'];

  return (
    <div>
      {teams.map((team: any, i: number) => {
        const teamData = team.data();

        return (
          <div key={team.id} style={{display: 'flex', alignItems: 'center', marginBottom: 15}}>
            <p style={{fontSize: 24, fontWeight: 'bold', marginBottom: 0, minWidth: 75}}>
              {(i+1) + (i < 4 ? positionSuffix[i] : positionSuffix[3])}
            </p>

            <img src={teamData.imageUri} alt='admin profile' style={{height: 60, width: 60, borderRadius: 60, border: '1px solid #d1d1d1'}} />

            <p style={{flex: 1, marginBottom: 0, marginLeft: 10, fontSize: 18, fontWeight: 'bold',}}>
              {teamData.name}
            </p>

            <p style={{marginBottom: 0, marginLeft: 10, fontSize: 20}}>
              {teamData.cumulativeScore} Pts.
            </p>
          </div>
        )
      })}
    </div>
  );
};

export default LeagueLeaderboard;
