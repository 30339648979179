import React from 'react';
import { ClimbTypes } from '../../../interfaces';

import AreaCard from './AreaCard';

const AreaSelector = ({
    areas,
    allAreaAnalytics,
    climbType,
    handleSelectArea,
}: {
    areas: any[],
    allAreaAnalytics: any;
    climbType: ClimbTypes;
    handleSelectArea: (area: any) => void
}) => {
    return (
        <div style={{margin: 20, width: '100%', display: 'flex', justifyContent: 'center'}}>
            <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center', paddingLeft: 20, paddingRight: 20}}>
                {areas.map((area, index) =>
                    <AreaCard
                        key={index}
                        areaAnalytics={allAreaAnalytics.find((areaAnalyticsDoc: any) => area.id === areaAnalyticsDoc.id)}
                        area={area.data()}
                        climbType={climbType}
                        handleSelectArea={handleSelectArea}
                    />
                )}
            </div>
        </div>
    );
};

export default AreaSelector;
