import React, { useState } from 'react';
import { Formik, Field, FieldArray } from 'formik';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';

import { Colors } from '../../constants';
import { IGym } from '../../interfaces';
import { gymSchema } from '../../validationSchema';
import { uploadImage } from '../../utils/uploadImage';
import db from '../../firebase.config';
import ImageCropSelector from '../organisms/imageEditor/ImageCropSelector';
import { setGym } from '../../redux/actions';

const gymRef = db.collection('gyms');

const GymProfileForm = ({
    gymData,
    handleCloseModal,
    setGymRedux,
} : {
    gymData: IGym;
    handleCloseModal: () => void;
    setGymRedux: (gym: IGym) => void;
}) => {
    const [bannerImageToUpload, setBannerImageToUpload] = useState<any>(undefined);
    const [avatarImageToUpload, setAvatarImageToUpload] = useState<any>(undefined);
    const [saveLoading, setSaveLoading] = useState<boolean>(false);

    const handleSaveGym = async (values: any) => {
        setSaveLoading(true);
        if (gymData.id) {
            const gymId = gymData.id
            delete values.id;

            if (gymData.imageUri !== values.imageUri) {
                if (!bannerImageToUpload) {
                    window.alert('Banner image upload failed');
                    setSaveLoading(false);
                    return;
                }
                const uploadBannerImageUri = await uploadImage(bannerImageToUpload, values.imageUri);
                if (!uploadBannerImageUri) {
                    window.alert('Banner image upload failed');
                    setSaveLoading(false);
                    return;
                }
                values.imageUri = uploadBannerImageUri;
            }

            if (gymData.avatarUri !== values.avatarUri) {
                if (!avatarImageToUpload) {
                    window.alert('Avatar image upload failed');
                    setSaveLoading(false);
                    return;
                }
                const uploadAvatarImageUri = await uploadImage(avatarImageToUpload, values.avatarUri);
                if (!uploadAvatarImageUri) {
                    window.alert('Avatar image upload failed');
                    setSaveLoading(false);
                    return;
                }
                values.avatarUri = uploadAvatarImageUri;
            }

            await gymRef
                .doc(gymId)
                .update(values);
            setGymRedux({id: gymData.id, ...values});
        }
        handleCloseModal();
    }

    return (
        <div style={{ margin: 20, display: 'flex', flexDirection: 'column', flex: 1 }}>
            <h4>{gymData.name}</h4>
            <Formik
                initialValues={gymData}
                validationSchema={gymSchema}
                onSubmit={handleSaveGym}
            >
                {({ values, submitForm, setFieldValue }: { values: IGym, submitForm: () => void, setFieldValue: any }) => {
                    const handleBannerImageChange = (imageBlob: any, blobUrl: string) => {
                        setFieldValue('imageUri', blobUrl);
                        setBannerImageToUpload(imageBlob);
                    }
                    const handleAvatarImageChange = (imageBlob: any, blobUrl: string) => {
                        setFieldValue('avatarUri', blobUrl);
                        setAvatarImageToUpload(imageBlob);
                    }

                    return (
                        <div style={{display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <div style={{display: 'flex', marginBottom: 40}}>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <label style={{marginTop: 20}} htmlFor="primaryPhoneNumber">Phone Number</label>
                                    <Field id="primaryPhoneNumber" name="primaryPhoneNumber" placeholder="+17177999826" />

                                    <label style={{marginTop: 20}} htmlFor="primaryEmailAddress">Email Address</label>
                                    <Field id="primaryEmailAddress" name="primaryEmailAddress" placeholder="joe.smith@gmail.com" />

                                    <div style={{display: 'flex', marginTop: 40}}>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '25vw', minWidth: 300}}>
                                            <div>
                                                <label style={{marginTop: 10, marginRight: 10, width: 100}} htmlFor="address.address">Address: </label>
                                                <Field id="address.address" name="address.address" placeholder="Address" />
                                            </div>

                                            <div>
                                                <label style={{marginTop: 10, marginRight: 10, width: 100}} htmlFor="address.city">City: </label>
                                                <Field id="address.city" name="address.city" placeholder="City" />
                                            </div>

                                            <div>
                                                <label style={{marginTop: 10, marginRight: 10, width: 100}} htmlFor="address.state">State: </label>
                                                <Field id="address.state" name="address.state" placeholder="State" />
                                            </div>

                                            <div>
                                                <label style={{marginTop: 10, marginRight: 10, width: 100}} htmlFor="address.zipcode">Zipcode: </label>
                                                <Field id="address.zipcode" name="address.zipcode" placeholder="Zipcode" />
                                            </div>
                                        </div>

                                        <div>
                                            <label htmlFor="hours">Hours</label>
                                            <FieldArray name="hours">
                                                {({ remove, push }: any) => (
                                                    <div>
                                                        {values.hours.map((label: any, index: number) => (
                                                            <div style={{display: 'flex', flexDirection: 'row', marginBottom: 10}} key={index}>
                                                                <Field
                                                                    style={{marginRight: 10}}
                                                                    name={`hours.${index}`}
                                                                    placeholder="Hour of Operation"
                                                                    type="text"
                                                                />

                                                                    <button
                                                                        style={{border: 'none', backgroundColor: 'white'}}
                                                                        type="button"
                                                                        className="secondary"
                                                                        onClick={() => remove(index)}
                                                                    >
                                                                        &#10005;
                                                                    </button>
                                                            </div>
                                                        ))}
                                                        <button
                                                            type="button"
                                                            style={{backgroundColor: Colors.MUSTARDSHADE, borderColor: Colors.LTGREY, borderRadius: 8, borderWidth: 1}}
                                                            onClick={() => push('')}
                                                        >
                                                            Add Hours
                                                        </button>
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', flex: 1, alignItems: 'center'}}>
                                    <h5>Banner Image:</h5>
                                    <ImageCropSelector
                                        initialImageSrc={values.imageUri}
                                        aspect={16/7}
                                        displayHeight={175}
                                        handleSaveImage={handleBannerImageChange}
                                    />

                                    <h5 style={{marginTop: 40}}>Avatar Image:</h5>
                                    <ImageCropSelector
                                        initialImageSrc={values.avatarUri}
                                        aspect={1}
                                        displayHeight={175}
                                        shape='round'
                                        handleSaveImage={handleAvatarImageChange}
                                    />
                                </div>
                            </div>

                            {saveLoading ? (
                                <div style={{alignSelf: 'center'}}>
                                    <Spinner animation="border" />
                                </div>
                            ) : (
                                <div style={{alignSelf: 'center'}}>
                                    <button
                                        type="button"
                                        style={{alignSelf: 'center', backgroundColor: Colors.OFFWHITE, marginRight: 15, padding: '5px 40px', border: '1px solid grey', borderRadius: 5}}
                                        onClick={handleCloseModal}
                                    >
                                        Close
                                    </button>
                                    <button
                                        type="submit"
                                        style={{alignSelf: 'center', backgroundColor: Colors.MUSTARD, padding: '5px 40px', border: '1px solid grey', borderRadius: 5}}
                                        onClick={submitForm}
                                    >
                                        Save
                                    </button>
                                </div>
                            )}  
                            
                        </div>
                    )
                }}
            </Formik>
        </div>
    );
}

const mapDispatchToProps = function(dispatch: any) {
    return {
        setGymRedux: (gym: IGym) => {
            dispatch(setGym({
                gym: gym,
            }))
        },
    }
}

export default connect(undefined, mapDispatchToProps)(GymProfileForm);
