import React, { useState, useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';

import db from '../../../firebase.config';
import { ClimbTypes, IGym } from '../../../interfaces';
import GymPage from './GymPage';
import AreaPage from './AreaPage';
import WallPage from './WallPage';

const gymMetadataRef = db.collection('gymMetadata');
const gymsRef = db.collection('gyms');
const areasRef = db.collection('areas');

const ReportsPage = ({
    gym,
} : {
    gym: IGym;
}) => {
    const [areas, setAreas] = useState<any>(undefined);
    const [allAreaAnalytics, setAllAreaAnalytics] = useState<any>(undefined);
    const [climbSettingData, setClimbSettingData] = useState<any>(undefined);
    const [climbType, setClimbType] = useState<ClimbTypes>(ClimbTypes.Boulder);

    const [selectedArea, setSelectedArea] = useState<any>(undefined);
    const [selectedWall, setSelectedWall] = useState<any>(undefined);

    const handleClearSelectedArea = () => setSelectedArea(undefined);
    const handleClearSelectedWall = () => setSelectedWall(undefined);

    useEffect(() => {
        if (gym?.id) {
            setSelectedWall(undefined);
            setSelectedArea(undefined);
            setAreas(undefined);
            setAllAreaAnalytics(undefined);
            setClimbSettingData(undefined);       

            gymsRef
                .doc(gym.id)
                .collection('climbSettingData')
                .doc(gym.id)
                .get()
                .then(climbSettingDataDoc => climbSettingDataDoc && setClimbSettingData(climbSettingDataDoc.data()))

            gymMetadataRef
                .doc(gym.id)
                .collection('areaAnalytics')
                .get()
                .then(areaAnalyticDocs => areaAnalyticDocs && setAllAreaAnalytics(areaAnalyticDocs.docs));

            areasRef
                .where('gymId', '==', gym.id)
                .get()
                .then(areaDocs => areaDocs && setAreas(areaDocs.docs.sort((a, b) => a.data().order - b.data().order)));
        }
    }, [gym?.id]);

    return (
        <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative'}}>
            {(!areas || !allAreaAnalytics || !climbSettingData) ? (
                <div>
                    <Spinner animation='border' />
                </div>
            ) : (selectedArea === undefined) ? (
                <GymPage
                    climbType={climbType}
                    areas={areas}
                    allAreaAnalytics={allAreaAnalytics}
                    climbSettingData={climbSettingData}
                    setClimbType={setClimbType}
                    setSelectedArea={setSelectedArea}
                />
            ) : (selectedWall === undefined) ? (
                <AreaPage
                    climbType={climbType}
                    areaAnalytics={allAreaAnalytics.find((areaAnalytics: any) => areaAnalytics.id === selectedArea.id)}
                    selectedArea={selectedArea}
                    climbSettingData={climbSettingData}
                    setSelectedWall={setSelectedWall}
                    handleBack={handleClearSelectedArea}
                />
            ) : (
                <WallPage
                    selectedArea={selectedArea}
                    selectedWall={selectedWall}
                    handleBack={handleClearSelectedWall}
                />
            )}
        </div>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
    }
}

export default connect(mapStateToProps)(ReportsPage);
