import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { connect } from 'react-redux';

import { Colors } from '../../constants';
import GymProfileForm from './GymProfileForm';
import { IGym } from '../../interfaces';

import './ManageGymPage.css';

const ManageGymPage = ({
  gym,
} : {
  gym: IGym;
}) => {
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsEditOpen(false);
  }, [gym])

  if (gym.id === undefined) {
    return <Spinner animation="border" />
  } else if (gym === null) {
    return <p>Error! Please go back and try again!</p>
  }

  const toggleIsEditOpen = () => setIsEditOpen(!isEditOpen);

  if (isEditOpen && gym) {
    return (
      <GymProfileForm
        gymData={gym as IGym}
        handleCloseModal={toggleIsEditOpen}
      />
    );
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1, padding: 20}}>
      <h4 style={{ alignSelf: 'center' }}>{gym.name}</h4>

      <div className='profileImageContainer'>
        <div>
          <h5 style={{textAlign: 'center', marginTop: 15}}>Banner Image</h5>
          <img className='bannerImage' alt='gym-profile' src={gym.imageUri} />
        </div>
        <div>
          <h5 style={{textAlign: 'center', marginTop: 15}}>Avatar Image</h5>
          <img className='avatarImage' alt='gym-profile' src={gym.avatarUri} />
        </div>
      </div>

      <div className='gymInfoContainer'>
        <div className='gymInfoBox'>
          <h6 className='infoTitle'>Hours:</h6>
          {gym.hours.map((hour: string, i: number) => 
            <p className='infoText' key={i}>{hour}</p>
          )}
        </div>

        <div className='gymInfoBox'>
          <h6 className='infoTitle'>Address:</h6>
          <p className='infoText'>{gym.address.address}</p>
          <p className='infoText'>{gym.address.city + ' ' + gym.address.state + ', ' + gym.address.zipcode}</p>
        </div>

        <div className='gymInfoBox'>
          <h6 className='infoTitle'>Email Address:</h6>
          <p className='infoText'>{gym.primaryEmailAddress}</p>
        </div>

        <div className='gymInfoBox'>
          <h6 className='infoTitle'>Phone Number:</h6>
          <p className='infoText'>{gym.primaryPhoneNumber}</p>
        </div>
      </div>
      <button
        onClick={toggleIsEditOpen}
        style={{
          alignSelf: 'center',
          marginTop: 50,
          backgroundColor: Colors.MUSTARD,
          border: 'none',
          color: Colors.WHITE,
          borderRadius: 3,
          fontSize: 18,
          fontWeight: 'bold',
          padding: 5,
          paddingLeft: 10,
          paddingRight: 10,
        }}
      >
        Edit profile
      </button>
    </div>
  );
}

const mapStateToProps = function(state: any) {
  return {
    gym: state.gym,
  }
}

export default connect(mapStateToProps)(ManageGymPage);
