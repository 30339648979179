import { storage } from '../firebase.config';

export const uploadImage = async (file: any, uri: string) => {
    try {
        const filename = uri.substring(uri.lastIndexOf('/') + 1);

        const image = await storage
            .ref(filename)
            .put(file);

        if (image.state !== 'success') {
            return undefined;
        }

        const url = await storage
            .ref(filename)
            .getDownloadURL();

        if (!url) {
            return undefined;
        }

        return url;
    } catch (e) {
        console.log(e);
        return undefined;
    }
};
