import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { BiTrash, BiListPlus } from 'react-icons/bi';
import { connect } from 'react-redux';

import db, { firestore } from '../../firebase.config';
import { Colors } from '../../constants';
import SetterSelectionModal from './SetterSelectionModal';
import { IGym } from '../../interfaces';

const gymRef = db.collection('gyms');
const usersRef = db.collection('users');

const ManageSettersPage = ({
    gym,
  } : {
    gym: IGym;
  }) => {
    const [setters, setSetters] = useState<any>(undefined);
    const [flaggedSetter, setFlaggedSetter] = useState<any>(undefined);
    const [isAddSetterOpen, setIsAddSetterOpen] = useState<any>(undefined);
    const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);

    useEffect(() => {
        if (gym?.id) {
            gymRef
                .doc(gym.id)
                .collection('climbSettingData')
                .doc(gym.id)
                .get()
                .then(async climbSettingDoc => {
                    if (climbSettingDoc && climbSettingDoc.exists) {
                        const allSetterData: any[] = [];
                        const settersMap = climbSettingDoc.data()?.setters;
                        for (const setterId of Object.keys(settersMap)) {
                            const userDoc = await usersRef
                                .doc(setterId)
                                .get()
                
                            if (userDoc && userDoc.exists) {
                                const setterData = {...userDoc.data()};
                                setterData.id = setterId;
                                allSetterData.push(setterData);
                            }
                        };
                        setSetters(allSetterData);
                    } else {
                        setSetters([]);
                    }
                })
        }
    }, [gym?.id]);

    const handleToggleAddSetterOpen = () => setIsAddSetterOpen(!isAddSetterOpen);

    const handleClearFlaggedSetter = () => setFlaggedSetter(undefined);
    const handleDeleteFlaggedSetter = async () => {
        if (flaggedSetter) {
            setIsLoadingDelete(true);
            const setterUpdateObj: any = {};
            setterUpdateObj["setters." + flaggedSetter.id] = firestore.FieldValue.delete();

            try {
                const userAdminDoc = await gymRef
                    .doc(gym.id)
                    .collection('admin')
                    .doc(flaggedSetter.id)
                    .get();

                const userAdminData = userAdminDoc?.data();
                if (userAdminDoc?.exists) {
                    if (!userAdminData) {
                        window.alert('Failed to remove setter. Please try again.');
                        setIsLoadingDelete(false);
                        return;
                    } else if (userAdminData.role === 'employee') {
                        await gymRef
                            .doc(gym.id)
                            .collection('admin')
                            .doc(flaggedSetter.id)
                            .delete();
                    }
                }

                await gymRef
                    .doc(gym.id)
                    .collection('climbSettingData')
                    .doc(gym.id)
                    .update(setterUpdateObj);
            } catch (err: any) {
                console.log(err);
                window.alert('Failed to remove setter. Please try again.');
                setIsLoadingDelete(false);
                return;
            }

            setIsLoadingDelete(false);
            setSetters(setters.filter((setter: any) => setter.id !== flaggedSetter.id));
            setFlaggedSetter(undefined);
        }
    }

    const handleAddSettersToList = (newSetters: any[]) => setSetters([...setters, ...newSetters]);

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, padding: 20}}>
            <h4 style={{marginBottom: 5}}>Manage Setters:</h4>
            <p style={{marginBottom: 10}}>Setters have access to the setting capability in the mobile app and will be listed under the list of setters when creating a climb. Unless added as an admin, setters will not have access to this gym admin website</p>

            {flaggedSetter && 
                <Modal animation={false} show={true} onHide={handleClearFlaggedSetter}>
                    <Modal.Header closeButton>
                        <Modal.Title>Confirm Deletion</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>Are you sure you want to remove {flaggedSetter.name} from the list of setters</Modal.Body>
                    <Modal.Footer>
                        <Button variant='light' onClick={handleClearFlaggedSetter}>
                            Close
                        </Button>
                        <Button variant='danger' disabled={isLoadingDelete} onClick={handleDeleteFlaggedSetter}>
                            Delete
                        </Button>
                    </Modal.Footer>
                </Modal>
            }

            <button
                onClick={handleToggleAddSetterOpen}
                style={{position: 'fixed', bottom: 20, right: 20, borderRadius: 100, padding: 13, backgroundColor: Colors.MUSTARD, border: 'none'}}
            >
                <BiListPlus size={40} color={Colors.WHITE} />
            </button>
            {isAddSetterOpen && 
                <SetterSelectionModal
                    handleCloseModal={handleToggleAddSetterOpen}
                    handleAddSettersToList={handleAddSettersToList}
                />
            }

            {!setters ? (
                <Spinner animation="border" />
            ) : setters.length === 0 ? (
                <p>No setters at this time</p>
            ) : (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {setters.sort((a: any, b: any) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0).map((setter: any) => {
                        const handleRemoveSetter = () => setFlaggedSetter(setter);
                        return (
                            <div
                                key={setter.id}
                                style={{
                                    position: 'relative',
                                    marginRight: 15,
                                    marginBottom: 15,
                                    padding: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    border: '1px solid grey',
                                    borderRadius: 5,
                                    minWidth: 250,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <button
                                    onClick={handleRemoveSetter}
                                    style={{position: 'absolute', bottom: -5, right: -5, backgroundColor: 'white', borderRadius: 20, padding: 3, border: '1px solid grey'}}
                                >
                                    <BiTrash size={24} />
                                </button>
                                <img alt='user-profile' src={setter.profilePictureUri} height={200} />
                                <p style={{margin: 10, fontWeight: 'bold'}}>{setter.name}</p>
                            </div>
                        )
                    })}
                </div>
            )}
        </div>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
    }
}

export default connect(mapStateToProps)(ManageSettersPage);
