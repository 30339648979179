import React from 'react';

import { Colors } from '../../../constants';

import './BarChart.css';

const BarChart = ({
    labels,
    data,
} : {
    labels: string[];
    data: {[key: string]: number};
}) => {
    const maxDataValue = Math.max(...Object.values(data));

    return (
        <div className='barChartContainer'>
            <div style={{display: 'flex', height: '100%'}}>
                {labels.map((label) =>
                    <div key={label} style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div style={{display: 'flex', flex: data[label] ? maxDataValue - data[label] : 1, alignItems: 'flex-end'}}>
                            {data[label] || 0}
                        </div>
                        <div style={{backgroundColor: Colors.MUSTARD, display: 'flex', flex: data[label], width: '2vw', minWidth: 15, maxWidth: 35}} />
                        <div style={{height: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', borderTop: '2px solid grey'}}>
                            <p className='barChartLabels'>
                                {label}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default BarChart;
