import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useParams } from 'react-router-dom';
import { BiRightArrowAlt, BiLeftArrowAlt, BiCheckboxChecked, BiCheckbox, BiCalendarEvent } from 'react-icons/bi';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import moment from 'moment';
import { connect } from 'react-redux';

import { Colors } from '../../constants';
import db from '../../firebase.config';
import { eventSchema } from '../../validationSchema';
import { IGym } from '../../interfaces';

import './EventDataPage.css';

const gymRef = db.collection('gyms');

const EventDataPage = ({
    allEvents,
    gym,
} : {
    allEvents: any[];
    gym: IGym;
}) => {
    let { id: eventId } = useParams<{id: string}>();
    const [event, setEvent] = useState<any>(undefined);

    useEffect(() => {
        if (eventId === 'create') {
            setEvent({
                startDate: new Date(),
                endDate: new Date(),
                multiDay: false,
                name: '',
                availableSpots: '',
                description: '',
                registrationLink: '',
            });
            return;
        } else if (gym?.id) {
            gymRef
                .doc(gym.id)
                .collection('events')
                .doc(eventId)
                .get()
                .then(eventDoc => {
                    if (eventDoc && eventDoc.exists) {
                        setEvent(eventDoc.data());
                    } else {
                        setEvent(null);
                    }
                });
        }
    }, [gym?.id, eventId]);

    if (event === undefined) {
        return <Spinner animation="border" />
    } else if (event === null) {
        return <p>Error! Please go back and try again!</p>
    }

    const handleBackClick = () => window.history.back();
    const handleSaveEvent = async (values: any) => {
        if (gym?.id) {
            if (eventId === 'create') {
                const newEvent = {
                    attendeeIds: [],
                    deletedAt: null,
                    ...values,
                };

                const newEventDoc = await gymRef
                    .doc(gym.id)
                    .collection('events')
                    .add(newEvent);

                allEvents?.push({id: newEventDoc.id, ...newEvent});
            } else {
                gymRef
                    .doc(gym.id)
                    .collection('events')
                    .doc(eventId)
                    .update(values);

                allEvents?.forEach((eventDoc: any) => {
                    if (eventDoc.id === eventId) {
                        eventDoc.startDate = values.startDate;
                        eventDoc.endDate = values.endDate;
                        eventDoc.name = values.name;
                        eventDoc.availableSpots = values.availableSpots;
                        eventDoc.description = values.description;
                    };
                });
            }
            
            window.history.back();
        }
    }

    const initialValues: {
        startDate: Date;
        endDate: Date;
        name: string;
        multiDay: boolean;
        availableSpots: number;
        description: string;
        registrationLink: string;
    } = {
        startDate: event.startDate.seconds ? event.startDate.toDate() : event.startDate,
        endDate: event.endDate.seconds ? event.endDate.toDate() : event.endDate,
        multiDay: event.multiDay,
        name: event.name,
        availableSpots: event.availableSpots,
        description: event.description,
        registrationLink: event.registrationLink,
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', flex: 1, margin: 25 }}>
            <button
                onClick={() => window.history.back()}
                style={{display: 'flex', borderRadius: 50, alignSelf: 'flex-start', padding: 10, marginTop: 10, marginBottom: 15, backgroundColor: Colors.OFFWHITE, border: 'none'}}
            >
                <BiLeftArrowAlt size={24} color={Colors.DRKGREY} />
            </button>

            <Formik
                initialValues={initialValues}
                validationSchema={eventSchema}
                onSubmit={handleSaveEvent}
            >
                {({values, setFieldValue}) => {
                    const handleStartDateChanged = (e: any) => {
                        if (e.target.value) {
                            const parsedDate = e.target.value.split('-');
                            if (parsedDate.length === 3) {
                                const startDate = values.startDate;
                                startDate.setDate(parsedDate[2]);
                                startDate.setMonth(parsedDate[1] - 1);
                                startDate.setFullYear(parsedDate[0]);
                                setFieldValue('startDate', startDate);
                                if (!values.multiDay) {
                                    const endDate = values.endDate;
                                    endDate.setDate(parsedDate[2]);
                                    endDate.setMonth(parsedDate[1] - 1);
                                    endDate.setFullYear(parsedDate[0]);
                                    setFieldValue('endDate', endDate);
                                }
                            }
                        }
                    }

                    const handleEndDateChanged = (e: any) => {
                        if (e.target.value) {
                            const parsedDate = e.target.value.split('-');
                            if (parsedDate.length === 3) {
                                const endDate = values.endDate;
                                endDate.setDate(parsedDate[2]);
                                endDate.setMonth(parsedDate[1] - 1);
                                endDate.setFullYear(parsedDate[0]);
                                setFieldValue('endDate', endDate);
                            }
                        }
                    }

                    const handleStartTimeChanged = (e: any) => {
                        if (e.target.value) {
                            const parsedDate = e.target.value.split(':');
                            if (parsedDate.length === 2) {
                                const startDate = values.startDate;
                                startDate.setHours(parsedDate[0]);
                                startDate.setMinutes(parsedDate[1]);
                                setFieldValue('startDate', startDate);
                            }
                        }
                    }

                    const handleEndTimeChanged = (e: any) => {
                        if (e.target.value) {
                            const parsedDate = e.target.value.split(':');
                            if (parsedDate.length === 2) {
                                const endDate = values.endDate;
                                endDate.setHours(parsedDate[0]);
                                endDate.setMinutes(parsedDate[1]);
                                setFieldValue('endDate', endDate);
                            }
                        }
                    }

                    const handleAvailableSpotsChange = (e: any) => {
                        const val = e.target.value;
                        if (val.replace(/\D+/g, '').length > 0) {
                            setFieldValue('availableSpots', parseInt(val.replace(/\D+/g, '')));
                        } else {
                            setFieldValue('availableSpots', undefined);
                        }
                    }

                    const handleUnlimitedCheckClicked = () => {
                        if (values.availableSpots !== 0) {
                            setFieldValue('availableSpots', 0);
                        } else {
                            setFieldValue('availableSpots', 1);
                        }
                    }

                    const handleChangeMutliDay = (e: any) => {
                        if (e.target.value === 'true') {
                            setFieldValue('multiDay', true);
                        } else {
                            setFieldValue('multiDay', false);
                        }
                    }

                    return(
                        <Form style={{display: 'flex', flexDirection: 'column'}}>
                            <div style={{marginBottom: 20}}>
                                <Field type="text" name="name" placeholder="Add title" className="eventInput reg" />
                            </div>

                            <div style={{display: 'flex', flexDirection: 'column', marginBottom: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <label style={{marginRight: 45, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <Field type="radio" name="multiDay" value={false} onChange={handleChangeMutliDay} />
                                        <p style={{marginBottom: 0, marginLeft: 8}}>Single Day</p>
                                    </label>
                                    <label style={{marginRight: 45, display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                        <Field type="radio" name="multiDay" value={true} onChange={handleChangeMutliDay} />
                                        <p style={{marginBottom: 0, marginLeft: 8}}>Multi Day</p>
                                    </label>
                                </div>

                                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                                    <input type="date" value={moment(values.startDate).format('YYYY-MM-DD')} onChange={handleStartDateChanged} className="eventInput short" style={{marginRight: 30}} />
                                    <input id='startTime' name='startTime' type="time" value={moment(values.startDate).format('HH:mm')} onChange={handleStartTimeChanged} className="eventInput extraShort" />
                                    <BiRightArrowAlt size={24} style={{marginLeft: 20, marginRight: 20}} />
                                    {values.multiDay && (
                                        <input type="date" value={moment(values.endDate).format('YYYY-MM-DD')} onChange={handleEndDateChanged} className="eventInput short" style={{marginRight: 30}} />
                                    )}
                                    <input id='endTime' name='endTime' type="time" value={moment(values.endDate).format('HH:mm')} onChange={handleEndTimeChanged} className="eventInput extraShort" />
                                </div>
                            </div>

                            <div style={{marginBottom: 20}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    {values.availableSpots !== 0 ? (
                                        <Field
                                            name='availableSpots'
                                            type='number'
                                            placeholder="Available spots"
                                            onChange={handleAvailableSpotsChange}
                                            value={values.availableSpots || ''}
                                            className="eventInput short"
                                        />
                                    ) : (
                                        <p style={{margin: 0, padding: 6.5, width: 200, borderRadius: 5, backgroundColor: Colors.OFFWHITE}}>
                                            Unlimited
                                        </p>
                                    )}

                                    <button
                                        type="button"
                                        onClick={handleUnlimitedCheckClicked}
                                        style={{marginLeft: 10, backgroundColor: Colors.WHITE, borderRadius: 5, border: 'none', display: 'flex', alignItems: 'center'}}
                                    >
                                        {values.availableSpots !== 0 ? <BiCheckbox size={28} /> : <BiCheckboxChecked size={28} />}
                                        <p style={{margin: 0, paddingRight: 10}}>Unlimited</p>
                                    </button>
                                </div>
                            </div>

                            <div style={{marginBottom: 20}}>
                                <Field type="text" name="description" as="textarea" className="eventInput reg" placeholder="Add description" />
                            </div>

                            <div style={{marginBottom: '3%', display: 'flex', flexDirection: 'column'}}>
                                <Field type="text" name="registrationLink" placeholder="Registration Link" className="eventInput reg" />
                                <ErrorMessage name="registrationLink">
                                    {msg => <p style={{color: 'red', fontSize: 12, margin: 0}}>{msg}</p>}
                                </ErrorMessage>
                            </div>

                            <button
                                type="submit"
                                style={{display: 'flex', alignSelf: 'flex-start', justifyContent: 'center', padding: '5px 60px', border: '1px solid lightgrey', backgroundColor: Colors.MUSTARD, borderRadius: 5}}
                            >
                                <BiCalendarEvent size={24} />
                                <p style={{margin: 0, marginLeft: 10}}>
                                    {eventId === 'create' ? 'Create' : 'Save'}
                                </p>
                            </button>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    );
}

const mapStateToProps = function(state: any) {
    return {
        gym: state.gym,
    }
}
  
export default connect(mapStateToProps)(EventDataPage);
