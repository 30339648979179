import React, { useCallback, useRef, useState } from 'react';
import { BiPencil, BiImage } from 'react-icons/bi';
import Cropper from 'react-easy-crop';
import imageCompression from 'browser-image-compression';

import { getCroppedImg } from './ImageCropperUtils';
import { Colors } from '../../../constants';

const imageCompressionOptions = { 
    maxSizeMB: 0.5,
    maxWidthOrHeight: 800,
}

const ImageCropSelector = ({
    initialImageSrc,
    aspect,
    displayHeight,
    shape,
    handleSaveImage,
} : {
    initialImageSrc: string | undefined;
    aspect: number;
    displayHeight: number;
    shape?: 'rect' | 'round';
    handleSaveImage: (imageBlob: any, blobUrl: string) => void;
}) => {
    const [crop, setCrop] = useState<any>({ x: 0, y: 0 });
    const [zoom, setZoom] = useState<number>(1);
    const [isCropperOpen, setIsCropperOpen] = useState<boolean>(false);
    const [imageSrc, setImageSrc] = useState<any>(initialImageSrc);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState<string | undefined>(initialImageSrc)

    const imageFileRef = useRef<any>(null);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, [])

    const handleSaveCroppedImageClick = async () => {
        try {
            const croppedImageBlob: any = await getCroppedImg(
                imageSrc,
                croppedAreaPixels,
            );
            const blobUrl = URL.createObjectURL(croppedImageBlob);
            setCroppedImage(blobUrl);
            handleSaveImage(croppedImageBlob, blobUrl);
            setIsCropperOpen(false);
        } catch (e) {
            console.error(e)
        }
    }

    const handleFileChange = async (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]

            try {
                const compressedFile = await imageCompression(file, imageCompressionOptions);
                const imageDataUrl = URL.createObjectURL(compressedFile);
                setIsCropperOpen(true);
                setImageSrc(imageDataUrl);
            } catch (error) {
                window.alert(JSON.stringify(error));
            }
        }
    }

    const handleCloseCropper = () => setIsCropperOpen(false);
    const handleOpenImageFS = () => imageFileRef.current.click();

    return (
        <div style={{ margin: 20, display: 'flex', flexDirection: 'column', flex: 1 }}>
            <input type='file' id='bannerImageFile' ref={imageFileRef} onChange={handleFileChange} style={{display: 'none'}}/>
            {(croppedImage !== undefined) ? (
                <div style={{position: 'relative'}}>
                    <img
                        height={displayHeight}
                        width={displayHeight * aspect}
                        style={{borderRadius: shape === 'round' ? 200 : 0, border: '1px solid grey'}}
                        src={croppedImage}
                        alt='cropped'
                    />
                    <button
                        onClick={handleOpenImageFS}
                        style={{position: 'absolute', bottom: -5, right: -5, backgroundColor: 'white', borderRadius: 20, padding: 3, border: '1px solid grey'}}
                    >
                        <BiPencil  size={24} />
                    </button>
                </div>
            ) : (
                <div>
                    <button style={{backgroundColor: 'transparent', border: 'none'}}>
                        <BiImage onClick={handleOpenImageFS} size={56} />
                    </button>
                </div>
            )}

            {(isCropperOpen && imageSrc !== undefined) && (
                <div style={{backgroundColor: Colors.WHITE, height: '100vh', width: '100vw', position: 'absolute', left: 0, top: 0, zIndex: 9999}}>
                    <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: '100px'}}>
                        {isCropperOpen && (
                            <Cropper
                                cropShape={shape}
                                image={imageSrc}
                                crop={crop}
                                zoom={zoom}
                                aspect={aspect}
                                onCropChange={setCrop}
                                onCropComplete={onCropComplete}
                                onZoomChange={setZoom}
                            />
                        )}
                    </div>
                    <div style={{position: 'absolute', bottom: 30, margin: 'auto', left: 0, right: 0, width: '300px'}}>
                        <button
                            style={{marginRight: 10, width: 100}}
                            onClick={handleCloseCropper}
                        >
                            Close
                        </button>
                        <button
                            style={{marginLeft: 10, width: 100, backgroundColor: Colors.MUSTARD}}
                            onClick={handleSaveCroppedImageClick}
                        >
                            Save
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ImageCropSelector;
