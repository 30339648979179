import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import db from '../../firebase.config';
import { Colors } from '../../constants';
import { IGym } from '../../interfaces';

const gymRef = db.collection('gyms');

const FeedbackPage = ({
  gym,
} : {
  gym: IGym;
}) => {
  const [feedback, setFeedback] = useState<any>(undefined);

  useEffect(() => {
    if (gym?.id) {
      gymRef
        .doc(gym.id)
        .collection('feedback')
        .orderBy('date', 'desc')
        .get()
        .then(feedbackDocs => feedbackDocs && setFeedback(
          feedbackDocs.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }))
        ))
    }
  }, [gym?.id]);

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1, height: '100vh', margin: 20}}>
      <h4>User Feedback</h4>

      {!feedback ? (
        <Spinner animation="border" />
      ) :
      feedback.length === 0 ? (
        <p>No feedback at this time</p>
      ) : (
        <ul className="list-group list-group-flush">
          {feedback.map((userFeedback: any) => {
            const feedbackData = userFeedback;
            const feedbackLink = '/feedback/' + userFeedback.id;

            return (
              <li key={userFeedback.id} style={{ padding: 0, backgroundColor: feedbackData.readAt ? Colors.OFFWHITE : 'rgba(0,0,0, 0.01)' }} className="list-group-item">
                <Link to={feedbackLink} style={{textDecorationLine: 'none', color: 'black'}}>
                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: 10}}>
                    <p style={{margin: 0}}>{feedbackData.message}</p>
                    <p style={{margin: 0}}>{moment(feedbackData.date.toDate()).format('MMM D, YYYY')}</p>
                  </div>
                </Link>
              </li>
            )
          })}
        </ul>
      )}
    </div>
  );
}

const mapStateToProps = function(state: any) {
  return {
    gym: state.gym,
  }
}

export default connect(mapStateToProps)(FeedbackPage);
