import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { functions, firestore } from '../../firebase.config';
import { Colors } from '../../constants';
import { Spinner } from 'react-bootstrap';

import './ClubEvents.css';

const getClubEvents = functions.httpsCallable('gymAdminRequest-getClubEvents');

const ClubEvents = ({
  clubId,
  gymId,
} : {
  clubId: string;
  gymId: string;
}) => {
  const [events, setEvents] = useState<any>(undefined);

  useEffect(() => {
    getClubEvents({
      clubId: clubId,
      gymId: gymId,
    })
    .then(eventsData => eventsData?.data && setEvents(eventsData.data))
  }, [clubId, gymId]);

  if (events === undefined) {
    return (
      <div className="loadingContainer">
        <Spinner animation='border' color={Colors.MUSTARD} />
      </div>
    )
  }

  return (
    <div className="mainContainer">
      {events.length !== 0 ?
        <div>
          {events.map((event: any) => {
            // const handleDeleteEventClick = () => handleDeleteEvent(event);
            const startDate = new firestore.Timestamp(event.startDate?._seconds, event.startDate?._nanoseconds);
            const endDate = new firestore.Timestamp(event.endDate?._seconds, event.endDate?._nanoseconds);

            return (
              <div key={event.id} className="eventsContainer">
                <div>
                  <p style={{fontSize: 24, color: Colors.MUSTARD, margin: 0, fontWeight: 'bold', textAlign: 'center'}}>
                    {moment(startDate.toDate()).format('MMM')}
                  </p>
                  <p style={{fontSize: 24, color: Colors.MUSTARD, margin: 0, fontWeight: 'bold', textAlign: 'center'}}>
                    {moment(startDate.toDate()).format('DD')}
                  </p>
                </div>

                <div style={{flex: 1, marginLeft: '20px'}}>
                  <p style={{fontWeight: 'bold', fontSize: 18, margin: 0}}>
                    {event.name}
                  </p>

                  <p style={{fontSize: 14, color: Colors.GREY, marginBottom: 5}}>
                    {moment(startDate.toDate()).format('dddd, LT')} - {moment(endDate.toDate()).format('LT')}
                  </p>

                  {event.availableSpots !== 0 && (
                    <div style={{display: 'flex', width: '60%', borderColor: Colors.GREY, borderWidth: 1}}>
                      <div style={{ height: 15, flex: event.attendeeIds.length, backgroundColor: Colors.MUSTARD}} />
                      <div style={{ height: 15, flex: event.availableSpots - event.attendeeIds.length, backgroundColor: Colors.LTGREY}} />
                    </div>
                  )}

                  {event.availableSpots !== 0 ? (
                    <p style={{alignSelf: 'flex-end', color: Colors.GREY, fontSize: 12}}>
                      {event.attendeeIds.length}/{event.availableSpots} spots filled
                    </p>
                  ) : (
                    <p style={{color: Colors.GREY, fontSize: 12, marginTop: 5}}>
                      {event.attendeeIds.length} climbers attending
                    </p>
                  )}
                </div>

                <div style={{flexDirection: 'column'}}>
                  {/* <IconButton
                    onPress={handleDeleteEventClick}
                    icon='delete'
                    color={Colors.DKRED} 
                    style={{margin: 0}}
                  /> */}
                </div>
              </div>
            )
          })}
        </div>
      : (
        <p className="noContentText">
          No upcoming events
        </p>
      )}
    </div>
  );
};

export default ClubEvents;
