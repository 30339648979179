import React, { useState, useEffect } from 'react';
import { BiChevronDown, BiChevronUp, BiPencil } from 'react-icons/bi';
import { Spinner } from 'react-bootstrap';

import db from '../../firebase.config';
import { Colors } from '../../constants';

const leaguesRef = db.collection('leagues');

const LeagueTeams = ({
  league,
} : {
  league: any;
}) => {
  const [teams, setTeams] = useState<any>(undefined);
  const [expandedTeam, setExpandedTeam] = useState<string | null>(null);
  const [edittingTeam, setEdittingTeam] = useState<any | null>(null);
  const [removingMemberId, setRemovingMemberId] = useState<string | null>(null);
  const [teamMembersMap, setTeamMembersMap] = useState<{[key: string]: {id: string; name: string; imageUri: string; cumulativeScore: number}[]}>({});

  const handleClearEdittingTeam = () => setEdittingTeam(null);
  const handleEditTeamNameChange = (e: any) => {
    if (edittingTeam) {
      const edittingTeamCopy = {...edittingTeam};
      edittingTeamCopy.name = e.target.value;
      setEdittingTeam(edittingTeamCopy);
    }
  }
  const handleEditTeamDescriptionChange = (e: any) => {
    if (edittingTeam) {
      const edittingTeamCopy = {...edittingTeam};
      edittingTeamCopy.description = e.target.value;
      setEdittingTeam(edittingTeamCopy);
    }
  }

  useEffect(() => {
    leaguesRef
      .doc(league.id)
      .collection('leagueTeams')
      .limit(50)
      .get()
      .then(teamDocs => teamDocs && setTeams(teamDocs.docs.map(team => ({id: team.id, ...team.data()}))));
  }, [league.id]);

  if (teams === undefined) {
    return (
      <div>
        <Spinner animation='border' color={Colors.MUSTARD} />
      </div>
    )
  } else if (teams.length === 0) {
    return (
      <div>
        <p style={{fontSize: 24}}>
          No teams yet
        </p>
      </div>
    )
  }

  const handleRemoveMember = async (member: any, team: any) => {
    setRemovingMemberId(member.id);

    await leaguesRef
      .doc(league.id)
      .collection('leagueParticipants')
      .doc(member.id)
      .update({
        teamId: null,
        teamStatus: null,
        teamAcceptedAt: null,
      });

    const previousMembers: any[] = team.previousMembers || [];
    previousMembers.push({
      memberId: member.id,
      points: member.cumulativeScore,
      date: new Date(),
    });

    await leaguesRef
      .doc(league.id)
      .collection('leagueTeams')
      .doc(team.id)
      .update({
        previousMembers,
      });
    
    const newTeamMembers = teamMembersMap[team.id].filter(currentMember => currentMember.id !== member.id);
    if (newTeamMembers.length) {
      const teamMembersMapCopy = {...teamMembersMap};
      teamMembersMapCopy[team.id] = newTeamMembers;
      setTeamMembersMap(teamMembersMapCopy);
    } else {
      const teamMembersMapCopy = {...teamMembersMap};
      delete teamMembersMapCopy[team.id];
      setTeamMembersMap(teamMembersMapCopy);
      setTeams(teams.filter((curTeam: any) => curTeam.id !== team.id));
    }

    setRemovingMemberId(null);
  }

  const handleExpandTeam = async (teamId: string) => {
    if (expandedTeam === teamId) {
      setExpandedTeam(null);
    } else {
      setExpandedTeam(teamId);
      if (!teamMembersMap[teamId]) {
        const participantDocs = await leaguesRef
          .doc(league.id)
          .collection('leagueParticipants')
          .where('teamId', '==', teamId)
          .where('teamStatus', '==', 'accepted')
          .get();

        if (participantDocs) {
          const teamMembersData = participantDocs.docs.map(participantDoc => ({
            id: participantDoc.id,
            name: participantDoc.data().userName,
            imageUri: participantDoc.data().userImageUri,
            cumulativeScore: participantDoc.data().cumulativeScore,
          }));
          const teamMembersMapCopy = {...teamMembersMap};
          teamMembersMapCopy[teamId] = teamMembersData;
          setTeamMembersMap(teamMembersMapCopy);
        }
      }
    }
  }

  const handleSaveEdittingTeam = async () => {
    if (edittingTeam) {
      await leaguesRef
        .doc(league.id)
        .collection('leagueTeams')
        .doc(edittingTeam.id)
        .update({
          name: edittingTeam.name,
          description: edittingTeam.description,
        });

      setTeams(teams.map((team: any) => team.id === edittingTeam.id ? edittingTeam : team));
      setEdittingTeam(null);
    }
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
      {edittingTeam && (
        <div style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, display: 'flex', alignItems: 'center', backgroundColor: Colors.MODALBACKGROUND}}>
          <div style={{height: 'calc(250px + 14vh)', width: 'calc(250px + 20vw)', borderRadius: 30, backgroundColor: Colors.WHITE, margin: 'auto', position: 'relative'}}>
            <button onClick={handleClearEdittingTeam} style={{border: 'none', backgroundColor: 'white', position: 'absolute', top: 15, right: 15}}>
              &#10006;
            </button>
            <p style={{textAlign: 'center', marginTop: 15, fontSize: 20}}>
              Edit Team: {edittingTeam.name}
            </p>

            <div style={{display: 'flex', flexDirection: 'column', margin: 25}}>
              <p style={{margin: 0}}>
                Name
              </p>
              <input value={edittingTeam.name} onChange={handleEditTeamNameChange} />

              <p style={{margin: '10px 0 0 0'}}>
                Description
              </p>
              <textarea style={{minHeight: 100}} value={edittingTeam.description} onChange={handleEditTeamDescriptionChange} />

              <button onClick={handleSaveEdittingTeam} style={{border: 'none', padding: 3, marginTop: 40, borderRadius: 5, backgroundColor: Colors.MUSTARD, }}>
                Save
              </button>
            </div>
          </div>
        </div>
      )}
      
      {teams.map((team: any) => {
        const handleExpandTeamClick = () => handleExpandTeam(team.id);
        const handleEditTeamClick = () => setEdittingTeam(team);

        return (
          <div key={team.id} style={{display: 'flex', flexDirection: 'column', flex: 1}}>
            <div
              style={{marginBottom: 10, padding: 5, borderRadius: 10, border: '1px solid grey', backgroundColor: Colors.WHITE}}
            >
              <div style={{display: 'flex', flexGrow: 1, alignItems: 'center'}}>
                <img src={team.imageUri} alt='admin profile' style={{height: 60, width: 60, borderRadius: 60}} />

                <p style={{marginBottom: 0, marginLeft: 10, fontSize: 18, fontWeight: 'bold', width: '25%', paddingRight: 5, textAlign: 'left'}}>
                  {team.name}
                </p>

                <p style={{marginBottom: 0, fontSize: 16, width: '25%', textAlign: 'left'}}>
                  {team.numMembers}/{league.maxMembersPerTeam} spots filled
                </p>

                <p style={{marginBottom: 0, fontSize: 16, flexGrow: 1, textAlign: 'left'}}>
                  {team.public ? 'Public' : 'Private'}
                </p>

                <div style={{flexGrow: 1, justifyContent: 'center', display: 'flex'}}>
                  <button onClick={handleEditTeamClick} style={{border: 'none', backgroundColor: 'white'}}>
                    <BiPencil size={30} color={Colors.DRKGREY} />
                  </button>
                </div>

                <button onClick={handleExpandTeamClick} style={{border: 'none', backgroundColor: 'white'}}>
                  {expandedTeam !== team.id ?
                    <BiChevronDown size={52} color={Colors.MUSTARD} /> :
                    <BiChevronUp size={52} color={Colors.MUSTARD} />
                  }
                </button>
              </div>

              {expandedTeam === team.id && (
                <div style={{marginTop: 7, padding: 5}}>
                  <div style={{display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', marginBottom: 10}}>
                    <p style={{marginBottom: 0, marginRight: 10, fontSize: 18, fontWeight: 'bold', color: Colors.DRKGREY, textAlign: 'left'}}>
                      Team Description:
                    </p>
                    <p style={{fontSize: 15, marginBottom: 0, textAlign: 'left'}}>
                      {team.description}
                    </p>
                  </div>
                  <p style={{marginBottom: 0, fontSize: 18, fontWeight: 'bold', color: Colors.DRKGREY, textAlign: 'left'}}>
                    Team Members:
                  </p>
                  {!teamMembersMap[team.id] ? (
                    <Spinner animation='border' />
                  ) : teamMembersMap[team.id].length === 0 ? (
                    <p style={{fontSize: 14, textAlign: 'left'}}>No team members yet.</p>
                  ) : (
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      {teamMembersMap[team.id].map(member => {
                        const handleRemoveMemberClick = () => handleRemoveMember(member, team);

                        return (
                          <div key={member.id} style={{display: 'flex', alignSelf: 'flex-start', alignItems: 'center', marginTop: 3, minWidth: 'calc(100px + 30vw)'}}>
                            <img
                              alt='member'
                              src={member.imageUri}
                              style={{ width: 40, height: 40, borderRadius: 100, borderWidth: 0.5, borderColor: Colors.LTGREY }}
                            />
                            <p style={{fontSize: 15, marginLeft: 5, marginBottom: 0, flex: 1, textAlign: 'left'}}>
                              {member.name} {team.ownerId === member.id && '(Owner)'}
                            </p>
                            {removingMemberId !== member.id ? (
                              <button
                                onClick={handleRemoveMemberClick}
                                style={{marginLeft: 20, backgroundColor: 'white', border: '1px solid #020202', borderRadius: 5, color: Colors.RED}}
                              >
                                Remove from team
                              </button>
                            ) : (
                              <Spinner animation='border' style={{marginRight: 60}} />
                            )}
                          </div>
                        )
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default LeagueTeams;
