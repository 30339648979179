import React, { useState } from 'react';
import {
  Switch,
  Route,
} from 'react-router-dom';
import { BiUserCircle, BiEnvelopeOpen, BiCalendar, BiMenu } from 'react-icons/bi';
import { FaUsers, FaRegNewspaper, FaUserLock, FaRegMap, FaChevronRight, FaMedal} from 'react-icons/fa';

import ManageGymPage from '../profile/ManageGymPage';
import ManageClubsPage from '../clubs/ManageClubsPage';
import FeedbackPage from '../feedback/FeedbackPage';
import ManageNewsPage from '../news/ManageNewsPage';
import ManageEventsPage from '../events/ManageEventsPage';
import ManageAdminsPage from '../people/ManageAdminsPage';
import ManageSettersPage from '../people/ManageSettersPage';
import ManageLeaguesPage from '../leagues/ManageLeaguesPage';
import EditLeaguePage from '../leagues/EditLeaguePage';
import EventDataPage from '../events/EventDataPage';
import ClubPage from '../clubs/ClubPage';
import FeedbackData from '../feedback/FeedbackData';
import NewsDataPage from '../news/NewsDataPage';
import LeaguePage from '../leagues/LeaguePage';
import ClimbTimeLogo from './../../assets/climb-time-logo-inverse.png';
import CurrentSet from '../gymMap/currentSet/CurrentSet';
import Header from '../organisms/header/Header';
import { Colors } from '../../constants';

import './Dashboard.css';

const Dashboard = () => {
  const [mapMenuOpen, setMapMenuOpen] = useState<boolean>(false);
  const [peopleMenuOpen, setPeopleMenuOpen] = useState<boolean>(false);
  const [isMenuExpanded, setIsMenuExpanded] = useState<boolean>(false);

  const toggleIsMenuExpanded = () => setIsMenuExpanded(!isMenuExpanded);
  const toggleMapMenuOpen = () => setMapMenuOpen(!mapMenuOpen);
  const togglePeopleMenuOpen = () => setPeopleMenuOpen(!peopleMenuOpen);

  return (
    <div className='main'>
      <div className="nav">
        <div className='headerBar'>
          <button onClick={toggleIsMenuExpanded} className='menuToggle'>
            <BiMenu size={30} color={Colors.GREY} />
          </button>
          <div className='logo'>
            <img alt='Logo' src={ClimbTimeLogo} style={{width:'100%'}} />
          </div>
        </div>

        <div className={isMenuExpanded ? 'linksContainer linksContainerShown' : 'linksContainer'}>
          <a href="/" className='links'>
            <BiUserCircle size={28} color={Colors.WHITE} />
            <p className='linkTitle'>Profile</p>
          </a>
          <div className='navMenu'>
            <div className='dropdownContainer'>
              <button onClick={toggleMapMenuOpen} className='links'>
                <FaRegMap size={28} color={Colors.WHITE} />
                <p className='linkTitle'>Gym Map</p>
                <FaChevronRight size={14} color={Colors.WHITE} className={mapMenuOpen ? 'chevronDown' : 'chevronRight'} />
              </button>
            </div>
            <div className={mapMenuOpen ? 'navSubMenu shown1' : 'navSubMenu'}>
              <a href="/current-set" className='submenuLink lastSubmenuLink'>
                <div style={{width: 5, height: 5, borderRadius: 10, border: '1px solid white', marginRight: 10}} />
                Current Set
              </a>
              {/* <a href="/past-sets" className='links'>
                <div style={{width: 5, height: 5, borderRadius: 10, border: '1px solid white', marginRight: 10}} />
                View Past Sets
              </a>
              <a href="/reports" className='links'>
                <div style={{width: 5, height: 5, borderRadius: 10, border: '1px solid white', marginRight: 10}} />
                Reports
              </a> */}
            </div>
          </div>
          <a href='/news' className='links'>
            <FaRegNewspaper size={28} color={Colors.WHITE} />
            <p className='linkTitle'>News</p>
          </a>
          <a href='/events' className='links'>
            <BiCalendar size={28} color={Colors.WHITE} />
            <p className='linkTitle'>Events</p>
          </a>
          <a href='/clubs' className='links'>
            <FaUsers size={28} color={Colors.WHITE} />
            <p className='linkTitle'>Clubs</p>
          </a>
          <a href='/leagues' className='links'>
            <FaMedal size={28} color={Colors.WHITE} />
            <p className='linkTitle'>Leagues</p>
          </a>
          <a href='/feedback' className='links'>
            <BiEnvelopeOpen size={28} color={Colors.WHITE} />
            <p className='linkTitle'>Feedback</p>
          </a>
          <div className='navMenu'>
            <div className='dropdownContainer'>
              <button onClick={togglePeopleMenuOpen} className='links lastLink'>
                <FaUserLock size={28} color={Colors.WHITE} />
                <p className='linkTitle'>People</p>
                <FaChevronRight size={14} color={Colors.WHITE} className={peopleMenuOpen ? 'chevronDown' : 'chevronRight'} />
              </button>
            </div>
            <div className={peopleMenuOpen ? 'navSubMenu shown2' : 'navSubMenu'}>
              <a href='/admins' className='submenuLink'>
                <div style={{width: 5, height: 5, borderRadius: 10, border: '1px solid white', marginRight: 10}} />
                Admins
              </a>
              <a href='/setters' className='submenuLink lastSubmenuLink'>
                <div style={{width: 5, height: 5, borderRadius: 10, border: '1px solid white', marginRight: 10}} />
                Setters
              </a>
            </div>
          </div>
        </div>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
        <Header />

        <Switch>
          <Route
            exact
            path='/'
            component={ManageGymPage}
          />
          <Route
            path='/current-set'
            component={CurrentSet}
          />
          <Route
            exact
            path='/news'
            component={ManageNewsPage}
          />
          <Route
            path='/news/:id'
            component={NewsDataPage}
          />
          <Route
            exact
            path='/events'
            component={ManageEventsPage}
          />
          <Route
            path='/events/:id'
            component={EventDataPage}
          />
          <Route
            exact
            path='/clubs'
            component={ManageClubsPage}
          />
          <Route
            path='/clubs/:id'
            component={ClubPage}
          />
          <Route
            exact
            path='/leagues'
            component={ManageLeaguesPage}
          />
          <Route
            exact
            path='/leagues/edit/:id'
            component={EditLeaguePage}
          />
          <Route
            path='/leagues/:id'
            component={LeaguePage}
          />
          <Route
            exact
            path='/feedback'
            component={FeedbackPage}
          />
          <Route
            path='/feedback/:id'
            component={FeedbackData}
          />
          <Route
            path='/admins'
            component={ManageAdminsPage}
          />
          <Route
            path='/setters'
            component={ManageSettersPage}
          />
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;
