import React from 'react';
import moment from 'moment';

import { Colors, ReverseColorLookup } from '../../../constants';
import { IRoute, ITagTemplate } from '../../../interfaces';
import ClimbTimeLogo from '../../../assets/climb-time-logo.png';


const RouteTag = ({
    route,
    gymImageUri,
    areaName,
    wallName,
    template,
    zoom,
} : {
    route: IRoute,
    gymImageUri: string;
    areaName: string;
    wallName: string;
    template: ITagTemplate;
    zoom: number;
}) => {
    return (
        <div style={{
            height: template.height,
            width: template.width,
            border: template.hasBorder ? '27px solid black' : '1px solid grey',
            position: 'relative',
            margin: 15,
            zoom: zoom,
        }}>
            {template.isGymLogoTop ? (
                <img
                    alt='gym-profile'
                    src={gymImageUri}
                    style={{
                        height: 350,
                        width: 350,
                        borderRadius: 100,
                        position: 'absolute',
                        left: template.gymImageLeft,
                        top: template.gymImageTop
                    }}
                />
            ) : (
                <div
                    style={{
                        width: '100%',
                        backgroundColor: Colors.BLUE,
                        height: template.gradeTop - 30,
                    }}
                />
            )}

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: template.gradeTop,
                left: 0,
                alignItems: 'center',
                width: '100%',
            }}>
                <p style={{margin: 0, padding: 0, fontWeight: 'bolder', fontSize: template.gradeFontSize, lineHeight: 1}}>{route.grade}</p>
                <p style={{margin: 0, fontWeight: 'bold', fontSize: template.nameFontSize, lineHeight: 1}}>{ReverseColorLookup[route.color]}</p>
            </div>

            <div style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'absolute',
                top: template.aboutTop,
                width: '100%',
                marginLeft: template.aboutSide
            }}>
                <p style={{margin: 0, fontSize: template.aboutFontSize, lineHeight: 1.3, textAlign: template.aboutAlign, fontFamily: template.aboutFontFamily, fontWeight: template.aboutFontWeight, wordSpacing: -10}}>
                    {template.areaNameShown ? 'Area: ' + areaName : 'Wall: ' + wallName}
                </p>
                <p style={{margin: 0, fontSize: template.aboutFontSize, lineHeight: 1.3, textAlign: template.aboutAlign, fontFamily: template.aboutFontFamily, fontWeight: template.aboutFontWeight, wordSpacing: -10}}>
                    Set by: {route.about.setter}
                </p>
                <p style={{margin: 0, fontSize: template.aboutFontSize, lineHeight: 1.3, textAlign: template.aboutAlign, fontFamily: template.aboutFontFamily, fontWeight: template.aboutFontWeight, wordSpacing: -10}}>
                    {moment(route.about.setDate.toDate()).format('LL')}
                </p>
            </div>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly'}}>
                {!template.isGymLogoTop && (
                    <img
                        alt='gym-logo'
                        src={template.gymImageUri === '' ? gymImageUri : template.gymImageUri}
                        style={{
                            width: undefined,
                            height: 180,
                            position: 'absolute',
                            top: template.climbTimeLogoTop,
                            left: template.climbTimeLogoSide
                        }}
                    />
                )}
                <img
                    alt='climb-time-logo'
                    src={ClimbTimeLogo}
                    style={{
                        width: undefined,
                        height: 180,
                        position: 'absolute',
                        top: template.climbTimeLogoTop,
                        right: !template.isGymLogoTop ? template.climbTimeLogoSide : 'auto',
                    }}
                />
            </div>
        </div>
    );
}

export default RouteTag;
